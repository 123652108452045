// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface AcceptJob
 */
export interface AcceptJob {
  /**
   *
   * @type {string}
   * @memberof AcceptJob
   */
  estimate?: string;
}
/**
 *
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  email: string;
}
/**
 *
 * @export
 * @interface BusinessEntities
 */
export interface BusinessEntities {
  /**
   *
   * @type {Array<BusinessEntity>}
   * @memberof BusinessEntities
   */
  data?: Array<BusinessEntity>;
}
/**
 *
 * @export
 * @interface BusinessEntity
 */
export interface BusinessEntity {
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BusinessEntity
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  level: number;
  /**
   *
   * @type {BusinessEntity}
   * @memberof BusinessEntity
   */
  ancestor?: BusinessEntity;
  /**
   *
   * @type {Array<BusinessEntity>}
   * @memberof BusinessEntity
   */
  descendants?: Array<BusinessEntity>;
  /**
   *
   * @type {Client}
   * @memberof BusinessEntity
   */
  client?: Client;
  /**
   *
   * @type {boolean}
   * @memberof BusinessEntity
   */
  hasMaterialMaster: boolean;
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  numberOfUsers?: number;
  /**
   *
   * @type {number}
   * @memberof BusinessEntity
   */
  numberOfDescendants?: number;
  /**
   *
   * @type {string}
   * @memberof BusinessEntity
   */
  plant?: string;
}
/**
 *
 * @export
 * @interface Catalog
 */
export interface Catalog {
  /**
   *
   * @type {number}
   * @memberof Catalog
   */
  id: number;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof Catalog
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  uploadedBy?: string;
}
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {number}
   * @memberof Client
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  documentationName?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  documentationUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  auth0ConnectionName?: string;
  /**
   *
   * @type {boolean}
   * @memberof Client
   */
  ssoOnly?: boolean;
}
/**
 *
 * @export
 * @interface Clients
 */
export interface Clients {
  /**
   *
   * @type {Array<Client>}
   * @memberof Clients
   */
  data: Array<Client>;
}
/**
 *
 * @export
 * @interface ColumnHighlight
 */
export interface ColumnHighlight {
  /**
   *
   * @type {number}
   * @memberof ColumnHighlight
   */
  start: number;
  /**
   *
   * @type {number}
   * @memberof ColumnHighlight
   */
  end: number;
}
/**
 *
 * @export
 * @interface ConfigurationLog
 */
export interface ConfigurationLog {
  /**
   *
   * @type {string}
   * @memberof ConfigurationLog
   */
  logType: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurationLog
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurationLog
   */
  userId: string;
  /**
   *
   * @type {object}
   * @memberof ConfigurationLog
   */
  oldValue?: object;
  /**
   *
   * @type {object}
   * @memberof ConfigurationLog
   */
  newValue: object;
}
/**
 *
 * @export
 * @interface CreatedMaterialStatistic
 */
export interface CreatedMaterialStatistic {
  /**
   *
   * @type {Client}
   * @memberof CreatedMaterialStatistic
   */
  client: Client;
  /**
   *
   * @type {number}
   * @memberof CreatedMaterialStatistic
   */
  creationId: number;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  searchTerm?: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof CreatedMaterialStatistic
   */
  manufacturerDescription?: string;
}
/**
 *
 * @export
 * @interface CreatedMaterialStatisticResults
 */
export interface CreatedMaterialStatisticResults {
  /**
   *
   * @type {Array<CreatedMaterialStatistic>}
   * @memberof CreatedMaterialStatisticResults
   */
  results: Array<CreatedMaterialStatistic>;
  /**
   *
   * @type {number}
   * @memberof CreatedMaterialStatisticResults
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @interface DanglingResource
 */
export interface DanglingResource {
  /**
   *
   * @type {number}
   * @memberof DanglingResource
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DanglingResource
   */
  materialReference: string;
}
/**
 *
 * @export
 * @interface DanglingResources
 */
export interface DanglingResources {
  /**
   *
   * @type {Array<DanglingResource>}
   * @memberof DanglingResources
   */
  changeRequests: Array<DanglingResource>;
  /**
   *
   * @type {Array<DanglingResource>}
   * @memberof DanglingResources
   */
  materialExtensions: Array<DanglingResource>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DateRange {
  Last7Days = "Last_7_days",
  Last30Days = "Last_30_days",
  LastYear = "Last_year",
  All = "All",
}

/**
 *
 * @export
 * @interface DeleteMatchingCandidates
 */
export interface DeleteMatchingCandidates {
  /**
   *
   * @type {string}
   * @memberof DeleteMatchingCandidates
   */
  type?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteMatchingCandidates
   */
  references?: Array<string>;
}
/**
 *
 * @export
 * @interface DemoDataToDelete
 */
export interface DemoDataToDelete {
  /**
   * (Required) Narrows down the condition for the data deletion
   * @type {number}
   * @memberof DemoDataToDelete
   */
  materialMasterId: number;
  /**
   * (Optional) Pending material IDs to be deleted
   * @type {Array<number>}
   * @memberof DemoDataToDelete
   */
  pendingMaterialIds?: Array<number>;
  /**
   * (Optional) Change request IDs to be deleted
   * @type {Array<number>}
   * @memberof DemoDataToDelete
   */
  changeRequestIds?: Array<number>;
}
/**
 *
 * @export
 * @interface DuplicateAction
 */
export interface DuplicateAction {
  /**
   *
   * @type {string}
   * @memberof DuplicateAction
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof DuplicateAction
   */
  parentGroupNumber: number;
  /**
   *
   * @type {number}
   * @memberof DuplicateAction
   */
  childGroupNumber: number;
}
/**
 *
 * @export
 * @interface DuplicateActions
 */
export interface DuplicateActions {
  /**
   *
   * @type {Array<DuplicateAction>}
   * @memberof DuplicateActions
   */
  actions: Array<DuplicateAction>;
}
/**
 *
 * @export
 * @interface DuplicateGroup
 */
export interface DuplicateGroup {
  /**
   *
   * @type {number}
   * @memberof DuplicateGroup
   */
  groupNumber: number;
  /**
   *
   * @type {Array<DuplicateGroup>}
   * @memberof DuplicateGroup
   */
  virtualGroups?: Array<DuplicateGroup>;
  /**
   *
   * @type {number}
   * @memberof DuplicateGroup
   */
  rowsCount: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroup
   */
  cells: Array<string>;
  /**
   * MaterialReference of the reference row for this group
   * @type {string}
   * @memberof DuplicateGroup
   */
  referenceRow: string;
}
/**
 *
 * @export
 * @interface DuplicateGroupCandidate
 */
export interface DuplicateGroupCandidate {
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroupCandidate
   */
  matchingContent: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroupCandidate
   */
  viewContent: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DuplicateGroupCandidate
   */
  groupNumber: number;
  /**
   *
   * @type {string}
   * @memberof DuplicateGroupCandidate
   */
  materialReference: string;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateGroupCandidate
   */
  hasRelatedGroups: boolean;
}
/**
 *
 * @export
 * @interface DuplicateGroupCandidates
 */
export interface DuplicateGroupCandidates {
  /**
   *
   * @type {Array<DuplicateGroupCandidate>}
   * @memberof DuplicateGroupCandidates
   */
  items: Array<DuplicateGroupCandidate>;
  /**
   *
   * @type {number}
   * @memberof DuplicateGroupCandidates
   */
  numberOfItems: number;
}
/**
 *
 * @export
 * @interface DuplicateGroupLeadingInfo
 */
export interface DuplicateGroupLeadingInfo {
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroupLeadingInfo
   */
  matchingContent: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroupLeadingInfo
   */
  viewContent: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DuplicateGroupLeadingInfo
   */
  numberOfCandidates: number;
  /**
   *
   * @type {number}
   * @memberof DuplicateGroupLeadingInfo
   */
  groupNumber: number;
  /**
   *
   * @type {string}
   * @memberof DuplicateGroupLeadingInfo
   */
  materialReference: string;
  /**
   *
   * @type {Array<DuplicateGroupCandidate>}
   * @memberof DuplicateGroupLeadingInfo
   */
  candidates: Array<DuplicateGroupCandidate>;
}
/**
 *
 * @export
 * @interface DuplicateGroupSearch
 */
export interface DuplicateGroupSearch {
  /**
   *
   * @type {string}
   * @memberof DuplicateGroupSearch
   */
  query: string;
  /**
   *
   * @type {Array<number>}
   * @memberof DuplicateGroupSearch
   */
  columnFilter: Array<number>;
}
/**
 *
 * @export
 * @interface DuplicateGroups
 */
export interface DuplicateGroups {
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroups
   */
  matchingHeaders: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateGroups
   */
  viewHeaders: Array<string>;
  /**
   *
   * @type {Array<DuplicateGroupLeadingInfo>}
   * @memberof DuplicateGroups
   */
  groups: Array<DuplicateGroupLeadingInfo>;
  /**
   * Number of groups to process in total
   * @type {number}
   * @memberof DuplicateGroups
   */
  totalNumberOfGroups: number;
}
/**
 *
 * @export
 * @interface DuplicateMatchingSettings
 */
export interface DuplicateMatchingSettings {
  /**
   *
   * @type {Array<number>}
   * @memberof DuplicateMatchingSettings
   */
  matchingColumns: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof DuplicateMatchingSettings
   */
  viewColumns?: Array<number>;
}
/**
 *
 * @export
 * @interface DuplicatesOverviewQuery
 */
export interface DuplicatesOverviewQuery {
  /**
   *
   * @type {number}
   * @memberof DuplicatesOverviewQuery
   */
  numberOfResults?: number;
  /**
   *
   * @type {number}
   * @memberof DuplicatesOverviewQuery
   */
  numberOfGroupCandidates?: number;
  /**
   *
   * @type {string}
   * @memberof DuplicatesOverviewQuery
   */
  materialReferenceFilter?: string;
  /**
   *
   * @type {string}
   * @memberof DuplicatesOverviewQuery
   */
  search?: string;
  /**
   *
   * @type {DuplicatesSortOptions}
   * @memberof DuplicatesOverviewQuery
   */
  sortBy?: DuplicatesSortOptions;
}
/**
 *
 * @export
 * @interface DuplicatesSortOptions
 */
export interface DuplicatesSortOptions {
  /**
   *
   * @type {Array<SortByColumn>}
   * @memberof DuplicatesSortOptions
   */
  columns?: Array<SortByColumn>;
  /**
   *
   * @type {SortByGroupSize}
   * @memberof DuplicatesSortOptions
   */
  groupSize?: SortByGroupSize;
}
/**
 *
 * @export
 * @interface EmbeddedProviderInfo
 */
export interface EmbeddedProviderInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedProviderInfo
   */
  logo?: string;
}
/**
 *
 * @export
 * @interface FileReference
 */
export interface FileReference {
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FileReference
   */
  originalFileName: string;
}
/**
 *
 * @export
 * @interface FinishJob
 */
export interface FinishJob {
  /**
   *
   * @type {number}
   * @memberof FinishJob
   */
  duplicates: number;
  /**
   *
   * @type {number}
   * @memberof FinishJob
   */
  distinct: number;
  /**
   *
   * @type {number}
   * @memberof FinishJob
   */
  clarification: number;
  /**
   *
   * @type {string}
   * @memberof FinishJob
   */
  sptResult?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FinishJob
   */
  additionalFiles?: Array<string>;
}
/**
 *
 * @export
 * @interface FormField
 */
export interface FormField {
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FormField
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof FormField
   */
  required: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FormField
   */
  multipleValues: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FormField
   */
  lookup: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FormField
   */
  hasMaxLength: boolean;
}
/**
 *
 * @export
 * @interface FormFieldConfiguration
 */
export interface FormFieldConfiguration {
  /**
   *
   * @type {string}
   * @memberof FormFieldConfiguration
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldConfiguration
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldConfiguration
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldConfiguration
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldConfiguration
   */
  required: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldConfiguration
   */
  multipleValues: boolean;
  /**
   *
   * @type {RequiredCondition}
   * @memberof FormFieldConfiguration
   */
  requiredCondition?: RequiredCondition;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldConfiguration
   */
  readOnly: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldConfiguration
   */
  bulkEditable?: boolean;
  /**
   *
   * @type {FormFieldSettings}
   * @memberof FormFieldConfiguration
   */
  settings: FormFieldSettings;
}
/**
 *
 * @export
 * @interface FormFieldData
 */
export interface FormFieldData {
  /**
   *
   * @type {string}
   * @memberof FormFieldData
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldData
   */
  value: string;
}
/**
 *
 * @export
 * @interface FormFieldDetails
 */
export interface FormFieldDetails {
  /**
   *
   * @type {boolean}
   * @memberof FormFieldDetails
   */
  inGroup: boolean;
  /**
   *
   * @type {string}
   * @memberof FormFieldDetails
   */
  layoutSubpage?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldDetails
   */
  display: boolean;
  /**
   *
   * @type {string}
   * @memberof FormFieldDetails
   */
  databaseField?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldDetails
   */
  exportedAs?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldDetails
   */
  materialColumn?: string;
}
/**
 *
 * @export
 * @interface FormFieldGroup
 */
export interface FormFieldGroup {
  /**
   *
   * @type {string}
   * @memberof FormFieldGroup
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldGroup
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldGroup
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldGroup
   */
  required: boolean;
}
/**
 *
 * @export
 * @interface FormFieldGroupConfiguration
 */
export interface FormFieldGroupConfiguration {
  /**
   *
   * @type {string}
   * @memberof FormFieldGroupConfiguration
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldGroupConfiguration
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldGroupConfiguration
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldGroupConfiguration
   */
  required: boolean;
  /**
   *
   * @type {Array<FormField>}
   * @memberof FormFieldGroupConfiguration
   */
  fields: Array<FormField>;
  /**
   *
   * @type {string}
   * @memberof FormFieldGroupConfiguration
   */
  plantFieldId: string;
}
/**
 *
 * @export
 * @interface FormFieldI18n
 */
export interface FormFieldI18n {
  /**
   *
   * @type {string}
   * @memberof FormFieldI18n
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldI18n
   */
  language: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldI18n
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldI18n
   */
  description?: string;
}
/**
 *
 * @export
 * @interface FormFieldI18ns
 */
export interface FormFieldI18ns {
  /**
   *
   * @type {Array<FormFieldI18n>}
   * @memberof FormFieldI18ns
   */
  fields: Array<FormFieldI18n>;
}
/**
 *
 * @export
 * @interface FormFieldMap
 */
export interface FormFieldMap {
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  formFieldName?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldMap
   */
  formFieldId: string;
  /**
   *
   * @type {Array<FormFieldData>}
   * @memberof FormFieldMap
   */
  data?: Array<FormFieldData>;
}
/**
 *
 * @export
 * @interface FormFieldMapping
 */
export interface FormFieldMapping {
  /**
   *
   * @type {Array<FormFieldMap>}
   * @memberof FormFieldMapping
   */
  data?: Array<FormFieldMap>;
}
/**
 *
 * @export
 * @interface FormFieldSettings
 */
export interface FormFieldSettings {
  /**
   *
   * @type {number}
   * @memberof FormFieldSettings
   */
  minimum?: number;
  /**
   *
   * @type {number}
   * @memberof FormFieldSettings
   */
  maximum?: number;
  /**
   *
   * @type {number}
   * @memberof FormFieldSettings
   */
  maxLength?: number;
  /**
   *
   * @type {number}
   * @memberof FormFieldSettings
   */
  minLength?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof FormFieldSettings
   */
  options?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof FormFieldSettings
   */
  lookupOptions?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FormFieldSettings
   */
  defaultValue?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldSettings
   */
  pattern?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldSettings
   */
  addOn?: string;
  /**
   *
   * @type {boolean}
   * @memberof FormFieldSettings
   */
  today?: boolean;
  /**
   *
   * @type {string}
   * @memberof FormFieldSettings
   */
  userFieldId?: string;
  /**
   *
   * @type {string}
   * @memberof FormFieldSettings
   */
  plantFieldId?: string;
}
/**
 *
 * @export
 * @interface FormLayout
 */
export interface FormLayout {
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  product: FormStage;
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  misc: FormStage;
  /**
   *
   * @type {FormStage}
   * @memberof FormLayout
   */
  inquiry: FormStage;
}
/**
 *
 * @export
 * @interface FormStage
 */
export interface FormStage {
  /**
   *
   * @type {TranslatableValues}
   * @memberof FormStage
   */
  title: TranslatableValues;
  /**
   *
   * @type {string}
   * @memberof FormStage
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof FormStage
   */
  description?: string;
  /**
   *
   * @type {Array<Array<LayoutItem>>}
   * @memberof FormStage
   */
  items: Array<Array<LayoutItem>>;
  /**
   *
   * @type {object}
   * @memberof FormStage
   */
  config?: object;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  manufacturerNameToMoveDetailsTo?: string;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {any}
   * @memberof InlineObject1
   */
  file: any;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {any}
   * @memberof InlineResponse200
   */
  file?: any;
}
/**
 *
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
  /**
   *
   * @type {number}
   * @memberof Inquiry
   */
  id: number;
  /**
   *
   * @type {Client}
   * @memberof Inquiry
   */
  client: Client;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  comment?: string;
  /**
   *
   * @type {User}
   * @memberof Inquiry
   */
  user: User;
  /**
   *
   * @type {ManufacturerDetails}
   * @memberof Inquiry
   */
  manufacturer: ManufacturerDetails;
  /**
   *
   * @type {InquiryMeta}
   * @memberof Inquiry
   */
  meta: InquiryMeta;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  dueDate: string;
}
/**
 *
 * @export
 * @interface InquiryDetails
 */
export interface InquiryDetails {
  /**
   *
   * @type {number}
   * @memberof InquiryDetails
   */
  id: number;
  /**
   *
   * @type {Client}
   * @memberof InquiryDetails
   */
  client: Client;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  createdAt: string;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof InquiryDetails
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  comment?: string;
  /**
   *
   * @type {User}
   * @memberof InquiryDetails
   */
  user: User;
  /**
   *
   * @type {ManufacturerDetails}
   * @memberof InquiryDetails
   */
  manufacturer: ManufacturerDetails;
  /**
   *
   * @type {InquiryMeta}
   * @memberof InquiryDetails
   */
  meta: InquiryMeta;
  /**
   *
   * @type {string}
   * @memberof InquiryDetails
   */
  dueDate: string;
  /**
   *
   * @type {Array<InquiryResult>}
   * @memberof InquiryDetails
   */
  results: Array<InquiryResult>;
}
/**
 *
 * @export
 * @interface InquiryMeta
 */
export interface InquiryMeta {
  /**
   *
   * @type {number}
   * @memberof InquiryMeta
   */
  numberOfItems: number;
}
/**
 *
 * @export
 * @interface InquiryResult
 */
export interface InquiryResult {
  /**
   *
   * @type {number}
   * @memberof InquiryResult
   */
  id: number;
  /**
   *
   * @type {Array<FileReference>}
   * @memberof InquiryResult
   */
  files: Array<FileReference>;
  /**
   *
   * @type {string}
   * @memberof InquiryResult
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof InquiryResult
   */
  date: string;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {number}
   * @memberof Job
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  client?: string;
  /**
   *
   * @type {User}
   * @memberof Job
   */
  author?: User;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  businessEntity?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  estimate?: string;
}
/**
 *
 * @export
 * @interface Jobs
 */
export interface Jobs {
  /**
   *
   * @type {number}
   * @memberof Jobs
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<Job>}
   * @memberof Jobs
   */
  data: Array<Job>;
}
/**
 *
 * @export
 * @interface LabelValuePair
 */
export interface LabelValuePair {
  /**
   *
   * @type {string}
   * @memberof LabelValuePair
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof LabelValuePair
   */
  value: string;
}
/**
 *
 * @export
 * @interface LanguageInformation
 */
export interface LanguageInformation {
  /**
   *
   * @type {number}
   * @memberof LanguageInformation
   */
  businessEntityId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof LanguageInformation
   */
  availableLanguages: Array<string>;
  /**
   *
   * @type {string}
   * @memberof LanguageInformation
   */
  basicLanguage: string;
}
/**
 *
 * @export
 * @interface LayoutItem
 */
export interface LayoutItem {
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof LayoutItem
   */
  property?: string;
  /**
   *
   * @type {TranslatableValues}
   * @memberof LayoutItem
   */
  heading?: TranslatableValues;
  /**
   *
   * @type {TranslatableValues}
   * @memberof LayoutItem
   */
  subHeading?: TranslatableValues;
}
/**
 *
 * @export
 * @interface ListExport
 */
export interface ListExport {
  /**
   *
   * @type {FileReference}
   * @memberof ListExport
   */
  file: FileReference;
  /**
   *
   * @type {string}
   * @memberof ListExport
   */
  exportedAt: string;
}
/**
 *
 * @export
 * @interface ListExports
 */
export interface ListExports {
  /**
   *
   * @type {Array<ListExport>}
   * @memberof ListExports
   */
  exports: Array<ListExport>;
}
/**
 *
 * @export
 * @interface ListToMaterialMasterLink
 */
export interface ListToMaterialMasterLink {
  /**
   *
   * @type {string}
   * @memberof ListToMaterialMasterLink
   */
  materialMasterReference: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ListToMaterialMasterLink
   */
  cells: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ListToMaterialMasterLink
   */
  isExactMatch: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ListToMaterialMasterLink
   */
  matchedInPages: Array<number>;
  /**
   *
   * @type {Array<ListVsMaterialMasterMatchedColumns>}
   * @memberof ListToMaterialMasterLink
   */
  matchedColumns: Array<ListVsMaterialMasterMatchedColumns>;
  /**
   *
   * @type {string}
   * @memberof ListToMaterialMasterLink
   */
  matchedSparetechId?: string;
}
/**
 *
 * @export
 * @interface ListToMaterialMasterMapping
 */
export interface ListToMaterialMasterMapping {
  /**
   *
   * @type {number}
   * @memberof ListToMaterialMasterMapping
   */
  listColumn: number;
  /**
   *
   * @type {Array<MaterialMasterColumnSettings>}
   * @memberof ListToMaterialMasterMapping
   */
  materialMasterColumns: Array<MaterialMasterColumnSettings>;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterAction
 */
export interface ListVsMaterialMasterAction {
  /**
   *
   * @type {string}
   * @memberof ListVsMaterialMasterAction
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ListVsMaterialMasterAction
   */
  materialMasterReference: string;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterActions
 */
export interface ListVsMaterialMasterActions {
  /**
   *
   * @type {Array<ListVsMaterialMasterAction>}
   * @memberof ListVsMaterialMasterActions
   */
  actions: Array<ListVsMaterialMasterAction>;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterGroup
 */
export interface ListVsMaterialMasterGroup {
  /**
   *
   * @type {number}
   * @memberof ListVsMaterialMasterGroup
   */
  listGroupNumber: number;
  /**
   *
   * @type {number}
   * @memberof ListVsMaterialMasterGroup
   */
  numberOfGroups: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ListVsMaterialMasterGroup
   */
  listGroupHeaders: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ListVsMaterialMasterGroup
   */
  linkHeaders: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ListVsMaterialMasterGroup
   */
  firstRowFromGroup: Array<string>;
  /**
   *
   * @type {Array<ListToMaterialMasterLink>}
   * @memberof ListVsMaterialMasterGroup
   */
  links: Array<ListToMaterialMasterLink>;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterMatchedColumns
 */
export interface ListVsMaterialMasterMatchedColumns {
  /**
   *
   * @type {number}
   * @memberof ListVsMaterialMasterMatchedColumns
   */
  listColumn: number;
  /**
   *
   * @type {Array<MatchedColumnsHighlights>}
   * @memberof ListVsMaterialMasterMatchedColumns
   */
  matched: Array<MatchedColumnsHighlights>;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterResults
 */
export interface ListVsMaterialMasterResults {
  /**
   *
   * @type {ListVsMaterialMasterGroup}
   * @memberof ListVsMaterialMasterResults
   */
  group?: ListVsMaterialMasterGroup;
  /**
   *
   * @type {MatchingInfo}
   * @memberof ListVsMaterialMasterResults
   */
  matchingInfo: MatchingInfo;
}
/**
 *
 * @export
 * @interface ListVsMaterialMasterSettings
 */
export interface ListVsMaterialMasterSettings {
  /**
   *
   * @type {number}
   * @memberof ListVsMaterialMasterSettings
   */
  materialMasterId?: number;
  /**
   *
   * @type {Array<ListToMaterialMasterMapping>}
   * @memberof ListVsMaterialMasterSettings
   */
  columnMappings?: Array<ListToMaterialMasterMapping>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MachineOperatorRoles {
  ClientAdministrator = "Client Administrator",
  MaterialCreationPlanner = "Material Creation Planner",
  MaterialCreationUploader = "Material Creation Uploader",
  MaterialChangePlanner = "Material Change Planner",
  MaterialChangeUploader = "Material Change Uploader",
  DataCleansingPlanner = "Data Cleansing Planner",
  BetaUser = "Beta User",
}

/**
 *
 * @export
 * @interface ManufacturerAddress
 */
export interface ManufacturerAddress {
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerAddress
   */
  zipCode?: string;
}
/**
 *
 * @export
 * @interface ManufacturerAggregation
 */
export interface ManufacturerAggregation {
  /**
   *
   * @type {ManufacturerShort}
   * @memberof ManufacturerAggregation
   */
  manufacturer: ManufacturerShort;
  /**
   *
   * @type {number}
   * @memberof ManufacturerAggregation
   */
  numberOfProducts: number;
}
/**
 *
 * @export
 * @interface ManufacturerConventions
 */
export interface ManufacturerConventions {
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  longDescription?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerConventions
   */
  remarks?: string;
}
/**
 *
 * @export
 * @interface ManufacturerDetails
 */
export interface ManufacturerDetails {
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  legalName: string;
  /**
   *
   * @type {ManufacturerAddress}
   * @memberof ManufacturerDetails
   */
  address?: ManufacturerAddress;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  abbreviation: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  duns?: string;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  isSupplier: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  hasProducts: boolean;
  /**
   *
   * @type {ManufacturerConventions}
   * @memberof ManufacturerDetails
   */
  conventions?: ManufacturerConventions;
  /**
   *
   * @type {string}
   * @memberof ManufacturerDetails
   */
  parent?: string;
  /**
   *
   * @type {number}
   * @memberof ManufacturerDetails
   */
  numberOfProducts?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  synonyms: Array<string>;
  /**
   *
   * @type {ManufacturerIdentificationRules}
   * @memberof ManufacturerDetails
   */
  identification?: ManufacturerIdentificationRules;
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerDetails
   */
  relatedManufacturers?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ManufacturerDetails
   */
  providesObsolescenceInfo: boolean;
}
/**
 *
 * @export
 * @interface ManufacturerIdentificationRules
 */
export interface ManufacturerIdentificationRules {
  /**
   *
   * @type {Array<string>}
   * @memberof ManufacturerIdentificationRules
   */
  fields: Array<string>;
}
/**
 *
 * @export
 * @interface ManufacturerList
 */
export interface ManufacturerList {
  /**
   *
   * @type {Array<ManufacturerDetails>}
   * @memberof ManufacturerList
   */
  manufacturers: Array<ManufacturerDetails>;
  /**
   *
   * @type {number}
   * @memberof ManufacturerList
   */
  numberOfManufacturers: number;
}
/**
 *
 * @export
 * @interface ManufacturerMapping
 */
export interface ManufacturerMapping {
  /**
   *
   * @type {Array<LabelValuePair>}
   * @memberof ManufacturerMapping
   */
  newMapping: Array<LabelValuePair>;
}
/**
 *
 * @export
 * @interface ManufacturerShort
 */
export interface ManufacturerShort {
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ManufacturerShort
   */
  name: string;
}
/**
 *
 * @export
 * @interface ManufacturerStatistics
 */
export interface ManufacturerStatistics {
  /**
   *
   * @type {ProductMatchingStatistics}
   * @memberof ManufacturerStatistics
   */
  latestStats?: ProductMatchingStatistics;
  /**
   *
   * @type {Array<TopProduct>}
   * @memberof ManufacturerStatistics
   */
  top10Products: Array<TopProduct>;
}
/**
 *
 * @export
 * @interface MatchedColumnsHighlights
 */
export interface MatchedColumnsHighlights {
  /**
   *
   * @type {number}
   * @memberof MatchedColumnsHighlights
   */
  column: number;
  /**
   *
   * @type {Array<ColumnHighlight>}
   * @memberof MatchedColumnsHighlights
   */
  indices: Array<ColumnHighlight>;
}
/**
 *
 * @export
 * @interface MatchedSparepart
 */
export interface MatchedSparepart {
  /**
   *
   * @type {string}
   * @memberof MatchedSparepart
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof MatchedSparepart
   */
  productCaption: string;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof MatchedSparepart
   */
  spareparts: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface MatchedSpareparts
 */
export interface MatchedSpareparts {
  /**
   *
   * @type {Array<string>}
   * @memberof MatchedSpareparts
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<MatchedSparepart>}
   * @memberof MatchedSpareparts
   */
  data: Array<MatchedSparepart>;
}
/**
 *
 * @export
 * @interface MatchingAction
 */
export interface MatchingAction {
  /**
   *
   * @type {number}
   * @memberof MatchingAction
   */
  id: number;
  /**
   *
   * @type {MatchingActionType}
   * @memberof MatchingAction
   */
  action: MatchingActionType;
}
/**
 *
 * @export
 * @interface MatchingActionResult
 */
export interface MatchingActionResult {
  /**
   *
   * @type {number}
   * @memberof MatchingActionResult
   */
  id: number;
  /**
   *
   * @type {MatchingActionType}
   * @memberof MatchingActionResult
   */
  action: MatchingActionType;
  /**
   *
   * @type {MatchingActionResultType}
   * @memberof MatchingActionResult
   */
  result: MatchingActionResultType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MatchingActionResultType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum MatchingActionType {
  DELETE = "DELETE",
  MATCH = "MATCH",
  UNMATCH = "UNMATCH",
}

/**
 *
 * @export
 * @interface MatchingBulkActionRequest
 */
export interface MatchingBulkActionRequest {
  /**
   *
   * @type {Array<MatchingAction>}
   * @memberof MatchingBulkActionRequest
   */
  items: Array<MatchingAction>;
}
/**
 *
 * @export
 * @interface MatchingBulkActionResponse
 */
export interface MatchingBulkActionResponse {
  /**
   *
   * @type {Array<MatchingActionResult>}
   * @memberof MatchingBulkActionResponse
   */
  items: Array<MatchingActionResult>;
}
/**
 *
 * @export
 * @interface MatchingCandidate
 */
export interface MatchingCandidate {
  /**
   *
   * @type {number}
   * @memberof MatchingCandidate
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MatchingCandidate
   */
  materialReference: string;
  /**
   *
   * @type {number}
   * @memberof MatchingCandidate
   */
  sparepartListId: number;
  /**
   *
   * @type {string}
   * @memberof MatchingCandidate
   */
  sparetechId: string;
  /**
   *
   * @type {string}
   * @memberof MatchingCandidate
   */
  productCaption?: string;
  /**
   *
   * @type {boolean}
   * @memberof MatchingCandidate
   */
  isMatched: boolean;
  /**
   *
   * @type {string}
   * @memberof MatchingCandidate
   */
  source: string;
  /**
   *
   * @type {object}
   * @memberof MatchingCandidate
   */
  matchedProductValues: object;
}
/**
 *
 * @export
 * @interface MatchingInfo
 */
export interface MatchingInfo {
  /**
   *
   * @type {string}
   * @memberof MatchingInfo
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof MatchingInfo
   */
  status: string;
  /**
   *
   * @type {number}
   * @memberof MatchingInfo
   */
  progress?: number;
}
/**
 *
 * @export
 * @interface MatchingRate
 */
export interface MatchingRate {
  /**
   *
   * @type {number}
   * @memberof MatchingRate
   */
  matched: number;
  /**
   *
   * @type {number}
   * @memberof MatchingRate
   */
  unmatched: number;
}
/**
 *
 * @export
 * @interface MatchingRates
 */
export interface MatchingRates {
  /**
   *
   * @type {MatchingRate}
   * @memberof MatchingRates
   */
  standardRate: MatchingRate;
  /**
   *
   * @type {MatchingRate}
   * @memberof MatchingRates
   */
  quickChecks: MatchingRate;
}
/**
 *
 * @export
 * @interface MatchingRow
 */
export interface MatchingRow {
  /**
   *
   * @type {string}
   * @memberof MatchingRow
   */
  materialReference: string;
  /**
   *
   * @type {number}
   * @memberof MatchingRow
   */
  rowIndex: number;
  /**
   *
   * @type {Array<MatchingCandidate>}
   * @memberof MatchingRow
   */
  matchingCandidates: Array<MatchingCandidate>;
  /**
   *
   * @type {number}
   * @memberof MatchingRow
   */
  sparepartListId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingRow
   */
  cells: Array<string>;
  /**
   *
   * @type {number}
   * @memberof MatchingRow
   */
  groupNumber?: number;
}
/**
 *
 * @export
 * @interface MatchingStatistics
 */
export interface MatchingStatistics {
  /**
   *
   * @type {number}
   * @memberof MatchingStatistics
   */
  greenCandidates: number;
  /**
   *
   * @type {number}
   * @memberof MatchingStatistics
   */
  rejectedGreenCandidates: number;
  /**
   *
   * @type {number}
   * @memberof MatchingStatistics
   */
  acceptedYellowCandidates: number;
  /**
   *
   * @type {MatchingRates}
   * @memberof MatchingStatistics
   */
  matchingRate?: MatchingRates;
}
/**
 *
 * @export
 * @interface MatchingTask
 */
export interface MatchingTask {
  /**
   *
   * @type {number}
   * @memberof MatchingTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MatchingTask
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MatchingTask
   */
  listType: MatchingTaskListTypeEnum;
  /**
   *
   * @type {BusinessEntity}
   * @memberof MatchingTask
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {FileReference}
   * @memberof MatchingTask
   */
  file: FileReference;
  /**
   *
   * @type {string}
   * @memberof MatchingTask
   */
  createdAt: string;
  /**
   *
   * @type {Array<MatchingInfo>}
   * @memberof MatchingTask
   */
  matchingInfo: Array<MatchingInfo>;
  /**
   *
   * @type {string}
   * @memberof MatchingTask
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof MatchingTask
   */
  quickCheck: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum MatchingTaskListTypeEnum {
  SparepartList = "SparepartList",
  MaterialMaster = "MaterialMaster",
  Update = "Update",
  Other = "Other",
}

/**
 *
 * @export
 * @interface MatchingTaskChanges
 */
export interface MatchingTaskChanges {
  /**
   *
   * @type {string}
   * @memberof MatchingTaskChanges
   */
  reference: string;
  /**
   *
   * @type {TaskChangeOccurrence}
   * @memberof MatchingTaskChanges
   */
  occurrence?: TaskChangeOccurrence;
  /**
   *
   * @type {Array<TaskChangeContent>}
   * @memberof MatchingTaskChanges
   */
  content?: Array<TaskChangeContent>;
}
/**
 *
 * @export
 * @interface MatchingTaskChangeset
 */
export interface MatchingTaskChangeset {
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingTaskChangeset
   */
  newRefs: Array<string>;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskChangeset
   */
  newRefsCount: number;
  /**
   *
   * @type {Array<MatchingTaskChanges>}
   * @memberof MatchingTaskChangeset
   */
  changed: Array<MatchingTaskChanges>;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskChangeset
   */
  changedCount: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingTaskChangeset
   */
  deleted: Array<string>;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskChangeset
   */
  deletedCount: number;
}
/**
 *
 * @export
 * @interface MatchingTaskDetails
 */
export interface MatchingTaskDetails {
  /**
   *
   * @type {MatchingTask}
   * @memberof MatchingTaskDetails
   */
  task: MatchingTask;
  /**
   *
   * @type {MatchingTaskUpdate}
   * @memberof MatchingTaskDetails
   */
  update?: MatchingTaskUpdate;
}
/**
 *
 * @export
 * @interface MatchingTaskList
 */
export interface MatchingTaskList {
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingTaskList
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof MatchingTaskList
   */
  rows: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface MatchingTaskListSettings
 */
export interface MatchingTaskListSettings {
  /**
   *
   * @type {Array<number>}
   * @memberof MatchingTaskListSettings
   */
  activeIndexes: Array<number>;
}
/**
 *
 * @export
 * @interface MatchingTaskListWithCandidates
 */
export interface MatchingTaskListWithCandidates {
  /**
   *
   * @type {number}
   * @memberof MatchingTaskListWithCandidates
   */
  id: number;
  /**
   *
   * @type {BusinessEntity}
   * @memberof MatchingTaskListWithCandidates
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingTaskListWithCandidates
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof MatchingTaskListWithCandidates
   */
  relevantHeadings: Array<string>;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskListWithCandidates
   */
  numberOfProcessedRows: number;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskListWithCandidates
   */
  numberOfResults?: number;
  /**
   *
   * @type {MatchingInfo}
   * @memberof MatchingTaskListWithCandidates
   */
  matchingInfo: MatchingInfo;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  creationDate: string;
  /**
   *
   * @type {Array<MatchingRow>}
   * @memberof MatchingTaskListWithCandidates
   */
  rows: Array<MatchingRow>;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  listType: string;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  lastMatchedAt?: string;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  lastReleasedAt?: string;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskListWithCandidates
   */
  lastUpdatedAt?: string;
}
/**
 *
 * @export
 * @interface MatchingTaskStatistics
 */
export interface MatchingTaskStatistics {
  /**
   *
   * @type {number}
   * @memberof MatchingTaskStatistics
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskStatistics
   */
  matched: number;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskStatistics
   */
  potential: number;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskStatistics
   */
  notFound: number;
}
/**
 *
 * @export
 * @interface MatchingTaskUpdate
 */
export interface MatchingTaskUpdate {
  /**
   *
   * @type {string}
   * @memberof MatchingTaskUpdate
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof MatchingTaskUpdate
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface MatchingTaskWithDuplicates
 */
export interface MatchingTaskWithDuplicates {
  /**
   *
   * @type {MatchingInfo}
   * @memberof MatchingTaskWithDuplicates
   */
  matchingInfo: MatchingInfo;
  /**
   *
   * @type {number}
   * @memberof MatchingTaskWithDuplicates
   */
  numberOfProcessedGroups?: number;
  /**
   *
   * @type {DuplicateGroup}
   * @memberof MatchingTaskWithDuplicates
   */
  group?: DuplicateGroup;
}
/**
 *
 * @export
 * @interface MatchingTasks
 */
export interface MatchingTasks {
  /**
   *
   * @type {number}
   * @memberof MatchingTasks
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<MatchingTask>}
   * @memberof MatchingTasks
   */
  tasks: Array<MatchingTask>;
}
/**
 *
 * @export
 * @interface Material
 */
export interface Material {
  /**
   *
   * @type {number}
   * @memberof Material
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  materialReference: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Material
   */
  cells: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Material
   */
  materialMasterId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Material
   */
  plants?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  rowPlant?: string;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasPendingChangeRequest?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasProductSuggestion?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasDuplicate?: boolean;
  /**
   *
   * @type {MaterialProductSuggestionStatus}
   * @memberof Material
   */
  status?: MaterialProductSuggestionStatus;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  productState?: string;
  /**
   *
   * @type {boolean}
   * @memberof Material
   */
  hasSuccessor?: boolean;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  foundBySearchStrategy?: string;
}
/**
 *
 * @export
 * @interface MaterialCreationStatistics
 */
export interface MaterialCreationStatistics {
  /**
   *
   * @type {number}
   * @memberof MaterialCreationStatistics
   */
  materialsCreated: number;
  /**
   *
   * @type {number}
   * @memberof MaterialCreationStatistics
   */
  createdFromDB: number;
  /**
   *
   * @type {number}
   * @memberof MaterialCreationStatistics
   */
  createdAfterSearch: number;
}
/**
 *
 * @export
 * @interface MaterialExportMapping
 */
export interface MaterialExportMapping {
  /**
   *
   * @type {string}
   * @memberof MaterialExportMapping
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof MaterialExportMapping
   */
  columnName?: string;
  /**
   *
   * @type {number}
   * @memberof MaterialExportMapping
   */
  sortOrder: number;
  /**
   *
   * @type {string}
   * @memberof MaterialExportMapping
   */
  value: string;
  /**
   *
   * @type {object}
   * @memberof MaterialExportMapping
   */
  content: object;
}
/**
 *
 * @export
 * @interface MaterialExportMappings
 */
export interface MaterialExportMappings {
  /**
   *
   * @type {Array<MaterialExportMapping>}
   * @memberof MaterialExportMappings
   */
  data: Array<MaterialExportMapping>;
}
/**
 *
 * @export
 * @interface MaterialForMatching
 */
export interface MaterialForMatching {
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialForMatching
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof MaterialForMatching
   */
  data: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface MaterialMaster
 */
export interface MaterialMaster {
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  id: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMaster
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Material>}
   * @memberof MaterialMaster
   */
  rows: Array<Material>;
  /**
   *
   * @type {number}
   * @memberof MaterialMaster
   */
  numberOfRows: number;
  /**
   *
   * @type {BusinessEntity}
   * @memberof MaterialMaster
   */
  businessEntity: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof MaterialMaster
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof MaterialMaster
   */
  updatedAt: string;
  /**
   *
   * @type {Array<number>}
   * @memberof MaterialMaster
   */
  displayColumns: Array<number>;
}
/**
 *
 * @export
 * @interface MaterialMasterColumnSettings
 */
export interface MaterialMasterColumnSettings {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterColumnSettings
   */
  type: string;
  /**
   *
   * @type {Array<number>}
   * @memberof MaterialMasterColumnSettings
   */
  columns: Array<number>;
}
/**
 *
 * @export
 * @interface MaterialMasterColumns
 */
export interface MaterialMasterColumns {
  /**
   *
   * @type {Array<number>}
   * @memberof MaterialMasterColumns
   */
  data: Array<number>;
}
/**
 *
 * @export
 * @interface MaterialMasterColumnsUpdate
 */
export interface MaterialMasterColumnsUpdate {
  /**
   *
   * @type {object}
   * @memberof MaterialMasterColumnsUpdate
   */
  oldToNewMapping: object;
}
/**
 *
 * @export
 * @interface MaterialMasterColumnsUpdatePreview
 */
export interface MaterialMasterColumnsUpdatePreview {
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMasterColumnsUpdatePreview
   */
  oldHeadings: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMasterColumnsUpdatePreview
   */
  newHeadings: Array<string>;
  /**
   *
   * @type {object}
   * @memberof MaterialMasterColumnsUpdatePreview
   */
  oldToNewMapping: object;
}
/**
 *
 * @export
 * @interface MaterialMasterImportConfiguration
 */
export interface MaterialMasterImportConfiguration {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterImportConfiguration
   */
  referenceColumn?: string;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterImportConfiguration
   */
  materialCreationId?: string;
}
/**
 *
 * @export
 * @interface MaterialMasterInfo
 */
export interface MaterialMasterInfo {
  /**
   *
   * @type {MaterialMasterLog}
   * @memberof MaterialMasterInfo
   */
  latestLog?: MaterialMasterLog;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterInfo
   */
  materialMasterId?: number;
}
/**
 *
 * @export
 * @interface MaterialMasterInput
 */
export interface MaterialMasterInput {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterInput
   */
  file: string;
  /**
   *
   * @type {MaterialMasterImportConfiguration}
   * @memberof MaterialMasterInput
   */
  configuration: MaterialMasterImportConfiguration;
  /**
   *
   * @type {boolean}
   * @memberof MaterialMasterInput
   */
  quickCheck: boolean;
}
/**
 *
 * @export
 * @interface MaterialMasterLanguage
 */
export interface MaterialMasterLanguage {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterLanguage
   */
  language: string;
}
/**
 *
 * @export
 * @interface MaterialMasterLog
 */
export interface MaterialMasterLog {
  /**
   *
   * @type {User}
   * @memberof MaterialMasterLog
   */
  user: User;
  /**
   *
   * @type {FileReference}
   * @memberof MaterialMasterLog
   */
  file: FileReference;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterLog
   */
  timestamp: string;
}
/**
 *
 * @export
 * @interface MaterialMasterLogs
 */
export interface MaterialMasterLogs {
  /**
   *
   * @type {Array<MaterialMasterLog>}
   * @memberof MaterialMasterLogs
   */
  items: Array<MaterialMasterLog>;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterLogs
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @interface MaterialMasterPreview
 */
export interface MaterialMasterPreview {
  /**
   *
   * @type {Array<string>}
   * @memberof MaterialMasterPreview
   */
  headings: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof MaterialMasterPreview
   */
  rows: Array<Array<string>>;
  /**
   *
   * @type {MaterialMasterImportConfiguration}
   * @memberof MaterialMasterPreview
   */
  settings?: MaterialMasterImportConfiguration;
}
/**
 *
 * @export
 * @interface MaterialMasterStatistics
 */
export interface MaterialMasterStatistics {
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterStatistics
   */
  timestamp: string;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  materialCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  greenCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  potentialCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  duplicateCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  obsoleteCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  redeemedCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  successorCount?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  missingCount: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterStatistics
   */
  obsoleteWithSuccessorCount?: number;
  /**
   *
   * @type {SiemensStatistics}
   * @memberof MaterialMasterStatistics
   */
  siemensStatistics?: SiemensStatistics;
}
/**
 *
 * @export
 * @interface MaterialMasterUpdateStatus
 */
export interface MaterialMasterUpdateStatus {
  /**
   *
   * @type {string}
   * @memberof MaterialMasterUpdateStatus
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterUpdateStatus
   */
  createdAt?: string;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterUpdateStatus
   */
  materialMasterId?: number;
  /**
   *
   * @type {number}
   * @memberof MaterialMasterUpdateStatus
   */
  matchingTaskId?: number;
  /**
   *
   * @type {string}
   * @memberof MaterialMasterUpdateStatus
   */
  fileId?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MaterialProductSuggestionStatus {
  SUGGESTED = "SUGGESTED",
  APPLIED = "APPLIED",
  EXTERNALLYADDED = "EXTERNALLY_ADDED",
}

/**
 *
 * @export
 * @interface NewBusinessEntity
 */
export interface NewBusinessEntity {
  /**
   *
   * @type {string}
   * @memberof NewBusinessEntity
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof NewBusinessEntity
   */
  parentId?: number;
  /**
   *
   * @type {boolean}
   * @memberof NewBusinessEntity
   */
  hasMaterialMaster?: boolean;
}
/**
 *
 * @export
 * @interface NewClient
 */
export interface NewClient {
  /**
   *
   * @type {string}
   * @memberof NewClient
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NewClient
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof NewClient
   */
  documentationName?: string;
  /**
   *
   * @type {string}
   * @memberof NewClient
   */
  documentationUrl?: string;
  /**
   *
   * @type {string}
   * @memberof NewClient
   */
  auth0ConnectionName?: string;
  /**
   *
   * @type {boolean}
   * @memberof NewClient
   */
  ssoOnly?: boolean;
}
/**
 *
 * @export
 * @interface NewFieldGroup
 */
export interface NewFieldGroup {
  /**
   *
   * @type {string}
   * @memberof NewFieldGroup
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewFieldGroup
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof NewFieldGroup
   */
  required: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof NewFieldGroup
   */
  fieldIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NewFieldGroup
   */
  plantFieldId: string;
}
/**
 *
 * @export
 * @interface NewInquiry
 */
export interface NewInquiry {
  /**
   *
   * @type {number}
   * @memberof NewInquiry
   */
  client: number;
  /**
   *
   * @type {string}
   * @memberof NewInquiry
   */
  comment?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NewInquiry
   */
  files: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NewInquiry
   */
  userId: string;
  /**
   *
   * @type {number}
   * @memberof NewInquiry
   */
  numberOfItems: number;
  /**
   *
   * @type {string}
   * @memberof NewInquiry
   */
  dueDate: string;
}
/**
 *
 * @export
 * @interface NewManufacturer
 */
export interface NewManufacturer {
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  legalName: string;
  /**
   *
   * @type {ManufacturerAddress}
   * @memberof NewManufacturer
   */
  address?: ManufacturerAddress;
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  duns?: string;
  /**
   *
   * @type {boolean}
   * @memberof NewManufacturer
   */
  isSupplier: boolean;
  /**
   *
   * @type {ManufacturerConventions}
   * @memberof NewManufacturer
   */
  conventions?: ManufacturerConventions;
  /**
   *
   * @type {string}
   * @memberof NewManufacturer
   */
  parent?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NewManufacturer
   */
  synonyms: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof NewManufacturer
   */
  relatedManufacturers?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof NewManufacturer
   */
  hasProducts: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewManufacturer
   */
  providesObsolescenceInfo: boolean;
}
/**
 *
 * @export
 * @interface NewManufacturerUser
 */
export interface NewManufacturerUser {
  /**
   *
   * @type {string}
   * @memberof NewManufacturerUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturerUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturerUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturerUser
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof NewManufacturerUser
   */
  manufacturerId: string;
}
/**
 *
 * @export
 * @interface NewMatchingTask
 */
export interface NewMatchingTask {
  /**
   *
   * @type {number}
   * @memberof NewMatchingTask
   */
  businessEntityId: number;
  /**
   *
   * @type {string}
   * @memberof NewMatchingTask
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof NewMatchingTask
   */
  listType: string;
  /**
   *
   * @type {boolean}
   * @memberof NewMatchingTask
   */
  quickCheck: boolean;
}
/**
 *
 * @export
 * @interface NewUser
 */
export interface NewUser {
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  telephone?: string;
  /**
   *
   * @type {number}
   * @memberof NewUser
   */
  businessEntityId: number;
  /**
   *
   * @type {number}
   * @memberof NewUser
   */
  clientId: number;
  /**
   *
   * @type {boolean}
   * @memberof NewUser
   */
  blocked?: boolean;
  /**
   *
   * @type {Array<MachineOperatorRoles>}
   * @memberof NewUser
   */
  roles: Array<MachineOperatorRoles>;
  /**
   *
   * @type {boolean}
   * @memberof NewUser
   */
  isGhostUser?: boolean;
}
/**
 *
 * @export
 * @interface NonMatchedPartsStatistic
 */
export interface NonMatchedPartsStatistic {
  /**
   *
   * @type {Client}
   * @memberof NonMatchedPartsStatistic
   */
  client: Client;
  /**
   *
   * @type {string}
   * @memberof NonMatchedPartsStatistic
   */
  matchingDate: string;
  /**
   *
   * @type {number}
   * @memberof NonMatchedPartsStatistic
   */
  matchingTask: number;
  /**
   *
   * @type {Array<string>}
   * @memberof NonMatchedPartsStatistic
   */
  manufacturers: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NonMatchedPartsStatistic
   */
  listType: string;
  /**
   *
   * @type {string}
   * @memberof NonMatchedPartsStatistic
   */
  rowContent: string;
}
/**
 *
 * @export
 * @interface NonMatchedPartsStatisticResults
 */
export interface NonMatchedPartsStatisticResults {
  /**
   *
   * @type {Array<NonMatchedPartsStatistic>}
   * @memberof NonMatchedPartsStatisticResults
   */
  results: Array<NonMatchedPartsStatistic>;
  /**
   *
   * @type {number}
   * @memberof NonMatchedPartsStatisticResults
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @interface PotentialSuccessorsResponse
 */
export interface PotentialSuccessorsResponse {
  /**
   *
   * @type {Array<ProductWithPotentialSuccessors>}
   * @memberof PotentialSuccessorsResponse
   */
  items: Array<ProductWithPotentialSuccessors>;
  /**
   *
   * @type {number}
   * @memberof PotentialSuccessorsResponse
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  sparetechId: string;
  /**
   *
   * @type {ManufacturerShort}
   * @memberof Product
   */
  manufacturer: ManufacturerShort;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  gtin?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  shortDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  longDescription: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  image?: string;
  /**
   *
   * @type {ProductSource}
   * @memberof Product
   */
  source: ProductSource;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  isConfigurable: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  successorId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  predecessors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  material?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  duplicateMaterial?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ProductAttribute
 */
export interface ProductAttribute {
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isIdAttribute: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductAttribute
   */
  isRelevant: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductAttribute
   */
  id?: string;
}
/**
 * Data object for batch deletion of products
 * @export
 * @interface ProductBatchDeleteRequest
 */
export interface ProductBatchDeleteRequest {
  /**
   * S3 fileId of CSV with products to remove
   * @type {string}
   * @memberof ProductBatchDeleteRequest
   */
  fileId: string;
}
/**
 *
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof ProductCategory
   */
  parentId?: string;
}
/**
 *
 * @export
 * @interface ProductDbSourceStatistics
 */
export interface ProductDbSourceStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  manufacturerCatalog: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  manufacturerResearch: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  manufacturerCrawler: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  manufacturerInquiry: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  supplierCatalog: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  supplierResearch: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  supplierCrawler: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  dataProviderCrawler: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  idValuesSize1: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  idValuesSize2: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbSourceStatistics
   */
  idValuesSize3: number;
}
/**
 *
 * @export
 * @interface ProductDbStatistics
 */
export interface ProductDbStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  products: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  publicProducts: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  internalProducts: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  configurableProducts: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  withSuccessor: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatistics
   */
  withImage: number;
  /**
   *
   * @type {ProductDbSourceStatistics}
   * @memberof ProductDbStatistics
   */
  sources: ProductDbSourceStatistics;
  /**
   *
   * @type {ProductDbStatusStatistics}
   * @memberof ProductDbStatistics
   */
  status: ProductDbStatusStatistics;
}
/**
 *
 * @export
 * @interface ProductDbStatusStatistics
 */
export interface ProductDbStatusStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductDbStatusStatistics
   */
  active: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatusStatistics
   */
  redeemed: number;
  /**
   *
   * @type {number}
   * @memberof ProductDbStatusStatistics
   */
  obsolete: number;
}
/**
 *
 * @export
 * @interface ProductDetails
 */
export interface ProductDetails {
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  gtin?: string;
  /**
   *
   * @type {EmbeddedProviderInfo}
   * @memberof ProductDetails
   */
  provider: EmbeddedProviderInfo;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  supplierArticleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  source: string;
  /**
   *
   * @type {ProductCategory}
   * @memberof ProductDetails
   */
  category?: ProductCategory;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  expiryDate?: string;
  /**
   *
   * @type {ProductImage}
   * @memberof ProductDetails
   */
  image?: ProductImage;
  /**
   *
   * @type {SourcingInformation}
   * @memberof ProductDetails
   */
  sourcingInfo?: SourcingInformation;
  /**
   *
   * @type {Array<ProductValue>}
   * @memberof ProductDetails
   */
  values?: Array<ProductValue>;
}
/**
 *
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  caption?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  type: string;
}
/**
 *
 * @export
 * @interface ProductImportJob
 */
export interface ProductImportJob {
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  author: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  providerName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductImportJob
   */
  uploadImages?: boolean;
  /**
   *
   * @type {FileReference}
   * @memberof ProductImportJob
   */
  file: FileReference;
  /**
   *
   * @type {string}
   * @memberof ProductImportJob
   */
  assets?: string;
}
/**
 *
 * @export
 * @interface ProductImportJobs
 */
export interface ProductImportJobs {
  /**
   *
   * @type {number}
   * @memberof ProductImportJobs
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<ProductImportJob>}
   * @memberof ProductImportJobs
   */
  data: Array<ProductImportJob>;
}
/**
 *
 * @export
 * @interface ProductImportReview
 */
export interface ProductImportReview {
  /**
   *
   * @type {ProductImportJob}
   * @memberof ProductImportReview
   */
  job: ProductImportJob;
  /**
   *
   * @type {Array<ProductReviewDocument>}
   * @memberof ProductImportReview
   */
  reviewDocuments: Array<ProductReviewDocument>;
  /**
   *
   * @type {ProductImportStatistics}
   * @memberof ProductImportReview
   */
  statistics: ProductImportStatistics;
}
/**
 *
 * @export
 * @interface ProductImportStatistics
 */
export interface ProductImportStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductImportStatistics
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof ProductImportStatistics
   */
  numberOfNew: number;
  /**
   *
   * @type {number}
   * @memberof ProductImportStatistics
   */
  numberOfUpdate: number;
  /**
   *
   * @type {number}
   * @memberof ProductImportStatistics
   */
  numberOfConflict: number;
}
/**
 *
 * @export
 * @interface ProductImportValidation
 */
export interface ProductImportValidation {
  /**
   *
   * @type {number}
   * @memberof ProductImportValidation
   */
  rowNumber: number;
  /**
   *
   * @type {Array<ProductImportValidationError>}
   * @memberof ProductImportValidation
   */
  errors: Array<ProductImportValidationError>;
}
/**
 *
 * @export
 * @interface ProductImportValidationError
 */
export interface ProductImportValidationError {
  /**
   *
   * @type {number}
   * @memberof ProductImportValidationError
   */
  columnNumber: number;
  /**
   *
   * @type {string}
   * @memberof ProductImportValidationError
   */
  error: string;
  /**
   *
   * @type {string}
   * @memberof ProductImportValidationError
   */
  cellValue: string;
}
/**
 *
 * @export
 * @interface ProductMatchingStatistics
 */
export interface ProductMatchingStatistics {
  /**
   *
   * @type {number}
   * @memberof ProductMatchingStatistics
   */
  matchedCount: number;
  /**
   *
   * @type {number}
   * @memberof ProductMatchingStatistics
   */
  notMatchedCount: number;
  /**
   *
   * @type {string}
   * @memberof ProductMatchingStatistics
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface ProductPrice
 */
export interface ProductPrice {
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ProductPrice
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ProductPrice
   */
  originalPrice?: number;
}
/**
 *
 * @export
 * @interface ProductReport
 */
export interface ProductReport {
  /**
   *
   * @type {string}
   * @memberof ProductReport
   */
  sparetechId: string;
  /**
   *
   * @type {string}
   * @memberof ProductReport
   */
  reason: string;
  /**
   *
   * @type {User}
   * @memberof ProductReport
   */
  user?: User;
  /**
   *
   * @type {string}
   * @memberof ProductReport
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReport
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ProductReport
   */
  id?: string;
  /**
   *
   * @type {Product}
   * @memberof ProductReport
   */
  product?: Product;
}
/**
 *
 * @export
 * @interface ProductReports
 */
export interface ProductReports {
  /**
   *
   * @type {number}
   * @memberof ProductReports
   */
  numberOfResults: number;
  /**
   *
   * @type {Array<ProductReport>}
   * @memberof ProductReports
   */
  data: Array<ProductReport>;
}
/**
 *
 * @export
 * @interface ProductReviewCandidate
 */
export interface ProductReviewCandidate {
  /**
   *
   * @type {string}
   * @memberof ProductReviewCandidate
   */
  sparetechId: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewCandidate
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewCandidate
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewCandidate
   */
  gtin?: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewCandidate
   */
  source: string;
}
/**
 * A candidate to match a product in the import process
 * @export
 * @interface ProductReviewCandidates
 */
export interface ProductReviewCandidates {
  /**
   *
   * @type {number}
   * @memberof ProductReviewCandidates
   */
  selectedCandidate?: number;
  /**
   *
   * @type {Array<ProductReviewCandidate>}
   * @memberof ProductReviewCandidates
   */
  candidates: Array<ProductReviewCandidate>;
}
/**
 *
 * @export
 * @interface ProductReviewDocument
 */
export interface ProductReviewDocument {
  /**
   *
   * @type {string}
   * @memberof ProductReviewDocument
   */
  id?: string;
  /**
   *
   * @type {RawProduct}
   * @memberof ProductReviewDocument
   */
  product: RawProduct;
  /**
   *
   * @type {ProductReviewCandidates}
   * @memberof ProductReviewDocument
   */
  candidates?: ProductReviewCandidates;
  /**
   *
   * @type {string}
   * @memberof ProductReviewDocument
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof ProductReviewDocument
   */
  importJobId: string;
}
/**
 *
 * @export
 * @interface ProductSearchAggregations
 */
export interface ProductSearchAggregations {
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  category?: Array<ProductSearchFilter>;
  /**
   *
   * @type {Array<ProductSearchFilter>}
   * @memberof ProductSearchAggregations
   */
  manufacturer?: Array<ProductSearchFilter>;
}
/**
 *
 * @export
 * @interface ProductSearchFilter
 */
export interface ProductSearchFilter {
  /**
   *
   * @type {string}
   * @memberof ProductSearchFilter
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ProductSearchFilter
   */
  numberOfProducts: number;
}
/**
 *
 * @export
 * @interface ProductSearchResults
 */
export interface ProductSearchResults {
  /**
   *
   * @type {ProductSearchAggregations}
   * @memberof ProductSearchResults
   */
  aggregations?: ProductSearchAggregations;
  /**
   *
   * @type {number}
   * @memberof ProductSearchResults
   */
  numberOfResults?: number;
  /**
   *
   * @type {Array<Product>}
   * @memberof ProductSearchResults
   */
  results: Array<Product>;
}
/**
 *
 * @export
 * @interface ProductSource
 */
export interface ProductSource {
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  origin: string;
  /**
   *
   * @type {string}
   * @memberof ProductSource
   */
  format: string;
}
/**
 *
 * @export
 * @interface ProductSuccessorInfo
 */
export interface ProductSuccessorInfo {
  /**
   *
   * @type {string}
   * @memberof ProductSuccessorInfo
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSuccessorInfo
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductSuccessorInfo
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProductValue
 */
export interface ProductValue {
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof ProductValue
   */
  unit?: string;
  /**
   *
   * @type {ProductAttribute}
   * @memberof ProductValue
   */
  attribute: ProductAttribute;
}
/**
 *
 * @export
 * @interface ProductWithPotentialSuccessors
 */
export interface ProductWithPotentialSuccessors {
  /**
   *
   * @type {Product}
   * @memberof ProductWithPotentialSuccessors
   */
  product: Product;
  /**
   *
   * @type {Array<Product>}
   * @memberof ProductWithPotentialSuccessors
   */
  potentialSuccessors: Array<Product>;
  /**
   *
   * @type {Array<ProductSuccessorInfo>}
   * @memberof ProductWithPotentialSuccessors
   */
  successorInfos: Array<ProductSuccessorInfo>;
}
/**
 *
 * @export
 * @interface RawAsset
 */
export interface RawAsset {
  /**
   *
   * @type {string}
   * @memberof RawAsset
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof RawAsset
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof RawAsset
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RawAsset
   */
  purpose?: string;
}
/**
 *
 * @export
 * @interface RawFeature
 */
export interface RawFeature {
  /**
   *
   * @type {string}
   * @memberof RawFeature
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RawFeature
   */
  classificationId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RawFeature
   */
  values?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RawFeature
   */
  unit?: string;
  /**
   *
   * @type {string}
   * @memberof RawFeature
   */
  valueType?: string;
}
/**
 *
 * @export
 * @interface RawProduct
 */
export interface RawProduct {
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  sparetechId?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  shortText?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  longText?: string;
  /**
   *
   * @type {Array<RawFeature>}
   * @memberof RawProduct
   */
  features?: Array<RawFeature>;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  classificationSystem?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  classificationVersion?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  classificationCategory?: string;
  /**
   *
   * @type {RawSourcingInfo}
   * @memberof RawProduct
   */
  sourcingInfo?: RawSourcingInfo;
  /**
   *
   * @type {Array<RawSourcingInfo>}
   * @memberof RawProduct
   */
  supplierSourcingInfo?: Array<RawSourcingInfo>;
  /**
   *
   * @type {Array<RawAsset>}
   * @memberof RawProduct
   */
  assets?: Array<RawAsset>;
  /**
   *
   * @type {ProductSuccessorInfo}
   * @memberof RawProduct
   */
  successorInfo?: ProductSuccessorInfo;
  /**
   *
   * @type {boolean}
   * @memberof RawProduct
   */
  isConfigurable?: boolean;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  expiryDate?: string;
  /**
   *
   * @type {string}
   * @memberof RawProduct
   */
  fileId?: string;
}
/**
 *
 * @export
 * @interface RawSourcingInfo
 */
export interface RawSourcingInfo {
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  supplier?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  delivery?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  price?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  originalPrice?: string;
  /**
   *
   * @type {string}
   * @memberof RawSourcingInfo
   */
  currency?: string;
}
/**
 *
 * @export
 * @interface ReMatchRequest
 */
export interface ReMatchRequest {
  /**
   *
   * @type {string}
   * @memberof ReMatchRequest
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof ReMatchRequest
   */
  field: string;
}
/**
 *
 * @export
 * @interface RejectedGreenCandidate
 */
export interface RejectedGreenCandidate {
  /**
   *
   * @type {Product}
   * @memberof RejectedGreenCandidate
   */
  product: Product;
  /**
   *
   * @type {Array<RejectedGreenMatch>}
   * @memberof RejectedGreenCandidate
   */
  matches: Array<RejectedGreenMatch>;
}
/**
 *
 * @export
 * @interface RejectedGreenCandidates
 */
export interface RejectedGreenCandidates {
  /**
   *
   * @type {Array<RejectedGreenCandidate>}
   * @memberof RejectedGreenCandidates
   */
  candidates: Array<RejectedGreenCandidate>;
  /**
   *
   * @type {number}
   * @memberof RejectedGreenCandidates
   */
  numberOfResults: number;
}
/**
 *
 * @export
 * @interface RejectedGreenMatch
 */
export interface RejectedGreenMatch {
  /**
   *
   * @type {number}
   * @memberof RejectedGreenMatch
   */
  taskId: number;
  /**
   *
   * @type {string}
   * @memberof RejectedGreenMatch
   */
  materialReference: string;
  /**
   *
   * @type {string}
   * @memberof RejectedGreenMatch
   */
  clientName: string;
  /**
   *
   * @type {string}
   * @memberof RejectedGreenMatch
   */
  rejectedAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RejectedGreenMatch
   */
  matchedCells: Array<string>;
}
/**
 *
 * @export
 * @interface ReleaseInformation
 */
export interface ReleaseInformation {
  /**
   *
   * @type {Array<string>}
   * @memberof ReleaseInformation
   */
  referencesToRelease?: Array<string>;
  /**
   *
   * @type {ReleaseInformationStatistics}
   * @memberof ReleaseInformation
   */
  statistics?: ReleaseInformationStatistics;
}
/**
 *
 * @export
 * @interface ReleaseInformationStatistics
 */
export interface ReleaseInformationStatistics {
  /**
   *
   * @type {number}
   * @memberof ReleaseInformationStatistics
   */
  potential?: number;
}
/**
 *
 * @export
 * @interface RequiredCondition
 */
export interface RequiredCondition {
  /**
   *
   * @type {string}
   * @memberof RequiredCondition
   */
  dependencyProvider: string;
  /**
   *
   * @type {string}
   * @memberof RequiredCondition
   */
  value: string;
}
/**
 *
 * @export
 * @interface RuleEngineImport
 */
export interface RuleEngineImport {
  /**
   *
   * @type {string}
   * @memberof RuleEngineImport
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof RuleEngineImport
   */
  assets?: string;
  /**
   *
   * @type {string}
   * @memberof RuleEngineImport
   */
  provider: string;
  /**
   *
   * @type {boolean}
   * @memberof RuleEngineImport
   */
  uploadImages?: boolean;
  /**
   *
   * @type {string}
   * @memberof RuleEngineImport
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof RuleEngineImport
   */
  source: string;
}
/**
 *
 * @export
 * @interface S3UploadDestination
 */
export interface S3UploadDestination {
  /**
   *
   * @type {string}
   * @memberof S3UploadDestination
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof S3UploadDestination
   */
  fileId: string;
  /**
   *
   * @type {string}
   * @memberof S3UploadDestination
   */
  contentType: string;
}
/**
 *
 * @export
 * @interface SiemensStatistics
 */
export interface SiemensStatistics {
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  materialCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  obsoleteCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  redeemedCount: number;
  /**
   *
   * @type {number}
   * @memberof SiemensStatistics
   */
  obsoleteWithSuccessorCount: number;
}
/**
 *
 * @export
 * @interface SimpleMatchingTask
 */
export interface SimpleMatchingTask {
  /**
   *
   * @type {number}
   * @memberof SimpleMatchingTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SimpleMatchingTask
   */
  name: string;
}
/**
 *
 * @export
 * @interface SortByColumn
 */
export interface SortByColumn {
  /**
   *
   * @type {number}
   * @memberof SortByColumn
   */
  column: number;
  /**
   *
   * @type {SortOrder}
   * @memberof SortByColumn
   */
  order: SortOrder;
}
/**
 *
 * @export
 * @interface SortByGroupSize
 */
export interface SortByGroupSize {
  /**
   *
   * @type {SortOrder}
   * @memberof SortByGroupSize
   */
  order: SortOrder;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 *
 * @export
 * @interface SourcingInformation
 */
export interface SourcingInformation {
  /**
   *
   * @type {ProductPrice}
   * @memberof SourcingInformation
   */
  price?: ProductPrice;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  delivery?: string;
  /**
   *
   * @type {string}
   * @memberof SourcingInformation
   */
  link?: string;
}
/**
 *
 * @export
 * @interface SystemStatistics
 */
export interface SystemStatistics {
  /**
   *
   * @type {ProductDbStatistics}
   * @memberof SystemStatistics
   */
  productDb: ProductDbStatistics;
  /**
   *
   * @type {MatchingStatistics}
   * @memberof SystemStatistics
   */
  matching: MatchingStatistics;
  /**
   *
   * @type {string}
   * @memberof SystemStatistics
   */
  timestamp: string;
  /**
   *
   * @type {MaterialCreationStatistics}
   * @memberof SystemStatistics
   */
  materialCreation?: MaterialCreationStatistics;
}
/**
 *
 * @export
 * @interface TaskChangeContent
 */
export interface TaskChangeContent {
  /**
   *
   * @type {string}
   * @memberof TaskChangeContent
   */
  heading: string;
  /**
   *
   * @type {number}
   * @memberof TaskChangeContent
   */
  index: number;
  /**
   *
   * @type {string}
   * @memberof TaskChangeContent
   */
  oldValue: string;
  /**
   *
   * @type {string}
   * @memberof TaskChangeContent
   */
  newValue: string;
}
/**
 *
 * @export
 * @interface TaskChangeOccurrence
 */
export interface TaskChangeOccurrence {
  /**
   *
   * @type {number}
   * @memberof TaskChangeOccurrence
   */
  oldIndex: number;
  /**
   *
   * @type {number}
   * @memberof TaskChangeOccurrence
   */
  newIndex: number;
}
/**
 *
 * @export
 * @interface TopProduct
 */
export interface TopProduct {
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  sparetechId: string;
  /**
   *
   * @type {number}
   * @memberof TopProduct
   */
  count: number;
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  shortText?: string;
  /**
   *
   * @type {string}
   * @memberof TopProduct
   */
  image?: string;
}
/**
 *
 * @export
 * @interface TranslatableValues
 */
export interface TranslatableValues {
  /**
   *
   * @type {string}
   * @memberof TranslatableValues
   */
  original: string;
  /**
   *
   * @type {object}
   * @memberof TranslatableValues
   */
  translations?: object;
}
/**
 *
 * @export
 * @interface UpdateBusinessEntity
 */
export interface UpdateBusinessEntity {
  /**
   *
   * @type {number}
   * @memberof UpdateBusinessEntity
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UpdateBusinessEntity
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateBusinessEntity
   */
  hasMaterialMaster: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateBusinessEntity
   */
  plant?: string;
}
/**
 *
 * @export
 * @interface UpdateMatchingTask
 */
export interface UpdateMatchingTask {
  /**
   *
   * @type {string}
   * @memberof UpdateMatchingTask
   */
  fileId: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  telephone?: string;
  /**
   *
   * @type {BusinessEntity}
   * @memberof User
   */
  businessEntity?: BusinessEntity;
  /**
   *
   * @type {string}
   * @memberof User
   */
  manufacturerId?: string;
  /**
   *
   * @type {UserMeta}
   * @memberof User
   */
  meta?: UserMeta;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  deleted?: boolean;
}
/**
 *
 * @export
 * @interface UserForm
 */
export interface UserForm {
  /**
   *
   * @type {object}
   * @memberof UserForm
   */
  schema: object;
  /**
   *
   * @type {UserFormLayout}
   * @memberof UserForm
   */
  layout: UserFormLayout;
}
/**
 *
 * @export
 * @interface UserFormLayout
 */
export interface UserFormLayout {
  /**
   *
   * @type {FormStage}
   * @memberof UserFormLayout
   */
  profile: FormStage;
}
/**
 *
 * @export
 * @interface UserMeta
 */
export interface UserMeta {
  /**
   *
   * @type {boolean}
   * @memberof UserMeta
   */
  blocked: boolean;
  /**
   *
   * @type {string}
   * @memberof UserMeta
   */
  lastLogin?: string;
  /**
   *
   * @type {number}
   * @memberof UserMeta
   */
  loginCount: number;
  /**
   *
   * @type {Array<MachineOperatorRoles>}
   * @memberof UserMeta
   */
  roles: Array<MachineOperatorRoles>;
}
/**
 *
 * @export
 * @interface Users
 */
export interface Users {
  /**
   *
   * @type {Array<User>}
   * @memberof Users
   */
  data: Array<User>;
}

/**
 * BusinessEntitiesApi - axios parameter creator
 * @export
 */
export const BusinessEntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new Business Entity in the scope of a Client
     * @param {NewBusinessEntity} [newBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(newBusinessEntity?: NewBusinessEntity, options: any = {}): RequestArgs {
      const localVarPath = `/business-entities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewBusinessEntity" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newBusinessEntity !== undefined ? newBusinessEntity : {})
        : newBusinessEntity || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Business Entities
     * @param {number} clientId Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntities(clientId: number, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          "clientId",
          "Required parameter clientId was null or undefined when calling getBusinessEntities.",
        );
      }
      const localVarPath = `/business-entities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a Business Entity
     * @param {number} id Business Entity ID
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(id: number, withUserCount?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getBusinessEntity.");
      }
      const localVarPath = `/business-entities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withUserCount !== undefined) {
        localVarQueryParameter["withUserCount"] = withUserCount;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the demo data created up until now to be deleted
     * @param {number} businessEntityId Business entity ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDemoDataToDelete(businessEntityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getDemoDataToDelete.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/demo-data-to-delete`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the latest material master log and the material master ID, if any
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterInfo(businessEntityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getMaterialMasterInfo.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/material-master/info`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a mapping of old header columns to the new ones to complete the mapping manually
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOldToNewMmColumnsMapping(businessEntityId: number, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getOldToNewMmColumnsMapping.",
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling getOldToNewMmColumnsMapping.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/old-to-new-mm-columns-mapping`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (file !== undefined) {
        localVarQueryParameter["file"] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Init material master update
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initMmUpdate(businessEntityId: number, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling initMmUpdate.",
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError("file", "Required parameter file was null or undefined when calling initMmUpdate.");
      }
      const localVarPath = `/business-entity/{businessEntityId}/init-mm-update`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (file !== undefined) {
        localVarQueryParameter["file"] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a preview of a material master and its settings
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewMaterialMaster(businessEntityId: number, file: string, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling previewMaterialMaster.",
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          "file",
          "Required parameter file was null or undefined when calling previewMaterialMaster.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/material-master/preview`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (file !== undefined) {
        localVarQueryParameter["file"] = file;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Saves the mapping of old header columns to the new ones to update the column indexes used across the system
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumnsUpdate} [materialMasterColumnsUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOldToNewMmColumnsMapping(
      businessEntityId: number,
      materialMasterColumnsUpdate?: MaterialMasterColumnsUpdate,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling saveOldToNewMmColumnsMapping.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/old-to-new-mm-columns-mapping`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterColumnsUpdate" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterColumnsUpdate !== undefined ? materialMasterColumnsUpdate : {})
        : materialMasterColumnsUpdate || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a business entity
     * @param {number} id User ID
     * @param {UpdateBusinessEntity} [updateBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(id: number, updateBusinessEntity?: UpdateBusinessEntity, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateBusinessEntity.");
      }
      const localVarPath = `/business-entities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UpdateBusinessEntity" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateBusinessEntity !== undefined ? updateBusinessEntity : {})
        : updateBusinessEntity || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload the latest version of a material master and link it with a business entity
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterInput} [materialMasterInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(
      businessEntityId: number,
      materialMasterInput?: MaterialMasterInput,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling uploadMaterialMaster.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/material-master`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterInput" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterInput !== undefined ? materialMasterInput : {})
        : materialMasterInput || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessEntitiesApi - functional programming interface
 * @export
 */
export const BusinessEntitiesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new Business Entity in the scope of a Client
     * @param {NewBusinessEntity} [newBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(
      newBusinessEntity?: NewBusinessEntity,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).createBusinessEntity(
        newBusinessEntity,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all Business Entities
     * @param {number} clientId Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntities(
      clientId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getBusinessEntities(
        clientId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a Business Entity
     * @param {number} id Business Entity ID
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(
      id: number,
      withUserCount?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getBusinessEntity(
        id,
        withUserCount,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the demo data created up until now to be deleted
     * @param {number} businessEntityId Business entity ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDemoDataToDelete(
      businessEntityId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoDataToDelete> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getDemoDataToDelete(
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the latest material master log and the material master ID, if any
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterInfo(
      businessEntityId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterInfo> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getMaterialMasterInfo(
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets a mapping of old header columns to the new ones to complete the mapping manually
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOldToNewMmColumnsMapping(
      businessEntityId: number,
      file: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterColumnsUpdatePreview> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).getOldToNewMmColumnsMapping(
        businessEntityId,
        file,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Init material master update
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initMmUpdate(
      businessEntityId: number,
      file: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).initMmUpdate(
        businessEntityId,
        file,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets a preview of a material master and its settings
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewMaterialMaster(
      businessEntityId: number,
      file: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterPreview> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).previewMaterialMaster(
        businessEntityId,
        file,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Saves the mapping of old header columns to the new ones to update the column indexes used across the system
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumnsUpdate} [materialMasterColumnsUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOldToNewMmColumnsMapping(
      businessEntityId: number,
      materialMasterColumnsUpdate?: MaterialMasterColumnsUpdate,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).saveOldToNewMmColumnsMapping(
        businessEntityId,
        materialMasterColumnsUpdate,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a business entity
     * @param {number} id User ID
     * @param {UpdateBusinessEntity} [updateBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(
      id: number,
      updateBusinessEntity?: UpdateBusinessEntity,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).updateBusinessEntity(
        id,
        updateBusinessEntity,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Upload the latest version of a material master and link it with a business entity
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterInput} [materialMasterInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(
      businessEntityId: number,
      materialMasterInput?: MaterialMasterInput,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BusinessEntitiesApiAxiosParamCreator(configuration).uploadMaterialMaster(
        businessEntityId,
        materialMasterInput,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BusinessEntitiesApi - factory interface
 * @export
 */
export const BusinessEntitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Create a new Business Entity in the scope of a Client
     * @param {NewBusinessEntity} [newBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBusinessEntity(newBusinessEntity?: NewBusinessEntity, options?: any) {
      return BusinessEntitiesApiFp(configuration).createBusinessEntity(newBusinessEntity, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all Business Entities
     * @param {number} clientId Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntities(clientId: number, options?: any) {
      return BusinessEntitiesApiFp(configuration).getBusinessEntities(clientId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a Business Entity
     * @param {number} id Business Entity ID
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntity(id: number, withUserCount?: boolean, options?: any) {
      return BusinessEntitiesApiFp(configuration).getBusinessEntity(id, withUserCount, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets the demo data created up until now to be deleted
     * @param {number} businessEntityId Business entity ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDemoDataToDelete(businessEntityId: number, options?: any) {
      return BusinessEntitiesApiFp(configuration).getDemoDataToDelete(businessEntityId, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets the latest material master log and the material master ID, if any
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterInfo(businessEntityId: number, options?: any) {
      return BusinessEntitiesApiFp(configuration).getMaterialMasterInfo(businessEntityId, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets a mapping of old header columns to the new ones to complete the mapping manually
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOldToNewMmColumnsMapping(businessEntityId: number, file: string, options?: any) {
      return BusinessEntitiesApiFp(configuration).getOldToNewMmColumnsMapping(
        businessEntityId,
        file,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Init material master update
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initMmUpdate(businessEntityId: number, file: string, options?: any) {
      return BusinessEntitiesApiFp(configuration).initMmUpdate(businessEntityId, file, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets a preview of a material master and its settings
     * @param {number} businessEntityId Business entity ID
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewMaterialMaster(businessEntityId: number, file: string, options?: any) {
      return BusinessEntitiesApiFp(configuration).previewMaterialMaster(
        businessEntityId,
        file,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Saves the mapping of old header columns to the new ones to update the column indexes used across the system
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumnsUpdate} [materialMasterColumnsUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveOldToNewMmColumnsMapping(
      businessEntityId: number,
      materialMasterColumnsUpdate?: MaterialMasterColumnsUpdate,
      options?: any,
    ) {
      return BusinessEntitiesApiFp(configuration).saveOldToNewMmColumnsMapping(
        businessEntityId,
        materialMasterColumnsUpdate,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update a business entity
     * @param {number} id User ID
     * @param {UpdateBusinessEntity} [updateBusinessEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBusinessEntity(id: number, updateBusinessEntity?: UpdateBusinessEntity, options?: any) {
      return BusinessEntitiesApiFp(configuration).updateBusinessEntity(
        id,
        updateBusinessEntity,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Upload the latest version of a material master and link it with a business entity
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterInput} [materialMasterInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadMaterialMaster(businessEntityId: number, materialMasterInput?: MaterialMasterInput, options?: any) {
      return BusinessEntitiesApiFp(configuration).uploadMaterialMaster(
        businessEntityId,
        materialMasterInput,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * BusinessEntitiesApi - object-oriented interface
 * @export
 * @class BusinessEntitiesApi
 * @extends {BaseAPI}
 */
export class BusinessEntitiesApi extends BaseAPI {
  /**
   *
   * @summary Create a new Business Entity in the scope of a Client
   * @param {NewBusinessEntity} [newBusinessEntity]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public createBusinessEntity(newBusinessEntity?: NewBusinessEntity, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).createBusinessEntity(newBusinessEntity, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all Business Entities
   * @param {number} clientId Client Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getBusinessEntities(clientId: number, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getBusinessEntities(clientId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a Business Entity
   * @param {number} id Business Entity ID
   * @param {boolean} [withUserCount] With user count
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getBusinessEntity(id: number, withUserCount?: boolean, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getBusinessEntity(
      id,
      withUserCount,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets the demo data created up until now to be deleted
   * @param {number} businessEntityId Business entity ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getDemoDataToDelete(businessEntityId: number, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getDemoDataToDelete(businessEntityId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Gets the latest material master log and the material master ID, if any
   * @param {number} businessEntityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getMaterialMasterInfo(businessEntityId: number, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getMaterialMasterInfo(businessEntityId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Gets a mapping of old header columns to the new ones to complete the mapping manually
   * @param {number} businessEntityId Business entity ID
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public getOldToNewMmColumnsMapping(businessEntityId: number, file: string, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).getOldToNewMmColumnsMapping(
      businessEntityId,
      file,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Init material master update
   * @param {number} businessEntityId Business entity ID
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public initMmUpdate(businessEntityId: number, file: string, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).initMmUpdate(
      businessEntityId,
      file,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets a preview of a material master and its settings
   * @param {number} businessEntityId Business entity ID
   * @param {string} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public previewMaterialMaster(businessEntityId: number, file: string, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).previewMaterialMaster(
      businessEntityId,
      file,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Saves the mapping of old header columns to the new ones to update the column indexes used across the system
   * @param {number} businessEntityId Business entity ID
   * @param {MaterialMasterColumnsUpdate} [materialMasterColumnsUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public saveOldToNewMmColumnsMapping(
    businessEntityId: number,
    materialMasterColumnsUpdate?: MaterialMasterColumnsUpdate,
    options?: any,
  ) {
    return BusinessEntitiesApiFp(this.configuration).saveOldToNewMmColumnsMapping(
      businessEntityId,
      materialMasterColumnsUpdate,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a business entity
   * @param {number} id User ID
   * @param {UpdateBusinessEntity} [updateBusinessEntity]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public updateBusinessEntity(id: number, updateBusinessEntity?: UpdateBusinessEntity, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).updateBusinessEntity(
      id,
      updateBusinessEntity,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Upload the latest version of a material master and link it with a business entity
   * @param {number} businessEntityId Business entity ID
   * @param {MaterialMasterInput} [materialMasterInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessEntitiesApi
   */
  public uploadMaterialMaster(businessEntityId: number, materialMasterInput?: MaterialMasterInput, options?: any) {
    return BusinessEntitiesApiFp(this.configuration).uploadMaterialMaster(
      businessEntityId,
      materialMasterInput,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new client
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClient(newClient: NewClient, options: any = {}): RequestArgs {
      // verify required parameter 'newClient' is not null or undefined
      if (newClient === null || newClient === undefined) {
        throw new RequiredError(
          "newClient",
          "Required parameter newClient was null or undefined when calling createClient.",
        );
      }
      const localVarPath = `/clients`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewClient" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newClient !== undefined ? newClient : {})
        : newClient || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a client
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClient(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteClient.");
      }
      const localVarPath = `/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets all business entitiy trees from a specific client
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityTree(id: number, withUserCount?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getBusinessEntityTree.",
        );
      }
      const localVarPath = `/clients/{id}/entity-tree`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withUserCount !== undefined) {
        localVarQueryParameter["withUserCount"] = withUserCount;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client detail
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getClient.");
      }
      const localVarPath = `/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(options: any = {}): RequestArgs {
      const localVarPath = `/clients`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client
     * @param {number} id Client\&#39;s id
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient(id: number, newClient: NewClient, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateClient.");
      }
      // verify required parameter 'newClient' is not null or undefined
      if (newClient === null || newClient === undefined) {
        throw new RequiredError(
          "newClient",
          "Required parameter newClient was null or undefined when calling updateClient.",
        );
      }
      const localVarPath = `/clients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewClient" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newClient !== undefined ? newClient : {})
        : newClient || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a new client
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClient(
      newClient: NewClient,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).createClient(newClient, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a client
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClient(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).deleteClient(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets all business entitiy trees from a specific client
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityTree(
      id: number,
      withUserCount?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntity> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).getBusinessEntityTree(
        id,
        withUserCount,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get client detail
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).getClient(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clients> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).getClients(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a client
     * @param {number} id Client\&#39;s id
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient(
      id: number,
      newClient: NewClient,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).updateClient(id, newClient, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a new client
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClient(newClient: NewClient, options?: any) {
      return ClientsApiFp(configuration).createClient(newClient, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a client
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClient(id: number, options?: any) {
      return ClientsApiFp(configuration).deleteClient(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets all business entitiy trees from a specific client
     * @param {number} id
     * @param {boolean} [withUserCount] With user count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntityTree(id: number, withUserCount?: boolean, options?: any) {
      return ClientsApiFp(configuration).getBusinessEntityTree(id, withUserCount, options)(axios, basePath);
    },
    /**
     *
     * @summary Get client detail
     * @param {number} id Client\&#39;s id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient(id: number, options?: any) {
      return ClientsApiFp(configuration).getClient(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all clients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(options?: any) {
      return ClientsApiFp(configuration).getClients(options)(axios, basePath);
    },
    /**
     *
     * @summary Update a client
     * @param {number} id Client\&#39;s id
     * @param {NewClient} newClient Client information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient(id: number, newClient: NewClient, options?: any) {
      return ClientsApiFp(configuration).updateClient(id, newClient, options)(axios, basePath);
    },
  };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
  /**
   *
   * @summary Create a new client
   * @param {NewClient} newClient Client information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public createClient(newClient: NewClient, options?: any) {
    return ClientsApiFp(this.configuration).createClient(newClient, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a client
   * @param {number} id Client\&#39;s id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public deleteClient(id: number, options?: any) {
    return ClientsApiFp(this.configuration).deleteClient(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets all business entitiy trees from a specific client
   * @param {number} id
   * @param {boolean} [withUserCount] With user count
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public getBusinessEntityTree(id: number, withUserCount?: boolean, options?: any) {
    return ClientsApiFp(this.configuration).getBusinessEntityTree(
      id,
      withUserCount,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get client detail
   * @param {number} id Client\&#39;s id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public getClient(id: number, options?: any) {
    return ClientsApiFp(this.configuration).getClient(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all clients
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public getClients(options?: any) {
    return ClientsApiFp(this.configuration).getClients(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a client
   * @param {number} id Client\&#39;s id
   * @param {NewClient} newClient Client information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public updateClient(id: number, newClient: NewClient, options?: any) {
    return ClientsApiFp(this.configuration).updateClient(id, newClient, options)(this.axios, this.basePath);
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary delete dangling resources (change requests, material extensions)
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDanglingResources(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling deleteDanglingResources.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/dangling`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get dangling resource information
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDanglingResources(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getDanglingResources.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/dangling`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary delete dangling resources (change requests, material extensions)
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDanglingResources(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDanglingResources(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary get dangling resource information
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDanglingResources(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DanglingResources> {
      const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDanglingResources(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary delete dangling resources (change requests, material extensions)
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDanglingResources(materialMasterId: number, options?: any) {
      return DefaultApiFp(configuration).deleteDanglingResources(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary get dangling resource information
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDanglingResources(materialMasterId: number, options?: any) {
      return DefaultApiFp(configuration).getDanglingResources(materialMasterId, options)(axios, basePath);
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary delete dangling resources (change requests, material extensions)
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteDanglingResources(materialMasterId: number, options?: any) {
    return DefaultApiFp(this.configuration).deleteDanglingResources(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary get dangling resource information
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getDanglingResources(materialMasterId: number, options?: any) {
    return DefaultApiFp(this.configuration).getDanglingResources(materialMasterId, options)(this.axios, this.basePath);
  }
}

/**
 * DumpApi - axios parameter creator
 * @export
 */
export const DumpApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options: any = {}): RequestArgs {
      const localVarPath = `/dump/coroutines`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options: any = {}): RequestArgs {
      const localVarPath = `/dump/threads`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DumpApi - functional programming interface
 * @export
 */
export const DumpApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DumpApiAxiosParamCreator(configuration).dumpCoroutines(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DumpApiAxiosParamCreator(configuration).dumpThreads(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DumpApi - factory interface
 * @export
 */
export const DumpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Print all active (suspended or running) coroutines to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpCoroutines(options?: any) {
      return DumpApiFp(configuration).dumpCoroutines(options)(axios, basePath);
    },
    /**
     *
     * @summary Dump threads to the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dumpThreads(options?: any) {
      return DumpApiFp(configuration).dumpThreads(options)(axios, basePath);
    },
  };
};

/**
 * DumpApi - object-oriented interface
 * @export
 * @class DumpApi
 * @extends {BaseAPI}
 */
export class DumpApi extends BaseAPI {
  /**
   *
   * @summary Print all active (suspended or running) coroutines to the log
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DumpApi
   */
  public dumpCoroutines(options?: any) {
    return DumpApiFp(this.configuration).dumpCoroutines(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Dump threads to the log
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DumpApi
   */
  public dumpThreads(options?: any) {
    return DumpApiFp(this.configuration).dumpThreads(options)(this.axios, this.basePath);
  }
}

/**
 * EsRegenerationApi - axios parameter creator
 * @export
 */
export const EsRegenerationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Regenerate the ElasticSearch indices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEs(options: any = {}): RequestArgs {
      const localVarPath = `/regenerate-es`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Regenerate manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEsManufacturers(options: any = {}): RequestArgs {
      const localVarPath = `/regenerate-es-manufacturers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EsRegenerationApi - functional programming interface
 * @export
 */
export const EsRegenerationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Regenerate the ElasticSearch indices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEs(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EsRegenerationApiAxiosParamCreator(configuration).regenerateEs(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Regenerate manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEsManufacturers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EsRegenerationApiAxiosParamCreator(configuration).regenerateEsManufacturers(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EsRegenerationApi - factory interface
 * @export
 */
export const EsRegenerationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Regenerate the ElasticSearch indices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEs(options?: any) {
      return EsRegenerationApiFp(configuration).regenerateEs(options)(axios, basePath);
    },
    /**
     *
     * @summary Regenerate manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    regenerateEsManufacturers(options?: any) {
      return EsRegenerationApiFp(configuration).regenerateEsManufacturers(options)(axios, basePath);
    },
  };
};

/**
 * EsRegenerationApi - object-oriented interface
 * @export
 * @class EsRegenerationApi
 * @extends {BaseAPI}
 */
export class EsRegenerationApi extends BaseAPI {
  /**
   *
   * @summary Regenerate the ElasticSearch indices
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EsRegenerationApi
   */
  public regenerateEs(options?: any) {
    return EsRegenerationApiFp(this.configuration).regenerateEs(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Regenerate manufacturers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EsRegenerationApi
   */
  public regenerateEsManufacturers(options?: any) {
    return EsRegenerationApiFp(this.configuration).regenerateEsManufacturers(options)(this.axios, this.basePath);
  }
}

/**
 * FeatureToggleApi - axios parameter creator
 * @export
 */
export const FeatureToggleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get feature Toggle value
     * @param {string} toggle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findToggleValue(toggle: string, options: any = {}): RequestArgs {
      // verify required parameter 'toggle' is not null or undefined
      if (toggle === null || toggle === undefined) {
        throw new RequiredError(
          "toggle",
          "Required parameter toggle was null or undefined when calling findToggleValue.",
        );
      }
      const localVarPath = `/feature-toggles/{toggle}`.replace(`{${"toggle"}}`, encodeURIComponent(String(toggle)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all feature Toggles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToggles(options: any = {}): RequestArgs {
      const localVarPath = `/feature-toggles`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update toggle
     * @param {string} toggle
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertToggle(toggle: string, body: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'toggle' is not null or undefined
      if (toggle === null || toggle === undefined) {
        throw new RequiredError("toggle", "Required parameter toggle was null or undefined when calling upsertToggle.");
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError("body", "Required parameter body was null or undefined when calling upsertToggle.");
      }
      const localVarPath = `/feature-toggles/{toggle}`.replace(`{${"toggle"}}`, encodeURIComponent(String(toggle)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "text/plain";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"boolean" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeatureToggleApi - functional programming interface
 * @export
 */
export const FeatureToggleApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get feature Toggle value
     * @param {string} toggle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findToggleValue(
      toggle: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
      const localVarAxiosArgs = FeatureToggleApiAxiosParamCreator(configuration).findToggleValue(toggle, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all feature Toggles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToggles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = FeatureToggleApiAxiosParamCreator(configuration).getToggles(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update toggle
     * @param {string} toggle
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertToggle(
      toggle: string,
      body: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = FeatureToggleApiAxiosParamCreator(configuration).upsertToggle(toggle, body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FeatureToggleApi - factory interface
 * @export
 */
export const FeatureToggleApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Get feature Toggle value
     * @param {string} toggle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findToggleValue(toggle: string, options?: any) {
      return FeatureToggleApiFp(configuration).findToggleValue(toggle, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all feature Toggles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToggles(options?: any) {
      return FeatureToggleApiFp(configuration).getToggles(options)(axios, basePath);
    },
    /**
     *
     * @summary Update toggle
     * @param {string} toggle
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertToggle(toggle: string, body: boolean, options?: any) {
      return FeatureToggleApiFp(configuration).upsertToggle(toggle, body, options)(axios, basePath);
    },
  };
};

/**
 * FeatureToggleApi - object-oriented interface
 * @export
 * @class FeatureToggleApi
 * @extends {BaseAPI}
 */
export class FeatureToggleApi extends BaseAPI {
  /**
   *
   * @summary Get feature Toggle value
   * @param {string} toggle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureToggleApi
   */
  public findToggleValue(toggle: string, options?: any) {
    return FeatureToggleApiFp(this.configuration).findToggleValue(toggle, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all feature Toggles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureToggleApi
   */
  public getToggles(options?: any) {
    return FeatureToggleApiFp(this.configuration).getToggles(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update toggle
   * @param {string} toggle
   * @param {boolean} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureToggleApi
   */
  public upsertToggle(toggle: string, body: boolean, options?: any) {
    return FeatureToggleApiFp(this.configuration).upsertToggle(toggle, body, options)(this.axios, this.basePath);
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get a signed URL and file ID for direct upload to S3
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3UploadDestination(fileName: string, options: any = {}): RequestArgs {
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          "fileName",
          "Required parameter fileName was null or undefined when calling getS3UploadDestination.",
        );
      }
      const localVarPath = `/files/upload-url/{fileName}`.replace(
        `{${"fileName"}}`,
        encodeURIComponent(String(fileName)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a file record in the database and get a signed URL and file ID for direct upload to S3
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepareCatalogFileUpload(body: string, options: any = {}): RequestArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling prepareCatalogFileUpload.",
        );
      }
      const localVarPath = `/files/upload-url`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "text/plain";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"string" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options: any = {}): RequestArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError("file", "Required parameter file was null or undefined when calling uploadFile.");
      }
      const localVarPath = `/files`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get a signed URL and file ID for direct upload to S3
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3UploadDestination(
      fileName: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadDestination> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).getS3UploadDestination(fileName, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Create a file record in the database and get a signed URL and file ID for direct upload to S3
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepareCatalogFileUpload(
      body: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UploadDestination> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).prepareCatalogFileUpload(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileReference> {
      const localVarAxiosArgs = FilesApiAxiosParamCreator(configuration).uploadFile(file, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Get a signed URL and file ID for direct upload to S3
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getS3UploadDestination(fileName: string, options?: any) {
      return FilesApiFp(configuration).getS3UploadDestination(fileName, options)(axios, basePath);
    },
    /**
     * Create a file record in the database and get a signed URL and file ID for direct upload to S3
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepareCatalogFileUpload(body: string, options?: any) {
      return FilesApiFp(configuration).prepareCatalogFileUpload(body, options)(axios, basePath);
    },
    /**
     * Upload a File
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any) {
      return FilesApiFp(configuration).uploadFile(file, options)(axios, basePath);
    },
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   * Get a signed URL and file ID for direct upload to S3
   * @param {string} fileName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getS3UploadDestination(fileName: string, options?: any) {
    return FilesApiFp(this.configuration).getS3UploadDestination(fileName, options)(this.axios, this.basePath);
  }

  /**
   * Create a file record in the database and get a signed URL and file ID for direct upload to S3
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public prepareCatalogFileUpload(body: string, options?: any) {
    return FilesApiFp(this.configuration).prepareCatalogFileUpload(body, options)(this.axios, this.basePath);
  }

  /**
   * Upload a File
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public uploadFile(file: any, options?: any) {
    return FilesApiFp(this.configuration).uploadFile(file, options)(this.axios, this.basePath);
  }
}

/**
 * InquiriesApi - axios parameter creator
 * @export
 */
export const InquiriesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelInquiry(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling cancelInquiry.");
      }
      const localVarPath = `/inquiries/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new inquiry for a manufacturer
     * @param {NewInquiry} [newInquiry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiry(newInquiry?: NewInquiry, options: any = {}): RequestArgs {
      const localVarPath = `/inquiries`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewInquiry" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newInquiry !== undefined ? newInquiry : {})
        : newInquiry || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets all inquiries
     * @param {number} [client]
     * @param {string} [manufacturer]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(
      client?: number,
      manufacturer?: string,
      user?: string,
      status?: string,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/inquiries`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (client !== undefined) {
        localVarQueryParameter["client"] = client;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (user !== undefined) {
        localVarQueryParameter["user"] = user;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getInquiry.");
      }
      const localVarPath = `/inquiries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InquiriesApi - functional programming interface
 * @export
 */
export const InquiriesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelInquiry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).cancelInquiry(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new inquiry for a manufacturer
     * @param {NewInquiry} [newInquiry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiry(
      newInquiry?: NewInquiry,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).createInquiry(newInquiry, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets all inquiries
     * @param {number} [client]
     * @param {string} [manufacturer]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(
      client?: number,
      manufacturer?: string,
      user?: string,
      status?: string,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Inquiry>> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).getAllInquiries(
        client,
        manufacturer,
        user,
        status,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryDetails> {
      const localVarAxiosArgs = InquiriesApiAxiosParamCreator(configuration).getInquiry(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InquiriesApi - factory interface
 * @export
 */
export const InquiriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Cancel an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelInquiry(id: number, options?: any) {
      return InquiriesApiFp(configuration).cancelInquiry(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a new inquiry for a manufacturer
     * @param {NewInquiry} [newInquiry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInquiry(newInquiry?: NewInquiry, options?: any) {
      return InquiriesApiFp(configuration).createInquiry(newInquiry, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets all inquiries
     * @param {number} [client]
     * @param {string} [manufacturer]
     * @param {string} [user]
     * @param {string} [status]
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInquiries(
      client?: number,
      manufacturer?: string,
      user?: string,
      status?: string,
      page?: number,
      limit?: number,
      options?: any,
    ) {
      return InquiriesApiFp(configuration).getAllInquiries(
        client,
        manufacturer,
        user,
        status,
        page,
        limit,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Gets an inquiry
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInquiry(id: number, options?: any) {
      return InquiriesApiFp(configuration).getInquiry(id, options)(axios, basePath);
    },
  };
};

/**
 * InquiriesApi - object-oriented interface
 * @export
 * @class InquiriesApi
 * @extends {BaseAPI}
 */
export class InquiriesApi extends BaseAPI {
  /**
   *
   * @summary Cancel an inquiry
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public cancelInquiry(id: number, options?: any) {
    return InquiriesApiFp(this.configuration).cancelInquiry(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new inquiry for a manufacturer
   * @param {NewInquiry} [newInquiry]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public createInquiry(newInquiry?: NewInquiry, options?: any) {
    return InquiriesApiFp(this.configuration).createInquiry(newInquiry, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets all inquiries
   * @param {number} [client]
   * @param {string} [manufacturer]
   * @param {string} [user]
   * @param {string} [status]
   * @param {number} [page]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public getAllInquiries(
    client?: number,
    manufacturer?: string,
    user?: string,
    status?: string,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return InquiriesApiFp(this.configuration).getAllInquiries(
      client,
      manufacturer,
      user,
      status,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets an inquiry
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InquiriesApi
   */
  public getInquiry(id: number, options?: any) {
    return InquiriesApiFp(this.configuration).getInquiry(id, options)(this.axios, this.basePath);
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Accept a Job
     * @param {number} id Job Id
     * @param {AcceptJob} [acceptJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptJob(id: number, acceptJob?: AcceptJob, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling acceptJob.");
      }
      const localVarPath = `/jobs/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"AcceptJob" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(acceptJob !== undefined ? acceptJob : {})
        : acceptJob || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Finish a Job
     * @param {number} id Job ID
     * @param {FinishJob} [finishJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishJob(id: number, finishJob?: FinishJob, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling finishJob.");
      }
      const localVarPath = `/jobs/{id}/finish`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FinishJob" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(finishJob !== undefined ? finishJob : {})
        : finishJob || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all BusinessEntities for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithJobs(clientId?: number, options: any = {}): RequestArgs {
      const localVarPath = `/jobs/business-entity`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients that have a job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithJobs(options: any = {}): RequestArgs {
      const localVarPath = `/jobs/clients`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all jobs
     * @param {number} [clientId] Client Id
     * @param {string} [status] Job Status
     * @param {string} [userId] User Id
     * @param {number} [businessEntity] businessEntity Id
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobs(
      clientId?: number,
      status?: string,
      userId?: string,
      businessEntity?: number,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/jobs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (businessEntity !== undefined) {
        localVarQueryParameter["businessEntity"] = businessEntity;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Users for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersWithJobs(clientId?: number, options: any = {}): RequestArgs {
      const localVarPath = `/jobs/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Accept a Job
     * @param {number} id Job Id
     * @param {AcceptJob} [acceptJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptJob(
      id: number,
      acceptJob?: AcceptJob,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).acceptJob(id, acceptJob, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Finish a Job
     * @param {number} id Job ID
     * @param {FinishJob} [finishJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishJob(
      id: number,
      finishJob?: FinishJob,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).finishJob(id, finishJob, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all BusinessEntities for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithJobs(
      clientId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getBusinessEntitiesWithJobs(clientId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all clients that have a job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithJobs(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clients> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getClientsWithJobs(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all jobs
     * @param {number} [clientId] Client Id
     * @param {string} [status] Job Status
     * @param {string} [userId] User Id
     * @param {number} [businessEntity] businessEntity Id
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobs(
      clientId?: number,
      status?: string,
      userId?: string,
      businessEntity?: number,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jobs> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getJobs(
        clientId,
        status,
        userId,
        businessEntity,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all Users for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersWithJobs(
      clientId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = JobsApiAxiosParamCreator(configuration).getUsersWithJobs(clientId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Accept a Job
     * @param {number} id Job Id
     * @param {AcceptJob} [acceptJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptJob(id: number, acceptJob?: AcceptJob, options?: any) {
      return JobsApiFp(configuration).acceptJob(id, acceptJob, options)(axios, basePath);
    },
    /**
     *
     * @summary Finish a Job
     * @param {number} id Job ID
     * @param {FinishJob} [finishJob]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishJob(id: number, finishJob?: FinishJob, options?: any) {
      return JobsApiFp(configuration).finishJob(id, finishJob, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all BusinessEntities for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithJobs(clientId?: number, options?: any) {
      return JobsApiFp(configuration).getBusinessEntitiesWithJobs(clientId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all clients that have a job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithJobs(options?: any) {
      return JobsApiFp(configuration).getClientsWithJobs(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all jobs
     * @param {number} [clientId] Client Id
     * @param {string} [status] Job Status
     * @param {string} [userId] User Id
     * @param {number} [businessEntity] businessEntity Id
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobs(
      clientId?: number,
      status?: string,
      userId?: string,
      businessEntity?: number,
      page?: number,
      limit?: number,
      options?: any,
    ) {
      return JobsApiFp(configuration).getJobs(
        clientId,
        status,
        userId,
        businessEntity,
        page,
        limit,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all Users for the client that have a job
     * @param {number} [clientId] Client Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersWithJobs(clientId?: number, options?: any) {
      return JobsApiFp(configuration).getUsersWithJobs(clientId, options)(axios, basePath);
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary Accept a Job
   * @param {number} id Job Id
   * @param {AcceptJob} [acceptJob]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public acceptJob(id: number, acceptJob?: AcceptJob, options?: any) {
    return JobsApiFp(this.configuration).acceptJob(id, acceptJob, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Finish a Job
   * @param {number} id Job ID
   * @param {FinishJob} [finishJob]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public finishJob(id: number, finishJob?: FinishJob, options?: any) {
    return JobsApiFp(this.configuration).finishJob(id, finishJob, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all BusinessEntities for the client that have a job
   * @param {number} [clientId] Client Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getBusinessEntitiesWithJobs(clientId?: number, options?: any) {
    return JobsApiFp(this.configuration).getBusinessEntitiesWithJobs(clientId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all clients that have a job
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getClientsWithJobs(options?: any) {
    return JobsApiFp(this.configuration).getClientsWithJobs(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all jobs
   * @param {number} [clientId] Client Id
   * @param {string} [status] Job Status
   * @param {string} [userId] User Id
   * @param {number} [businessEntity] businessEntity Id
   * @param {number} [page] Page
   * @param {number} [limit] Limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobs(
    clientId?: number,
    status?: string,
    userId?: string,
    businessEntity?: number,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return JobsApiFp(this.configuration).getJobs(
      clientId,
      status,
      userId,
      businessEntity,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all Users for the client that have a job
   * @param {number} [clientId] Client Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getUsersWithJobs(clientId?: number, options?: any) {
    return JobsApiFp(this.configuration).getUsersWithJobs(clientId, options)(this.axios, this.basePath);
  }
}

/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get language information for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLanguageInformations(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getLanguageInformations.",
        );
      }
      const localVarPath = `/language-informations/{materialMasterId}`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all system languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemLanguages(options: any = {}): RequestArgs {
      const localVarPath = `/system-languages`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update language information
     * @param {LanguageInformation} [languageInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLanguageInformations(languageInformation?: LanguageInformation, options: any = {}): RequestArgs {
      const localVarPath = `/language-informations`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"LanguageInformation" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(languageInformation !== undefined ? languageInformation : {})
        : languageInformation || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get language information for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLanguageInformations(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageInformation> {
      const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).getLanguageInformations(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all system languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemLanguages(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).getSystemLanguages(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update language information
     * @param {LanguageInformation} [languageInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLanguageInformations(
      languageInformation?: LanguageInformation,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).updateLanguageInformations(
        languageInformation,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get language information for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLanguageInformations(materialMasterId: number, options?: any) {
      return LanguageApiFp(configuration).getLanguageInformations(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all system languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemLanguages(options?: any) {
      return LanguageApiFp(configuration).getSystemLanguages(options)(axios, basePath);
    },
    /**
     *
     * @summary Update language information
     * @param {LanguageInformation} [languageInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLanguageInformations(languageInformation?: LanguageInformation, options?: any) {
      return LanguageApiFp(configuration).updateLanguageInformations(languageInformation, options)(axios, basePath);
    },
  };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
  /**
   *
   * @summary Get language information for the material master
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LanguageApi
   */
  public getLanguageInformations(materialMasterId: number, options?: any) {
    return LanguageApiFp(this.configuration).getLanguageInformations(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all system languages
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LanguageApi
   */
  public getSystemLanguages(options?: any) {
    return LanguageApiFp(this.configuration).getSystemLanguages(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update language information
   * @param {LanguageInformation} [languageInformation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LanguageApi
   */
  public updateLanguageInformations(languageInformation?: LanguageInformation, options?: any) {
    return LanguageApiFp(this.configuration).updateLanguageInformations(languageInformation, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if manufacturer is deletable
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDeletability(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling checkDeletability.");
      }
      const localVarPath = `/manufacturers/{id}/deletability`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary createManufacturer
     * @param {NewManufacturer} [newManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturer(newManufacturer?: NewManufacturer, options: any = {}): RequestArgs {
      const localVarPath = `/manufacturers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewManufacturer" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newManufacturer !== undefined ? newManufacturer : {})
        : newManufacturer || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes the manufacturer
     * @param {string} id
     * @param {string} [manufacturerNameToMoveDetailsTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManufacturer(id: string, manufacturerNameToMoveDetailsTo?: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteManufacturer.");
      }
      const localVarPath = `/manufacturers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (manufacturerNameToMoveDetailsTo !== undefined) {
        localVarFormParams.append("manufacturerNameToMoveDetailsTo", manufacturerNameToMoveDetailsTo as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download a manufacturer\'s stats
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadManufacturerStats(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling downloadManufacturerStats.",
        );
      }
      const localVarPath = `/manufacturers/{id}/download-stats`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lists available Manufacturers
     * @param {boolean} [hasUsers]
     * @param {boolean} [isSupplier]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturers(
      hasUsers?: boolean,
      isSupplier?: boolean,
      limit?: number,
      page?: number,
      query?: string,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/manufacturers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (hasUsers !== undefined) {
        localVarQueryParameter["hasUsers"] = hasUsers;
      }

      if (isSupplier !== undefined) {
        localVarQueryParameter["isSupplier"] = isSupplier;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the short version of all manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturersShort(options: any = {}): RequestArgs {
      const localVarPath = `/manufacturers/short/all`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get catalogs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getCatalogs.");
      }
      const localVarPath = `/manufacturers/{id}/catalogs`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get information about the  Manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getManufacturer.");
      }
      const localVarPath = `/manufacturers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets manufacturer statistics
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerStatistics(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getManufacturerStatistics.",
        );
      }
      const localVarPath = `/manufacturers/{id}/statistics`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Query a manufacturer among the manufacturer names and synonyms
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lookupManufacturer(name: string, options: any = {}): RequestArgs {
      // verify required parameter 'name' is not null or undefined
      if (name === null || name === undefined) {
        throw new RequiredError(
          "name",
          "Required parameter name was null or undefined when calling lookupManufacturer.",
        );
      }
      const localVarPath = `/manufacturers/lookup/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(name)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Persist product statistics per manufacturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    persistManufacturerStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/manufacturers/statistics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a manufacturer\'s identification rules
     * @param {string} id
     * @param {ManufacturerIdentificationRules} [manufacturerIdentificationRules]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIdentificationRules(
      id: string,
      manufacturerIdentificationRules?: ManufacturerIdentificationRules,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateIdentificationRules.",
        );
      }
      const localVarPath = `/manufacturers/{id}/identification`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ManufacturerIdentificationRules" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(manufacturerIdentificationRules !== undefined ? manufacturerIdentificationRules : {})
        : manufacturerIdentificationRules || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a manufacturer
     * @param {string} id
     * @param {ManufacturerDetails} [manufacturerDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturer(id: string, manufacturerDetails?: ManufacturerDetails, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateManufacturer.");
      }
      const localVarPath = `/manufacturers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ManufacturerDetails" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(manufacturerDetails !== undefined ? manufacturerDetails : {})
        : manufacturerDetails || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if manufacturer is deletable
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDeletability(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).checkDeletability(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary createManufacturer
     * @param {NewManufacturer} [newManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturer(
      newManufacturer?: NewManufacturer,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDetails> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).createManufacturer(
        newManufacturer,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes the manufacturer
     * @param {string} id
     * @param {string} [manufacturerNameToMoveDetailsTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManufacturer(
      id: string,
      manufacturerNameToMoveDetailsTo?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).deleteManufacturer(
        id,
        manufacturerNameToMoveDetailsTo,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Download a manufacturer\'s stats
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadManufacturerStats(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).downloadManufacturerStats(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lists available Manufacturers
     * @param {boolean} [hasUsers]
     * @param {boolean} [isSupplier]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturers(
      hasUsers?: boolean,
      isSupplier?: boolean,
      limit?: number,
      page?: number,
      query?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerList> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getAllManufacturers(
        hasUsers,
        isSupplier,
        limit,
        page,
        query,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the short version of all manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturersShort(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerShort>> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getAllManufacturersShort(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get catalogs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Catalog>> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getCatalogs(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get information about the  Manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDetails> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getManufacturer(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets manufacturer statistics
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerStatistics(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerStatistics> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).getManufacturerStatistics(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Query a manufacturer among the manufacturer names and synonyms
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lookupManufacturer(
      name: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDetails> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).lookupManufacturer(name, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Persist product statistics per manufacturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    persistManufacturerStatistics(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).persistManufacturerStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a manufacturer\'s identification rules
     * @param {string} id
     * @param {ManufacturerIdentificationRules} [manufacturerIdentificationRules]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIdentificationRules(
      id: string,
      manufacturerIdentificationRules?: ManufacturerIdentificationRules,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).updateIdentificationRules(
        id,
        manufacturerIdentificationRules,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a manufacturer
     * @param {string} id
     * @param {ManufacturerDetails} [manufacturerDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturer(
      id: string,
      manufacturerDetails?: ManufacturerDetails,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ManufacturersApiAxiosParamCreator(configuration).updateManufacturer(
        id,
        manufacturerDetails,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Check if manufacturer is deletable
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkDeletability(id: string, options?: any) {
      return ManufacturersApiFp(configuration).checkDeletability(id, options)(axios, basePath);
    },
    /**
     *
     * @summary createManufacturer
     * @param {NewManufacturer} [newManufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturer(newManufacturer?: NewManufacturer, options?: any) {
      return ManufacturersApiFp(configuration).createManufacturer(newManufacturer, options)(axios, basePath);
    },
    /**
     *
     * @summary Deletes the manufacturer
     * @param {string} id
     * @param {string} [manufacturerNameToMoveDetailsTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManufacturer(id: string, manufacturerNameToMoveDetailsTo?: string, options?: any) {
      return ManufacturersApiFp(configuration).deleteManufacturer(
        id,
        manufacturerNameToMoveDetailsTo,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Download a manufacturer\'s stats
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadManufacturerStats(id: string, options?: any) {
      return ManufacturersApiFp(configuration).downloadManufacturerStats(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Lists available Manufacturers
     * @param {boolean} [hasUsers]
     * @param {boolean} [isSupplier]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturers(
      hasUsers?: boolean,
      isSupplier?: boolean,
      limit?: number,
      page?: number,
      query?: string,
      options?: any,
    ) {
      return ManufacturersApiFp(configuration).getAllManufacturers(
        hasUsers,
        isSupplier,
        limit,
        page,
        query,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get the short version of all manufacturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManufacturersShort(options?: any) {
      return ManufacturersApiFp(configuration).getAllManufacturersShort(options)(axios, basePath);
    },
    /**
     *
     * @summary Get catalogs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(id: string, options?: any) {
      return ManufacturersApiFp(configuration).getCatalogs(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get information about the  Manufacturer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturer(id: string, options?: any) {
      return ManufacturersApiFp(configuration).getManufacturer(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets manufacturer statistics
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerStatistics(id: string, options?: any) {
      return ManufacturersApiFp(configuration).getManufacturerStatistics(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Query a manufacturer among the manufacturer names and synonyms
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lookupManufacturer(name: string, options?: any) {
      return ManufacturersApiFp(configuration).lookupManufacturer(name, options)(axios, basePath);
    },
    /**
     *
     * @summary Persist product statistics per manufacturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    persistManufacturerStatistics(options?: any) {
      return ManufacturersApiFp(configuration).persistManufacturerStatistics(options)(axios, basePath);
    },
    /**
     *
     * @summary Updates a manufacturer\'s identification rules
     * @param {string} id
     * @param {ManufacturerIdentificationRules} [manufacturerIdentificationRules]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateIdentificationRules(
      id: string,
      manufacturerIdentificationRules?: ManufacturerIdentificationRules,
      options?: any,
    ) {
      return ManufacturersApiFp(configuration).updateIdentificationRules(
        id,
        manufacturerIdentificationRules,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Updates a manufacturer
     * @param {string} id
     * @param {ManufacturerDetails} [manufacturerDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturer(id: string, manufacturerDetails?: ManufacturerDetails, options?: any) {
      return ManufacturersApiFp(configuration).updateManufacturer(id, manufacturerDetails, options)(axios, basePath);
    },
  };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
  /**
   *
   * @summary Check if manufacturer is deletable
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public checkDeletability(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).checkDeletability(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary createManufacturer
   * @param {NewManufacturer} [newManufacturer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public createManufacturer(newManufacturer?: NewManufacturer, options?: any) {
    return ManufacturersApiFp(this.configuration).createManufacturer(newManufacturer, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Deletes the manufacturer
   * @param {string} id
   * @param {string} [manufacturerNameToMoveDetailsTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public deleteManufacturer(id: string, manufacturerNameToMoveDetailsTo?: string, options?: any) {
    return ManufacturersApiFp(this.configuration).deleteManufacturer(
      id,
      manufacturerNameToMoveDetailsTo,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Download a manufacturer\'s stats
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public downloadManufacturerStats(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).downloadManufacturerStats(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Lists available Manufacturers
   * @param {boolean} [hasUsers]
   * @param {boolean} [isSupplier]
   * @param {number} [limit]
   * @param {number} [page]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getAllManufacturers(
    hasUsers?: boolean,
    isSupplier?: boolean,
    limit?: number,
    page?: number,
    query?: string,
    options?: any,
  ) {
    return ManufacturersApiFp(this.configuration).getAllManufacturers(
      hasUsers,
      isSupplier,
      limit,
      page,
      query,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the short version of all manufacturers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getAllManufacturersShort(options?: any) {
    return ManufacturersApiFp(this.configuration).getAllManufacturersShort(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get catalogs
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getCatalogs(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).getCatalogs(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get information about the  Manufacturer
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getManufacturer(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).getManufacturer(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets manufacturer statistics
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public getManufacturerStatistics(id: string, options?: any) {
    return ManufacturersApiFp(this.configuration).getManufacturerStatistics(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Query a manufacturer among the manufacturer names and synonyms
   * @param {string} name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public lookupManufacturer(name: string, options?: any) {
    return ManufacturersApiFp(this.configuration).lookupManufacturer(name, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Persist product statistics per manufacturer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public persistManufacturerStatistics(options?: any) {
    return ManufacturersApiFp(this.configuration).persistManufacturerStatistics(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Updates a manufacturer\'s identification rules
   * @param {string} id
   * @param {ManufacturerIdentificationRules} [manufacturerIdentificationRules]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public updateIdentificationRules(
    id: string,
    manufacturerIdentificationRules?: ManufacturerIdentificationRules,
    options?: any,
  ) {
    return ManufacturersApiFp(this.configuration).updateIdentificationRules(
      id,
      manufacturerIdentificationRules,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Updates a manufacturer
   * @param {string} id
   * @param {ManufacturerDetails} [manufacturerDetails]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManufacturersApi
   */
  public updateManufacturer(id: string, manufacturerDetails?: ManufacturerDetails, options?: any) {
    return ManufacturersApiFp(this.configuration).updateManufacturer(
      id,
      manufacturerDetails,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * MatchingApi - axios parameter creator
 * @export
 */
export const MatchingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Convert the QuickCheck list to normal one
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertQuickCheck(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling convertQuickCheck.");
      }
      const localVarPath = `/matching-tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new matching task
     * @param {NewMatchingTask} [newMatchingTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMatchingTask(newMatchingTask?: NewMatchingTask, options: any = {}): RequestArgs {
      const localVarPath = `/matching-tasks`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewMatchingTask" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newMatchingTask !== undefined ? newMatchingTask : {})
        : newMatchingTask || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes all candidates from a list
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {DeleteMatchingCandidates} [deleteMatchingCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllCandidatesFromList(
      id: number,
      isMaterialMasterUpdate: boolean,
      deleteMatchingCandidates?: DeleteMatchingCandidates,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteAllCandidatesFromList.",
        );
      }
      // verify required parameter 'isMaterialMasterUpdate' is not null or undefined
      if (isMaterialMasterUpdate === null || isMaterialMasterUpdate === undefined) {
        throw new RequiredError(
          "isMaterialMasterUpdate",
          "Required parameter isMaterialMasterUpdate was null or undefined when calling deleteAllCandidatesFromList.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching-candidates`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isMaterialMasterUpdate !== undefined) {
        localVarQueryParameter["isMaterialMasterUpdate"] = isMaterialMasterUpdate;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"DeleteMatchingCandidates" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(deleteMatchingCandidates !== undefined ? deleteMatchingCandidates : {})
        : deleteMatchingCandidates || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes candidates from a sparepart with specific material reference
     * @param {number} id
     * @param {string} reference
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCandidatesFromSparepart(id: number, reference: string, type: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteCandidatesFromSparepart.",
        );
      }
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling deleteCandidatesFromSparepart.",
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError(
          "type",
          "Required parameter type was null or undefined when calling deleteCandidatesFromSparepart.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/sparepart/{reference}/candidates`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete all duplicate groups
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDuplicateGroups(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteDuplicateGroups.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete all list-vs-master results
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteListVsMasterGroup(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteListVsMasterGroup.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete the matching task
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingTask(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteMatchingTask.");
      }
      const localVarPath = `/matching-tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply a bulk set of actions to a duplicate group
     * @param {number} id
     * @param {DuplicateActions} [duplicateActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeDuplicateGroupAction(id: number, duplicateActions?: DuplicateActions, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling executeDuplicateGroupAction.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/actions`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"DuplicateActions" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(duplicateActions !== undefined ? duplicateActions : {})
        : duplicateActions || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply a bulk set of actions to a list-vs-mst group
     * @param {number} id
     * @param {number} groupNumber
     * @param {ListVsMaterialMasterActions} [listVsMaterialMasterActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeListVsMasterActions(
      id: number,
      groupNumber: number,
      listVsMaterialMasterActions?: ListVsMaterialMasterActions,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling executeListVsMasterActions.",
        );
      }
      // verify required parameter 'groupNumber' is not null or undefined
      if (groupNumber === null || groupNumber === undefined) {
        throw new RequiredError(
          "groupNumber",
          "Required parameter groupNumber was null or undefined when calling executeListVsMasterActions.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/actions`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupNumber !== undefined) {
        localVarQueryParameter["groupNumber"] = groupNumber;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ListVsMaterialMasterActions" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(listVsMaterialMasterActions !== undefined ? listVsMaterialMasterActions : {})
        : listVsMaterialMasterActions || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateExport(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling generateExport.");
      }
      const localVarPath = `/matching-tasks/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all matching tasks (simplified)
     * @param {boolean} [withSparetech]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSimpleMatchingTasks(withSparetech?: boolean, options: any = {}): RequestArgs {
      const localVarPath = `/matching-tasks/simple/all`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withSparetech !== undefined) {
        localVarQueryParameter["withSparetech"] = withSparetech;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Business Entities with Matching Tasks for a Client
     * @param {number} clientId Client Id
     * @param {boolean} isMaterialMaster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithMatchingTasks(clientId: number, isMaterialMaster: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          "clientId",
          "Required parameter clientId was null or undefined when calling getBusinessEntitiesWithMatchingTasks.",
        );
      }
      // verify required parameter 'isMaterialMaster' is not null or undefined
      if (isMaterialMaster === null || isMaterialMaster === undefined) {
        throw new RequiredError(
          "isMaterialMaster",
          "Required parameter isMaterialMaster was null or undefined when calling getBusinessEntitiesWithMatchingTasks.",
        );
      }
      const localVarPath = `/matching-tasks/info/business-entities`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (isMaterialMaster !== undefined) {
        localVarQueryParameter["isMaterialMaster"] = isMaterialMaster;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients with matching tasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithMatchingTasks(options: any = {}): RequestArgs {
      const localVarPath = `/matching-tasks/info/clients`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all rows that are bound to the same duplicate group ID
     * @param {number} id
     * @param {number} duplicateGroupNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroupPreview(id: number, duplicateGroupNumber: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getDuplicateGroupPreview.",
        );
      }
      // verify required parameter 'duplicateGroupNumber' is not null or undefined
      if (duplicateGroupNumber === null || duplicateGroupNumber === undefined) {
        throw new RequiredError(
          "duplicateGroupNumber",
          "Required parameter duplicateGroupNumber was null or undefined when calling getDuplicateGroupPreview.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/{duplicateGroupNumber}/preview`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"duplicateGroupNumber"}}`, encodeURIComponent(String(duplicateGroupNumber)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the leading row of each duplicate group with unresolved conflicts
     * @param {number} id
     * @param {DuplicatesOverviewQuery} [duplicatesOverviewQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroups(id: number, duplicatesOverviewQuery?: DuplicatesOverviewQuery, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getDuplicateGroups.");
      }
      const localVarPath = `/matching-tasks/{id}/duplicates`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"DuplicatesOverviewQuery" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(duplicatesOverviewQuery !== undefined ? duplicatesOverviewQuery : {})
        : duplicatesOverviewQuery || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the duplicate settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateSettings(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getDuplicateSettings.");
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExport(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getExport.");
      }
      const localVarPath = `/matching-tasks/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the export settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportSettings(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getExportSettings.");
      }
      const localVarPath = `/matching-tasks/{id}/export/settings`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a group with all links between a sparepart and potential materials
     * @param {number} id
     * @param {number} [groupIndex]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMaster(id: number, groupIndex?: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getListVsMaster.");
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupIndex !== undefined) {
        localVarQueryParameter["groupIndex"] = groupIndex;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the settings to match a list against a material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMasterSettings(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getListVsMasterSettings.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all matched duplicates grouped by product ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchedDuplicates(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMatchedDuplicates.");
      }
      const localVarPath = `/matching-tasks/{id}/matched-duplicates`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a sparepart list, its rows and all candidates according to filters
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [candidatesFilter]
     * @param {string} [q]
     * @param {string} [createdSince] Creation date, format should be dd.MM.yyyy
     * @param {string} [ref]
     * @param {string} [matchedManufacturer] ID of a manufacturer from the existing matching candidates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingList(
      id: number,
      isMaterialMasterUpdate: boolean,
      page?: number,
      limit?: number,
      candidatesFilter?: string,
      q?: string,
      createdSince?: string,
      ref?: string,
      matchedManufacturer?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMatchingList.");
      }
      // verify required parameter 'isMaterialMasterUpdate' is not null or undefined
      if (isMaterialMasterUpdate === null || isMaterialMasterUpdate === undefined) {
        throw new RequiredError(
          "isMaterialMasterUpdate",
          "Required parameter isMaterialMasterUpdate was null or undefined when calling getMatchingList.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (candidatesFilter !== undefined) {
        localVarQueryParameter["candidatesFilter"] = candidatesFilter;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      if (createdSince !== undefined) {
        localVarQueryParameter["createdSince"] = createdSince;
      }

      if (isMaterialMasterUpdate !== undefined) {
        localVarQueryParameter["isMaterialMasterUpdate"] = isMaterialMasterUpdate;
      }

      if (ref !== undefined) {
        localVarQueryParameter["ref"] = ref;
      }

      if (matchedManufacturer !== undefined) {
        localVarQueryParameter["matchedManufacturer"] = matchedManufacturer;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all unique manufacturers from the matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingListManufacturers(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMatchingListManufacturers.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matched-manufacturers`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get current progress from all matchings from a specific list
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingProgress(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMatchingProgress.");
      }
      const localVarPath = `/matching-tasks/{id}/matching/progress`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get matching statistics
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingStatistics(id: number, isMaterialMasterUpdate: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMatchingStatistics.",
        );
      }
      // verify required parameter 'isMaterialMasterUpdate' is not null or undefined
      if (isMaterialMasterUpdate === null || isMaterialMasterUpdate === undefined) {
        throw new RequiredError(
          "isMaterialMasterUpdate",
          "Required parameter isMaterialMasterUpdate was null or undefined when calling getMatchingStatistics.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching/statistics`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isMaterialMasterUpdate !== undefined) {
        localVarQueryParameter["isMaterialMasterUpdate"] = isMaterialMasterUpdate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a matching task\'s full details
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskDetails(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMatchingTaskDetails.",
        );
      }
      const localVarPath = `/matching-tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get matching task list details
     * @param {number} id
     * @param {number} [rowLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskList(id: number, rowLimit?: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMatchingTaskList.");
      }
      const localVarPath = `/matching-tasks/{id}/list`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (rowLimit !== undefined) {
        localVarQueryParameter["rowLimit"] = rowLimit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get matching task matching settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskMatchingSettings(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMatchingTaskMatchingSettings.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get matching tasks
     * @param {'SparepartList' | 'MaterialMaster'} listType
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [clientId]
     * @param {number} [businessEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTasks(
      listType: "SparepartList" | "MaterialMaster",
      page?: number,
      pageSize?: number,
      clientId?: number,
      businessEntityId?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'listType' is not null or undefined
      if (listType === null || listType === undefined) {
        throw new RequiredError(
          "listType",
          "Required parameter listType was null or undefined when calling getMatchingTasks.",
        );
      }
      const localVarPath = `/matching-tasks`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (listType !== undefined) {
        localVarQueryParameter["listType"] = listType;
      }

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (businessEntityId !== undefined) {
        localVarQueryParameter["businessEntityId"] = businessEntityId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary See a material master\'s top 5 rows in order to choose the reference column
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterPreview(fileId: string, options: any = {}): RequestArgs {
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling getMaterialMasterPreview.",
        );
      }
      const localVarPath = `/matching-tasks/preview-material-master/{fileId}`.replace(
        `{${"fileId"}}`,
        encodeURIComponent(String(fileId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all materials with the same reference from a material master
     * @param {number} id
     * @param {string} materialReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialsByReference(id: number, materialReference: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMaterialsByReference.",
        );
      }
      // verify required parameter 'materialReference' is not null or undefined
      if (materialReference === null || materialReference === undefined) {
        throw new RequiredError(
          "materialReference",
          "Required parameter materialReference was null or undefined when calling getMaterialsByReference.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/material-master/{materialReference}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"materialReference"}}`, encodeURIComponent(String(materialReference)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a sparepart by its unique reference
     * @param {number} id
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparepart(id: number, reference: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getSparepart.");
      }
      // verify required parameter 'reference' is not null or undefined
      if (reference === null || reference === undefined) {
        throw new RequiredError(
          "reference",
          "Required parameter reference was null or undefined when calling getSparepart.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/spareparts/{reference}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a task update\'s changeset
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateChangeset(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getUpdateChangeset.");
      }
      const localVarPath = `/matching-tasks/{id}/pending-update/changeset`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Release green matches to the operator app
     * @param {number} id
     * @param {ReleaseInformation} [releaseInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseMatches(id: number, releaseInformation?: ReleaseInformation, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling releaseMatches.");
      }
      const localVarPath = `/matching-tasks/{id}/matching/release`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ReleaseInformation" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(releaseInformation !== undefined ? releaseInformation : {})
        : releaseInformation || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startDuplicatesMatching(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling startDuplicatesMatching.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/start`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startListVsMasterMatching(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling startListVsMasterMatching.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/start`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start a matching against the product DB
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startMatching(id: number, isMaterialMasterUpdate: boolean, startDate?: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling startMatching.");
      }
      // verify required parameter 'isMaterialMasterUpdate' is not null or undefined
      if (isMaterialMasterUpdate === null || isMaterialMasterUpdate === undefined) {
        throw new RequiredError(
          "isMaterialMasterUpdate",
          "Required parameter isMaterialMasterUpdate was null or undefined when calling startMatching.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching/start`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter["startDate"] = startDate;
      }

      if (isMaterialMasterUpdate !== undefined) {
        localVarQueryParameter["isMaterialMasterUpdate"] = isMaterialMasterUpdate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopDuplicatesMatching(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling stopDuplicatesMatching.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/stop`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopListVsMasterMatching(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling stopListVsMasterMatching.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/stop`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop a matching against the product DB
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopMatching(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling stopMatching.");
      }
      const localVarPath = `/matching-tasks/{id}/matching/stop`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update duplicate matching settings
     * @param {number} id
     * @param {DuplicateMatchingSettings} [duplicateMatchingSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDuplicateSettings(
      id: number,
      duplicateMatchingSettings?: DuplicateMatchingSettings,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateDuplicateSettings.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/duplicates/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"DuplicateMatchingSettings" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(duplicateMatchingSettings !== undefined ? duplicateMatchingSettings : {})
        : duplicateMatchingSettings || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update export matching settings
     * @param {number} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportSettings(id: number, requestBody?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateExportSettings.");
      }
      const localVarPath = `/matching-tasks/{id}/export/settings`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the settings to match a list against a material master
     * @param {number} id
     * @param {ListVsMaterialMasterSettings} [listVsMaterialMasterSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateListVsMasterSettings(
      id: number,
      listVsMaterialMasterSettings?: ListVsMaterialMasterSettings,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateListVsMasterSettings.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/list-vs-master/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ListVsMaterialMasterSettings" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(listVsMaterialMasterSettings !== undefined ? listVsMaterialMasterSettings : {})
        : listVsMaterialMasterSettings || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update matching task list settings details
     * @param {number} id
     * @param {MatchingTaskListSettings} [matchingTaskListSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMatchingTaskMatchingSettings(
      id: number,
      matchingTaskListSettings?: MatchingTaskListSettings,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateMatchingTaskMatchingSettings.",
        );
      }
      const localVarPath = `/matching-tasks/{id}/matching/settings`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MatchingTaskListSettings" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matchingTaskListSettings !== undefined ? matchingTaskListSettings : {})
        : matchingTaskListSettings || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MatchingApi - functional programming interface
 * @export
 */
export const MatchingApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Convert the QuickCheck list to normal one
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertQuickCheck(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).convertQuickCheck(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create new matching task
     * @param {NewMatchingTask} [newMatchingTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMatchingTask(
      newMatchingTask?: NewMatchingTask,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTask> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).createMatchingTask(
        newMatchingTask,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes all candidates from a list
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {DeleteMatchingCandidates} [deleteMatchingCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllCandidatesFromList(
      id: number,
      isMaterialMasterUpdate: boolean,
      deleteMatchingCandidates?: DeleteMatchingCandidates,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).deleteAllCandidatesFromList(
        id,
        isMaterialMasterUpdate,
        deleteMatchingCandidates,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes candidates from a sparepart with specific material reference
     * @param {number} id
     * @param {string} reference
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCandidatesFromSparepart(
      id: number,
      reference: string,
      type: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).deleteCandidatesFromSparepart(
        id,
        reference,
        type,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete all duplicate groups
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDuplicateGroups(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).deleteDuplicateGroups(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete all list-vs-master results
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteListVsMasterGroup(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).deleteListVsMasterGroup(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete the matching task
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingTask(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).deleteMatchingTask(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Apply a bulk set of actions to a duplicate group
     * @param {number} id
     * @param {DuplicateActions} [duplicateActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeDuplicateGroupAction(
      id: number,
      duplicateActions?: DuplicateActions,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).executeDuplicateGroupAction(
        id,
        duplicateActions,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Apply a bulk set of actions to a list-vs-mst group
     * @param {number} id
     * @param {number} groupNumber
     * @param {ListVsMaterialMasterActions} [listVsMaterialMasterActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeListVsMasterActions(
      id: number,
      groupNumber: number,
      listVsMaterialMasterActions?: ListVsMaterialMasterActions,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).executeListVsMasterActions(
        id,
        groupNumber,
        listVsMaterialMasterActions,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generate export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateExport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).generateExport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all matching tasks (simplified)
     * @param {boolean} [withSparetech]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSimpleMatchingTasks(
      withSparetech?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleMatchingTask>> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getAllSimpleMatchingTasks(
        withSparetech,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all Business Entities with Matching Tasks for a Client
     * @param {number} clientId Client Id
     * @param {boolean} isMaterialMaster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithMatchingTasks(
      clientId: number,
      isMaterialMaster: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessEntities> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getBusinessEntitiesWithMatchingTasks(
        clientId,
        isMaterialMaster,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all clients with matching tasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithMatchingTasks(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clients> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getClientsWithMatchingTasks(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all rows that are bound to the same duplicate group ID
     * @param {number} id
     * @param {number} duplicateGroupNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroupPreview(
      id: number,
      duplicateGroupNumber: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskList> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getDuplicateGroupPreview(
        id,
        duplicateGroupNumber,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the leading row of each duplicate group with unresolved conflicts
     * @param {number} id
     * @param {DuplicatesOverviewQuery} [duplicatesOverviewQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroups(
      id: number,
      duplicatesOverviewQuery?: DuplicatesOverviewQuery,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DuplicateGroups> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getDuplicateGroups(
        id,
        duplicatesOverviewQuery,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the duplicate settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateSettings(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DuplicateMatchingSettings> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getDuplicateSettings(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExports> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getExport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the export settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportSettings(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getExportSettings(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a group with all links between a sparepart and potential materials
     * @param {number} id
     * @param {number} [groupIndex]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMaster(
      id: number,
      groupIndex?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVsMaterialMasterResults> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getListVsMaster(id, groupIndex, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the settings to match a list against a material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMasterSettings(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVsMaterialMasterSettings> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getListVsMasterSettings(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all matched duplicates grouped by product ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchedDuplicates(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchedSpareparts> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchedDuplicates(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets a sparepart list, its rows and all candidates according to filters
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [candidatesFilter]
     * @param {string} [q]
     * @param {string} [createdSince] Creation date, format should be dd.MM.yyyy
     * @param {string} [ref]
     * @param {string} [matchedManufacturer] ID of a manufacturer from the existing matching candidates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingList(
      id: number,
      isMaterialMasterUpdate: boolean,
      page?: number,
      limit?: number,
      candidatesFilter?: string,
      q?: string,
      createdSince?: string,
      ref?: string,
      matchedManufacturer?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskListWithCandidates> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingList(
        id,
        isMaterialMasterUpdate,
        page,
        limit,
        candidatesFilter,
        q,
        createdSince,
        ref,
        matchedManufacturer,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all unique manufacturers from the matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingListManufacturers(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerShort>> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingListManufacturers(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get current progress from all matchings from a specific list
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingProgress(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatchingInfo>> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingProgress(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get matching statistics
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingStatistics(
      id: number,
      isMaterialMasterUpdate: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskStatistics> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingStatistics(
        id,
        isMaterialMasterUpdate,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a matching task\'s full details
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskDetails(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskDetails> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingTaskDetails(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get matching task list details
     * @param {number} id
     * @param {number} [rowLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskList(
      id: number,
      rowLimit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskList> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingTaskList(id, rowLimit, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get matching task matching settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskMatchingSettings(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskListSettings> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingTaskMatchingSettings(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get matching tasks
     * @param {'SparepartList' | 'MaterialMaster'} listType
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [clientId]
     * @param {number} [businessEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTasks(
      listType: "SparepartList" | "MaterialMaster",
      page?: number,
      pageSize?: number,
      clientId?: number,
      businessEntityId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTasks> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMatchingTasks(
        listType,
        page,
        pageSize,
        clientId,
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary See a material master\'s top 5 rows in order to choose the reference column
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterPreview(
      fileId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskList> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMaterialMasterPreview(fileId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all materials with the same reference from a material master
     * @param {number} id
     * @param {string} materialReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialsByReference(
      id: number,
      materialReference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialForMatching> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getMaterialsByReference(
        id,
        materialReference,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a sparepart by its unique reference
     * @param {number} id
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparepart(
      id: number,
      reference: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getSparepart(id, reference, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a task update\'s changeset
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateChangeset(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingTaskChangeset> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).getUpdateChangeset(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Release green matches to the operator app
     * @param {number} id
     * @param {ReleaseInformation} [releaseInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseMatches(
      id: number,
      releaseInformation?: ReleaseInformation,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).releaseMatches(
        id,
        releaseInformation,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Start a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startDuplicatesMatching(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).startDuplicatesMatching(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Start a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startListVsMasterMatching(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).startListVsMasterMatching(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Start a matching against the product DB
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startMatching(
      id: number,
      isMaterialMasterUpdate: boolean,
      startDate?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).startMatching(
        id,
        isMaterialMasterUpdate,
        startDate,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Stop a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopDuplicatesMatching(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).stopDuplicatesMatching(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Stop a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopListVsMasterMatching(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).stopListVsMasterMatching(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Stop a matching against the product DB
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopMatching(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).stopMatching(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update duplicate matching settings
     * @param {number} id
     * @param {DuplicateMatchingSettings} [duplicateMatchingSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDuplicateSettings(
      id: number,
      duplicateMatchingSettings?: DuplicateMatchingSettings,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).updateDuplicateSettings(
        id,
        duplicateMatchingSettings,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update export matching settings
     * @param {number} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportSettings(
      id: number,
      requestBody?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).updateExportSettings(
        id,
        requestBody,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update the settings to match a list against a material master
     * @param {number} id
     * @param {ListVsMaterialMasterSettings} [listVsMaterialMasterSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateListVsMasterSettings(
      id: number,
      listVsMaterialMasterSettings?: ListVsMaterialMasterSettings,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).updateListVsMasterSettings(
        id,
        listVsMaterialMasterSettings,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update matching task list settings details
     * @param {number} id
     * @param {MatchingTaskListSettings} [matchingTaskListSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMatchingTaskMatchingSettings(
      id: number,
      matchingTaskListSettings?: MatchingTaskListSettings,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingApiAxiosParamCreator(configuration).updateMatchingTaskMatchingSettings(
        id,
        matchingTaskListSettings,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MatchingApi - factory interface
 * @export
 */
export const MatchingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Convert the QuickCheck list to normal one
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertQuickCheck(id: number, options?: any) {
      return MatchingApiFp(configuration).convertQuickCheck(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Create new matching task
     * @param {NewMatchingTask} [newMatchingTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMatchingTask(newMatchingTask?: NewMatchingTask, options?: any) {
      return MatchingApiFp(configuration).createMatchingTask(newMatchingTask, options)(axios, basePath);
    },
    /**
     *
     * @summary Deletes all candidates from a list
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {DeleteMatchingCandidates} [deleteMatchingCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllCandidatesFromList(
      id: number,
      isMaterialMasterUpdate: boolean,
      deleteMatchingCandidates?: DeleteMatchingCandidates,
      options?: any,
    ) {
      return MatchingApiFp(configuration).deleteAllCandidatesFromList(
        id,
        isMaterialMasterUpdate,
        deleteMatchingCandidates,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Deletes candidates from a sparepart with specific material reference
     * @param {number} id
     * @param {string} reference
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCandidatesFromSparepart(id: number, reference: string, type: string, options?: any) {
      return MatchingApiFp(configuration).deleteCandidatesFromSparepart(id, reference, type, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete all duplicate groups
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDuplicateGroups(id: number, options?: any) {
      return MatchingApiFp(configuration).deleteDuplicateGroups(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete all list-vs-master results
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteListVsMasterGroup(id: number, options?: any) {
      return MatchingApiFp(configuration).deleteListVsMasterGroup(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete the matching task
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingTask(id: number, options?: any) {
      return MatchingApiFp(configuration).deleteMatchingTask(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Apply a bulk set of actions to a duplicate group
     * @param {number} id
     * @param {DuplicateActions} [duplicateActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeDuplicateGroupAction(id: number, duplicateActions?: DuplicateActions, options?: any) {
      return MatchingApiFp(configuration).executeDuplicateGroupAction(id, duplicateActions, options)(axios, basePath);
    },
    /**
     *
     * @summary Apply a bulk set of actions to a list-vs-mst group
     * @param {number} id
     * @param {number} groupNumber
     * @param {ListVsMaterialMasterActions} [listVsMaterialMasterActions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeListVsMasterActions(
      id: number,
      groupNumber: number,
      listVsMaterialMasterActions?: ListVsMaterialMasterActions,
      options?: any,
    ) {
      return MatchingApiFp(configuration).executeListVsMasterActions(
        id,
        groupNumber,
        listVsMaterialMasterActions,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Generate export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateExport(id: number, options?: any) {
      return MatchingApiFp(configuration).generateExport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all matching tasks (simplified)
     * @param {boolean} [withSparetech]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSimpleMatchingTasks(withSparetech?: boolean, options?: any) {
      return MatchingApiFp(configuration).getAllSimpleMatchingTasks(withSparetech, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all Business Entities with Matching Tasks for a Client
     * @param {number} clientId Client Id
     * @param {boolean} isMaterialMaster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessEntitiesWithMatchingTasks(clientId: number, isMaterialMaster: boolean, options?: any) {
      return MatchingApiFp(configuration).getBusinessEntitiesWithMatchingTasks(
        clientId,
        isMaterialMaster,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all clients with matching tasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsWithMatchingTasks(options?: any) {
      return MatchingApiFp(configuration).getClientsWithMatchingTasks(options)(axios, basePath);
    },
    /**
     *
     * @summary Get all rows that are bound to the same duplicate group ID
     * @param {number} id
     * @param {number} duplicateGroupNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroupPreview(id: number, duplicateGroupNumber: number, options?: any) {
      return MatchingApiFp(configuration).getDuplicateGroupPreview(id, duplicateGroupNumber, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets the leading row of each duplicate group with unresolved conflicts
     * @param {number} id
     * @param {DuplicatesOverviewQuery} [duplicatesOverviewQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateGroups(id: number, duplicatesOverviewQuery?: DuplicatesOverviewQuery, options?: any) {
      return MatchingApiFp(configuration).getDuplicateGroups(id, duplicatesOverviewQuery, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the duplicate settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDuplicateSettings(id: number, options?: any) {
      return MatchingApiFp(configuration).getDuplicateSettings(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the export
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExport(id: number, options?: any) {
      return MatchingApiFp(configuration).getExport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the export settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportSettings(id: number, options?: any) {
      return MatchingApiFp(configuration).getExportSettings(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a group with all links between a sparepart and potential materials
     * @param {number} id
     * @param {number} [groupIndex]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMaster(id: number, groupIndex?: number, options?: any) {
      return MatchingApiFp(configuration).getListVsMaster(id, groupIndex, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the settings to match a list against a material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListVsMasterSettings(id: number, options?: any) {
      return MatchingApiFp(configuration).getListVsMasterSettings(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all matched duplicates grouped by product ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchedDuplicates(id: number, options?: any) {
      return MatchingApiFp(configuration).getMatchedDuplicates(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets a sparepart list, its rows and all candidates according to filters
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [candidatesFilter]
     * @param {string} [q]
     * @param {string} [createdSince] Creation date, format should be dd.MM.yyyy
     * @param {string} [ref]
     * @param {string} [matchedManufacturer] ID of a manufacturer from the existing matching candidates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingList(
      id: number,
      isMaterialMasterUpdate: boolean,
      page?: number,
      limit?: number,
      candidatesFilter?: string,
      q?: string,
      createdSince?: string,
      ref?: string,
      matchedManufacturer?: string,
      options?: any,
    ) {
      return MatchingApiFp(configuration).getMatchingList(
        id,
        isMaterialMasterUpdate,
        page,
        limit,
        candidatesFilter,
        q,
        createdSince,
        ref,
        matchedManufacturer,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all unique manufacturers from the matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingListManufacturers(id: number, options?: any) {
      return MatchingApiFp(configuration).getMatchingListManufacturers(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get current progress from all matchings from a specific list
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingProgress(id: number, options?: any) {
      return MatchingApiFp(configuration).getMatchingProgress(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get matching statistics
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingStatistics(id: number, isMaterialMasterUpdate: boolean, options?: any) {
      return MatchingApiFp(configuration).getMatchingStatistics(id, isMaterialMasterUpdate, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a matching task\'s full details
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskDetails(id: number, options?: any) {
      return MatchingApiFp(configuration).getMatchingTaskDetails(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get matching task list details
     * @param {number} id
     * @param {number} [rowLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskList(id: number, rowLimit?: number, options?: any) {
      return MatchingApiFp(configuration).getMatchingTaskList(id, rowLimit, options)(axios, basePath);
    },
    /**
     *
     * @summary Get matching task matching settings
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTaskMatchingSettings(id: number, options?: any) {
      return MatchingApiFp(configuration).getMatchingTaskMatchingSettings(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get matching tasks
     * @param {'SparepartList' | 'MaterialMaster'} listType
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [clientId]
     * @param {number} [businessEntityId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMatchingTasks(
      listType: "SparepartList" | "MaterialMaster",
      page?: number,
      pageSize?: number,
      clientId?: number,
      businessEntityId?: number,
      options?: any,
    ) {
      return MatchingApiFp(configuration).getMatchingTasks(
        listType,
        page,
        pageSize,
        clientId,
        businessEntityId,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary See a material master\'s top 5 rows in order to choose the reference column
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterPreview(fileId: string, options?: any) {
      return MatchingApiFp(configuration).getMaterialMasterPreview(fileId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all materials with the same reference from a material master
     * @param {number} id
     * @param {string} materialReference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialsByReference(id: number, materialReference: string, options?: any) {
      return MatchingApiFp(configuration).getMaterialsByReference(id, materialReference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a sparepart by its unique reference
     * @param {number} id
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparepart(id: number, reference: string, options?: any) {
      return MatchingApiFp(configuration).getSparepart(id, reference, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a task update\'s changeset
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUpdateChangeset(id: number, options?: any) {
      return MatchingApiFp(configuration).getUpdateChangeset(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Release green matches to the operator app
     * @param {number} id
     * @param {ReleaseInformation} [releaseInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseMatches(id: number, releaseInformation?: ReleaseInformation, options?: any) {
      return MatchingApiFp(configuration).releaseMatches(id, releaseInformation, options)(axios, basePath);
    },
    /**
     *
     * @summary Start a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startDuplicatesMatching(id: number, options?: any) {
      return MatchingApiFp(configuration).startDuplicatesMatching(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Start a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startListVsMasterMatching(id: number, options?: any) {
      return MatchingApiFp(configuration).startListVsMasterMatching(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Start a matching against the product DB
     * @param {number} id
     * @param {boolean} isMaterialMasterUpdate
     * @param {string} [startDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startMatching(id: number, isMaterialMasterUpdate: boolean, startDate?: string, options?: any) {
      return MatchingApiFp(configuration).startMatching(
        id,
        isMaterialMasterUpdate,
        startDate,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Stop a duplicate matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopDuplicatesMatching(id: number, options?: any) {
      return MatchingApiFp(configuration).stopDuplicatesMatching(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Stop a list-vs-master matching job
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopListVsMasterMatching(id: number, options?: any) {
      return MatchingApiFp(configuration).stopListVsMasterMatching(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Stop a matching against the product DB
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopMatching(id: number, options?: any) {
      return MatchingApiFp(configuration).stopMatching(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Update duplicate matching settings
     * @param {number} id
     * @param {DuplicateMatchingSettings} [duplicateMatchingSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDuplicateSettings(id: number, duplicateMatchingSettings?: DuplicateMatchingSettings, options?: any) {
      return MatchingApiFp(configuration).updateDuplicateSettings(
        id,
        duplicateMatchingSettings,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update export matching settings
     * @param {number} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportSettings(id: number, requestBody?: Array<string>, options?: any) {
      return MatchingApiFp(configuration).updateExportSettings(id, requestBody, options)(axios, basePath);
    },
    /**
     *
     * @summary Update the settings to match a list against a material master
     * @param {number} id
     * @param {ListVsMaterialMasterSettings} [listVsMaterialMasterSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateListVsMasterSettings(id: number, listVsMaterialMasterSettings?: ListVsMaterialMasterSettings, options?: any) {
      return MatchingApiFp(configuration).updateListVsMasterSettings(
        id,
        listVsMaterialMasterSettings,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update matching task list settings details
     * @param {number} id
     * @param {MatchingTaskListSettings} [matchingTaskListSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMatchingTaskMatchingSettings(id: number, matchingTaskListSettings?: MatchingTaskListSettings, options?: any) {
      return MatchingApiFp(configuration).updateMatchingTaskMatchingSettings(
        id,
        matchingTaskListSettings,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * MatchingApi - object-oriented interface
 * @export
 * @class MatchingApi
 * @extends {BaseAPI}
 */
export class MatchingApi extends BaseAPI {
  /**
   *
   * @summary Convert the QuickCheck list to normal one
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public convertQuickCheck(id: number, options?: any) {
    return MatchingApiFp(this.configuration).convertQuickCheck(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create new matching task
   * @param {NewMatchingTask} [newMatchingTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public createMatchingTask(newMatchingTask?: NewMatchingTask, options?: any) {
    return MatchingApiFp(this.configuration).createMatchingTask(newMatchingTask, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Deletes all candidates from a list
   * @param {number} id
   * @param {boolean} isMaterialMasterUpdate
   * @param {DeleteMatchingCandidates} [deleteMatchingCandidates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public deleteAllCandidatesFromList(
    id: number,
    isMaterialMasterUpdate: boolean,
    deleteMatchingCandidates?: DeleteMatchingCandidates,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).deleteAllCandidatesFromList(
      id,
      isMaterialMasterUpdate,
      deleteMatchingCandidates,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Deletes candidates from a sparepart with specific material reference
   * @param {number} id
   * @param {string} reference
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public deleteCandidatesFromSparepart(id: number, reference: string, type: string, options?: any) {
    return MatchingApiFp(this.configuration).deleteCandidatesFromSparepart(
      id,
      reference,
      type,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete all duplicate groups
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public deleteDuplicateGroups(id: number, options?: any) {
    return MatchingApiFp(this.configuration).deleteDuplicateGroups(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete all list-vs-master results
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public deleteListVsMasterGroup(id: number, options?: any) {
    return MatchingApiFp(this.configuration).deleteListVsMasterGroup(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete the matching task
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public deleteMatchingTask(id: number, options?: any) {
    return MatchingApiFp(this.configuration).deleteMatchingTask(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Apply a bulk set of actions to a duplicate group
   * @param {number} id
   * @param {DuplicateActions} [duplicateActions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public executeDuplicateGroupAction(id: number, duplicateActions?: DuplicateActions, options?: any) {
    return MatchingApiFp(this.configuration).executeDuplicateGroupAction(
      id,
      duplicateActions,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Apply a bulk set of actions to a list-vs-mst group
   * @param {number} id
   * @param {number} groupNumber
   * @param {ListVsMaterialMasterActions} [listVsMaterialMasterActions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public executeListVsMasterActions(
    id: number,
    groupNumber: number,
    listVsMaterialMasterActions?: ListVsMaterialMasterActions,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).executeListVsMasterActions(
      id,
      groupNumber,
      listVsMaterialMasterActions,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Generate export
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public generateExport(id: number, options?: any) {
    return MatchingApiFp(this.configuration).generateExport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all matching tasks (simplified)
   * @param {boolean} [withSparetech]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getAllSimpleMatchingTasks(withSparetech?: boolean, options?: any) {
    return MatchingApiFp(this.configuration).getAllSimpleMatchingTasks(withSparetech, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all Business Entities with Matching Tasks for a Client
   * @param {number} clientId Client Id
   * @param {boolean} isMaterialMaster
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getBusinessEntitiesWithMatchingTasks(clientId: number, isMaterialMaster: boolean, options?: any) {
    return MatchingApiFp(this.configuration).getBusinessEntitiesWithMatchingTasks(
      clientId,
      isMaterialMaster,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all clients with matching tasks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getClientsWithMatchingTasks(options?: any) {
    return MatchingApiFp(this.configuration).getClientsWithMatchingTasks(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all rows that are bound to the same duplicate group ID
   * @param {number} id
   * @param {number} duplicateGroupNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getDuplicateGroupPreview(id: number, duplicateGroupNumber: number, options?: any) {
    return MatchingApiFp(this.configuration).getDuplicateGroupPreview(
      id,
      duplicateGroupNumber,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets the leading row of each duplicate group with unresolved conflicts
   * @param {number} id
   * @param {DuplicatesOverviewQuery} [duplicatesOverviewQuery]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getDuplicateGroups(id: number, duplicatesOverviewQuery?: DuplicatesOverviewQuery, options?: any) {
    return MatchingApiFp(this.configuration).getDuplicateGroups(
      id,
      duplicatesOverviewQuery,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the duplicate settings
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getDuplicateSettings(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getDuplicateSettings(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the export
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getExport(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getExport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the export settings
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getExportSettings(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getExportSettings(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a group with all links between a sparepart and potential materials
   * @param {number} id
   * @param {number} [groupIndex]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getListVsMaster(id: number, groupIndex?: number, options?: any) {
    return MatchingApiFp(this.configuration).getListVsMaster(id, groupIndex, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the settings to match a list against a material master
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getListVsMasterSettings(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getListVsMasterSettings(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all matched duplicates grouped by product ID
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchedDuplicates(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchedDuplicates(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets a sparepart list, its rows and all candidates according to filters
   * @param {number} id
   * @param {boolean} isMaterialMasterUpdate
   * @param {number} [page]
   * @param {number} [limit]
   * @param {string} [candidatesFilter]
   * @param {string} [q]
   * @param {string} [createdSince] Creation date, format should be dd.MM.yyyy
   * @param {string} [ref]
   * @param {string} [matchedManufacturer] ID of a manufacturer from the existing matching candidates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingList(
    id: number,
    isMaterialMasterUpdate: boolean,
    page?: number,
    limit?: number,
    candidatesFilter?: string,
    q?: string,
    createdSince?: string,
    ref?: string,
    matchedManufacturer?: string,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).getMatchingList(
      id,
      isMaterialMasterUpdate,
      page,
      limit,
      candidatesFilter,
      q,
      createdSince,
      ref,
      matchedManufacturer,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all unique manufacturers from the matching candidates
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingListManufacturers(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingListManufacturers(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get current progress from all matchings from a specific list
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingProgress(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingProgress(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get matching statistics
   * @param {number} id
   * @param {boolean} isMaterialMasterUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingStatistics(id: number, isMaterialMasterUpdate: boolean, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingStatistics(
      id,
      isMaterialMasterUpdate,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a matching task\'s full details
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingTaskDetails(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingTaskDetails(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get matching task list details
   * @param {number} id
   * @param {number} [rowLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingTaskList(id: number, rowLimit?: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingTaskList(id, rowLimit, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get matching task matching settings
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingTaskMatchingSettings(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getMatchingTaskMatchingSettings(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get matching tasks
   * @param {'SparepartList' | 'MaterialMaster'} listType
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {number} [clientId]
   * @param {number} [businessEntityId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMatchingTasks(
    listType: "SparepartList" | "MaterialMaster",
    page?: number,
    pageSize?: number,
    clientId?: number,
    businessEntityId?: number,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).getMatchingTasks(
      listType,
      page,
      pageSize,
      clientId,
      businessEntityId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary See a material master\'s top 5 rows in order to choose the reference column
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMaterialMasterPreview(fileId: string, options?: any) {
    return MatchingApiFp(this.configuration).getMaterialMasterPreview(fileId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all materials with the same reference from a material master
   * @param {number} id
   * @param {string} materialReference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getMaterialsByReference(id: number, materialReference: string, options?: any) {
    return MatchingApiFp(this.configuration).getMaterialsByReference(
      id,
      materialReference,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a sparepart by its unique reference
   * @param {number} id
   * @param {string} reference
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getSparepart(id: number, reference: string, options?: any) {
    return MatchingApiFp(this.configuration).getSparepart(id, reference, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a task update\'s changeset
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public getUpdateChangeset(id: number, options?: any) {
    return MatchingApiFp(this.configuration).getUpdateChangeset(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Release green matches to the operator app
   * @param {number} id
   * @param {ReleaseInformation} [releaseInformation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public releaseMatches(id: number, releaseInformation?: ReleaseInformation, options?: any) {
    return MatchingApiFp(this.configuration).releaseMatches(id, releaseInformation, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Start a duplicate matching job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public startDuplicatesMatching(id: number, options?: any) {
    return MatchingApiFp(this.configuration).startDuplicatesMatching(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Start a list-vs-master matching job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public startListVsMasterMatching(id: number, options?: any) {
    return MatchingApiFp(this.configuration).startListVsMasterMatching(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Start a matching against the product DB
   * @param {number} id
   * @param {boolean} isMaterialMasterUpdate
   * @param {string} [startDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public startMatching(id: number, isMaterialMasterUpdate: boolean, startDate?: string, options?: any) {
    return MatchingApiFp(this.configuration).startMatching(
      id,
      isMaterialMasterUpdate,
      startDate,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Stop a duplicate matching job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public stopDuplicatesMatching(id: number, options?: any) {
    return MatchingApiFp(this.configuration).stopDuplicatesMatching(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Stop a list-vs-master matching job
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public stopListVsMasterMatching(id: number, options?: any) {
    return MatchingApiFp(this.configuration).stopListVsMasterMatching(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Stop a matching against the product DB
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public stopMatching(id: number, options?: any) {
    return MatchingApiFp(this.configuration).stopMatching(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update duplicate matching settings
   * @param {number} id
   * @param {DuplicateMatchingSettings} [duplicateMatchingSettings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public updateDuplicateSettings(id: number, duplicateMatchingSettings?: DuplicateMatchingSettings, options?: any) {
    return MatchingApiFp(this.configuration).updateDuplicateSettings(
      id,
      duplicateMatchingSettings,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update export matching settings
   * @param {number} id
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public updateExportSettings(id: number, requestBody?: Array<string>, options?: any) {
    return MatchingApiFp(this.configuration).updateExportSettings(id, requestBody, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update the settings to match a list against a material master
   * @param {number} id
   * @param {ListVsMaterialMasterSettings} [listVsMaterialMasterSettings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public updateListVsMasterSettings(
    id: number,
    listVsMaterialMasterSettings?: ListVsMaterialMasterSettings,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).updateListVsMasterSettings(
      id,
      listVsMaterialMasterSettings,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update matching task list settings details
   * @param {number} id
   * @param {MatchingTaskListSettings} [matchingTaskListSettings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public updateMatchingTaskMatchingSettings(
    id: number,
    matchingTaskListSettings?: MatchingTaskListSettings,
    options?: any,
  ) {
    return MatchingApiFp(this.configuration).updateMatchingTaskMatchingSettings(
      id,
      matchingTaskListSettings,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * MatchingCandidatesApi - axios parameter creator
 * @export
 */
export const MatchingCandidatesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Processes matching candidates in bulk
     * @param {MatchingBulkActionRequest} [matchingBulkActionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkProcessMatchingCandidates(
      matchingBulkActionRequest?: MatchingBulkActionRequest,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/matching-candidates/bulk`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MatchingBulkActionRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matchingBulkActionRequest !== undefined ? matchingBulkActionRequest : {})
        : matchingBulkActionRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a matching candidate
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingCandidate(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteMatchingCandidate.",
        );
      }
      const localVarPath = `/matching-candidates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set a candidate as matched or not matched
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toggleMatchingCandidate(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling toggleMatchingCandidate.",
        );
      }
      const localVarPath = `/matching-candidates/{id}/toggle`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MatchingCandidatesApi - functional programming interface
 * @export
 */
export const MatchingCandidatesApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Processes matching candidates in bulk
     * @param {MatchingBulkActionRequest} [matchingBulkActionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkProcessMatchingCandidates(
      matchingBulkActionRequest?: MatchingBulkActionRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchingBulkActionResponse> {
      const localVarAxiosArgs = MatchingCandidatesApiAxiosParamCreator(configuration).bulkProcessMatchingCandidates(
        matchingBulkActionRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a matching candidate
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingCandidate(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingCandidatesApiAxiosParamCreator(configuration).deleteMatchingCandidate(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Set a candidate as matched or not matched
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toggleMatchingCandidate(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MatchingCandidatesApiAxiosParamCreator(configuration).toggleMatchingCandidate(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MatchingCandidatesApi - factory interface
 * @export
 */
export const MatchingCandidatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Processes matching candidates in bulk
     * @param {MatchingBulkActionRequest} [matchingBulkActionRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkProcessMatchingCandidates(matchingBulkActionRequest?: MatchingBulkActionRequest, options?: any) {
      return MatchingCandidatesApiFp(configuration).bulkProcessMatchingCandidates(matchingBulkActionRequest, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Delete a matching candidate
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMatchingCandidate(id: number, options?: any) {
      return MatchingCandidatesApiFp(configuration).deleteMatchingCandidate(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Set a candidate as matched or not matched
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toggleMatchingCandidate(id: number, options?: any) {
      return MatchingCandidatesApiFp(configuration).toggleMatchingCandidate(id, options)(axios, basePath);
    },
  };
};

/**
 * MatchingCandidatesApi - object-oriented interface
 * @export
 * @class MatchingCandidatesApi
 * @extends {BaseAPI}
 */
export class MatchingCandidatesApi extends BaseAPI {
  /**
   *
   * @summary Processes matching candidates in bulk
   * @param {MatchingBulkActionRequest} [matchingBulkActionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingCandidatesApi
   */
  public bulkProcessMatchingCandidates(matchingBulkActionRequest?: MatchingBulkActionRequest, options?: any) {
    return MatchingCandidatesApiFp(this.configuration).bulkProcessMatchingCandidates(
      matchingBulkActionRequest,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a matching candidate
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingCandidatesApi
   */
  public deleteMatchingCandidate(id: number, options?: any) {
    return MatchingCandidatesApiFp(this.configuration).deleteMatchingCandidate(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Set a candidate as matched or not matched
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingCandidatesApi
   */
  public toggleMatchingCandidate(id: number, options?: any) {
    return MatchingCandidatesApiFp(this.configuration).toggleMatchingCandidate(id, options)(this.axios, this.basePath);
  }
}

/**
 * MaterialMasterApi - axios parameter creator
 * @export
 */
export const MaterialMasterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancels a material master update and deletes all relevant data
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelMaterialMasterUpdate(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling cancelMaterialMasterUpdate.",
        );
      }
      const localVarPath = `/material-masters/{id}/update/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Accepts a material master update\'s changeset and changes the update\'s status to MATCHING
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTaskUpdate(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling confirmTaskUpdate.");
      }
      const localVarPath = `/material-masters/{id}/update/confirm`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a form field group
     * @param {number} materialMasterId
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFormFieldGroup(materialMasterId: number, newFieldGroup?: NewFieldGroup, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling createFormFieldGroup.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-field-groups`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewFieldGroup" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newFieldGroup !== undefined ? newFieldGroup : {})
        : newFieldGroup || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialMasterFormField(
      materialMasterId: number,
      formFieldConfiguration?: FormFieldConfiguration,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling createMaterialMasterFormField.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-fields-settings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormFieldConfiguration" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formFieldConfiguration !== undefined ? formFieldConfiguration : {})
        : formFieldConfiguration || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new user form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFormField(
      materialMasterId: number,
      formFieldConfiguration?: FormFieldConfiguration,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling createUserFormField.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/user-form-fields-settings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormFieldConfiguration" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formFieldConfiguration !== undefined ? formFieldConfiguration : {})
        : formFieldConfiguration || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMapping(materialMasterId: number, type: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling deleteMapping.",
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError("type", "Required parameter type was null or undefined when calling deleteMapping.");
      }
      const localVarPath = `/material-masters/{materialMasterId}/mapping/{type}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdditionalPlantFields(materialMasterId: number, id: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getAdditionalPlantFields.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getAdditionalPlantFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/plants/{id}/additional-fields`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutUserFields] Without user related fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFields(materialMasterId: number, withoutUserFields?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getAvailableFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/available-form-fields`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutUserFields !== undefined) {
        localVarQueryParameter["withoutUserFields"] = withoutUserFields;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all (OptionsField, OptionsLookupField) form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailablePlantFields(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getAvailablePlantFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/available-plant-fields`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the configuration logs for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigurationLogs(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getConfigurationLogs.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/configuration-logs`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get export mappings for a given material master configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportMappings(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getExportMappings.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/export-mappings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all original fields or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFields(materialMasterId: number, isUserField: boolean, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getFields.",
        );
      }
      // verify required parameter 'isUserField' is not null or undefined
      if (isUserField === null || isUserField === undefined) {
        throw new RequiredError(
          "isUserField",
          "Required parameter isUserField was null or undefined when calling getFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/fields`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isUserField !== undefined) {
        localVarQueryParameter["isUserField"] = isUserField;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the details for the formfield
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId formfield-id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldDetails(materialMasterId: number, fieldId: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getFormFieldDetails.",
        );
      }
      // verify required parameter 'fieldId' is not null or undefined
      if (fieldId === null || fieldId === undefined) {
        throw new RequiredError(
          "fieldId",
          "Required parameter fieldId was null or undefined when calling getFormFieldDetails.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-field-details/{fieldId}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all details from a form field group
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroupConfiguration(materialMasterId: number, id: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getFormFieldGroupConfiguration.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getFormFieldGroupConfiguration.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-field-groups/{id}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all form field groups
     * @param {number} materialMasterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroups(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getFormFieldGroups.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-field-groups`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all original groups or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroups(materialMasterId: number, isUserField: boolean, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getGroups.",
        );
      }
      // verify required parameter 'isUserField' is not null or undefined
      if (isUserField === null || isUserField === undefined) {
        throw new RequiredError(
          "isUserField",
          "Required parameter isUserField was null or undefined when calling getGroups.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/groups`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isUserField !== undefined) {
        localVarQueryParameter["isUserField"] = isUserField;
      }

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerMapping(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getManufacturerMapping.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/manufacturer-mapping`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the options for the manufacturer. Or empty, if not available
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerOptions(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getManufacturerOptions.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/formfields/manufacturer-options`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapping(materialMasterId: number, type: string, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMapping.",
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError("type", "Required parameter type was null or undefined when calling getMapping.");
      }
      const localVarPath = `/material-masters/{materialMasterId}/mapping/{type}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the current material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getMaterialMaster.");
      }
      const localVarPath = `/material-masters/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFieldConfigurations(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterFieldConfigurations.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-fields-settings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutFileField] Without form field \&#39;file\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFields(materialMasterId: number, withoutFileField?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-fields`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutFileField !== undefined) {
        localVarQueryParameter["withoutFileField"] = withoutFileField;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all material master upload logs
     * @param {number} id
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(id: number, page?: number, limit?: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getMaterialMasterHistory.",
        );
      }
      const localVarPath = `/material-masters/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets material master import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterImportConfiguration(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterImportConfiguration.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/import-configuration`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterLayout(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterLayout.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-layout`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get material master statistics
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterStatistics.",
        );
      }
      const localVarPath = `/material-master/{materialMasterId}/statistics`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the status of a materialmaster update
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUpdateStatus(businessEntityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getMaterialMasterUpdateStatus.",
        );
      }
      const localVarPath = `/material-masters/{businessEntityId}/update/status`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUserFormLayout(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialMasterUserFormLayout.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/user-form-layout`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available user form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialUserFields(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getMaterialUserFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/user-form-fields`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the remaining plant values
     * @param {number} businessEntityId Business Entity Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRemainingPlantValues(businessEntityId: number, options: any = {}): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling getRemainingPlantValues.",
        );
      }
      const localVarPath = `/material-masters/{businessEntityId}/plant-values`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user data creation form
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDataForm(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUserDataForm.",
        );
      }
      const localVarPath = `/material-master/user-form/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all user form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFieldConfigurations(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling getUserFieldConfigurations.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/user-form-fields-settings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(userId: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getUserFormValues.",
        );
      }
      const localVarPath = `/material-master/user-form-values/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Merge a MM with is update, along with all relevant matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeMaterialMasterUpdate(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling mergeMaterialMasterUpdate.",
        );
      }
      const localVarPath = `/material-masters/{id}/update/merge`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Pre-process all columns that are mapped to special fields
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preProcessColumns(materialMasterId: number, options: any = {}): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling preProcessColumns.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/pre-process`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save a SPARETECH result mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {FormFieldMapping} [formFieldMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setMapping(
      materialMasterId: number,
      type: string,
      formFieldMapping?: FormFieldMapping,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling setMapping.",
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError("type", "Required parameter type was null or undefined when calling setMapping.");
      }
      const localVarPath = `/material-masters/{materialMasterId}/mapping/{type}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormFieldMapping" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formFieldMapping !== undefined ? formFieldMapping : {})
        : formFieldMapping || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdditionalPlantFields(
      materialMasterId: number,
      id: string,
      requestBody?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateAdditionalPlantFields.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateAdditionalPlantFields.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/plants/{id}/additional-fields`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update export mappings for a given material configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialExportMappings} [materialExportMappings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportMappings(
      materialMasterId: number,
      materialExportMappings?: MaterialExportMappings,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateExportMappings.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/export-mappings`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialExportMappings" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialExportMappings !== undefined ? materialExportMappings : {})
        : materialExportMappings || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update form fields translation
     * @param {boolean} isUserField If true it returns the user fields
     * @param {FormFieldI18ns} [formFieldI18ns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFieldsTranslation(isUserField: boolean, formFieldI18ns?: FormFieldI18ns, options: any = {}): RequestArgs {
      // verify required parameter 'isUserField' is not null or undefined
      if (isUserField === null || isUserField === undefined) {
        throw new RequiredError(
          "isUserField",
          "Required parameter isUserField was null or undefined when calling updateFieldsTranslation.",
        );
      }
      const localVarPath = `/material-masters/form-fields-translation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isUserField !== undefined) {
        localVarQueryParameter["isUserField"] = isUserField;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormFieldI18ns" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formFieldI18ns !== undefined ? formFieldI18ns : {})
        : formFieldI18ns || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a form field of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId Field ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldConfiguration(
      materialMasterId: number,
      fieldId: string,
      formFieldConfiguration?: FormFieldConfiguration,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateFormFieldConfiguration.",
        );
      }
      // verify required parameter 'fieldId' is not null or undefined
      if (fieldId === null || fieldId === undefined) {
        throw new RequiredError(
          "fieldId",
          "Required parameter fieldId was null or undefined when calling updateFormFieldConfiguration.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-fields-settings/{fieldId}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormFieldConfiguration" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formFieldConfiguration !== undefined ? formFieldConfiguration : {})
        : formFieldConfiguration || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a field group\'s configuration
     * @param {number} materialMasterId
     * @param {string} id
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldGroup(
      materialMasterId: number,
      id: string,
      newFieldGroup?: NewFieldGroup,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateFormFieldGroup.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateFormFieldGroup.");
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-field-groups/{id}`
        .replace(`{${"materialMasterId"}}`, encodeURIComponent(String(materialMasterId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewFieldGroup" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newFieldGroup !== undefined ? newFieldGroup : {})
        : newFieldGroup || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updated the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {ManufacturerMapping} [manufacturerMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerMapping(
      materialMasterId: number,
      manufacturerMapping?: ManufacturerMapping,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateManufacturerMapping.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/manufacturer-mapping`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ManufacturerMapping" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(manufacturerMapping !== undefined ? manufacturerMapping : {})
        : manufacturerMapping || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary update current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {FormLayout} [formLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMasterDataFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      formLayout?: FormLayout,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateMasterDataFormLayout.",
        );
      }
      // verify required parameter 'isTranslation' is not null or undefined
      if (isTranslation === null || isTranslation === undefined) {
        throw new RequiredError(
          "isTranslation",
          "Required parameter isTranslation was null or undefined when calling updateMasterDataFormLayout.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/form-layout`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isTranslation !== undefined) {
        localVarQueryParameter["isTranslation"] = isTranslation;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"FormLayout" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(formLayout !== undefined ? formLayout : {})
        : formLayout || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a material master configuration
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumns} [materialMasterColumns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterDisplayColumns(
      businessEntityId: number,
      materialMasterColumns?: MaterialMasterColumns,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'businessEntityId' is not null or undefined
      if (businessEntityId === null || businessEntityId === undefined) {
        throw new RequiredError(
          "businessEntityId",
          "Required parameter businessEntityId was null or undefined when calling updateMaterialMasterDisplayColumns.",
        );
      }
      const localVarPath = `/business-entity/{businessEntityId}/material-master/display-columns`.replace(
        `{${"businessEntityId"}}`,
        encodeURIComponent(String(businessEntityId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterColumns" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterColumns !== undefined ? materialMasterColumns : {})
        : materialMasterColumns || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a material master\'s import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialMasterImportConfiguration} [materialMasterImportConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterImportConfiguration(
      materialMasterId: number,
      materialMasterImportConfiguration?: MaterialMasterImportConfiguration,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateMaterialMasterImportConfiguration.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/import-configuration`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"MaterialMasterImportConfiguration" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(materialMasterImportConfiguration !== undefined ? materialMasterImportConfiguration : {})
        : materialMasterImportConfiguration || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary update current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {UserFormLayout} [userFormLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      userFormLayout?: UserFormLayout,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'materialMasterId' is not null or undefined
      if (materialMasterId === null || materialMasterId === undefined) {
        throw new RequiredError(
          "materialMasterId",
          "Required parameter materialMasterId was null or undefined when calling updateUserFormLayout.",
        );
      }
      // verify required parameter 'isTranslation' is not null or undefined
      if (isTranslation === null || isTranslation === undefined) {
        throw new RequiredError(
          "isTranslation",
          "Required parameter isTranslation was null or undefined when calling updateUserFormLayout.",
        );
      }
      const localVarPath = `/material-masters/{materialMasterId}/user-form-layout`.replace(
        `{${"materialMasterId"}}`,
        encodeURIComponent(String(materialMasterId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isTranslation !== undefined) {
        localVarQueryParameter["isTranslation"] = isTranslation;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"UserFormLayout" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userFormLayout !== undefined ? userFormLayout : {})
        : userFormLayout || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(userId: string, body?: object, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling updateUserFormValues.",
        );
      }
      const localVarPath = `/material-master/user-form-values/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"object" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MaterialMasterApi - functional programming interface
 * @export
 */
export const MaterialMasterApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancels a material master update and deletes all relevant data
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelMaterialMasterUpdate(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).cancelMaterialMasterUpdate(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Accepts a material master update\'s changeset and changes the update\'s status to MATCHING
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTaskUpdate(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).confirmTaskUpdate(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a form field group
     * @param {number} materialMasterId
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFormFieldGroup(
      materialMasterId: number,
      newFieldGroup?: NewFieldGroup,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).createFormFieldGroup(
        materialMasterId,
        newFieldGroup,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialMasterFormField(
      materialMasterId: number,
      formFieldConfiguration?: FormFieldConfiguration,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).createMaterialMasterFormField(
        materialMasterId,
        formFieldConfiguration,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new user form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFormField(
      materialMasterId: number,
      formFieldConfiguration?: FormFieldConfiguration,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).createUserFormField(
        materialMasterId,
        formFieldConfiguration,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMapping(
      materialMasterId: number,
      type: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).deleteMapping(
        materialMasterId,
        type,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdditionalPlantFields(
      materialMasterId: number,
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getAdditionalPlantFields(
        materialMasterId,
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutUserFields] Without user related fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFields(
      materialMasterId: number,
      withoutUserFields?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormField>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getAvailableFields(
        materialMasterId,
        withoutUserFields,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all (OptionsField, OptionsLookupField) form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailablePlantFields(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormField>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getAvailablePlantFields(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the configuration logs for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigurationLogs(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationLog>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getConfigurationLogs(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get export mappings for a given material master configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportMappings(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialExportMapping>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getExportMappings(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all original fields or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFields(
      materialMasterId: number,
      isUserField: boolean,
      language?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormFieldConfiguration>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getFields(
        materialMasterId,
        isUserField,
        language,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the details for the formfield
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId formfield-id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldDetails(
      materialMasterId: number,
      fieldId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormFieldDetails> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getFormFieldDetails(
        materialMasterId,
        fieldId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all details from a form field group
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroupConfiguration(
      materialMasterId: number,
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormFieldGroupConfiguration> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getFormFieldGroupConfiguration(
        materialMasterId,
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all form field groups
     * @param {number} materialMasterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroups(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormFieldGroup>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getFormFieldGroups(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all original groups or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroups(
      materialMasterId: number,
      isUserField: boolean,
      language?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormFieldConfiguration>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getGroups(
        materialMasterId,
        isUserField,
        language,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerMapping(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelValuePair>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getManufacturerMapping(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the options for the manufacturer. Or empty, if not available
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerOptions(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelValuePair>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getManufacturerOptions(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapping(
      materialMasterId: number,
      type: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormFieldMapping> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMapping(
        materialMasterId,
        type,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the current material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMaster> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMaster(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFieldConfigurations(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormFieldConfiguration>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterFieldConfigurations(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutFileField] Without form field \&#39;file\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFields(
      materialMasterId: number,
      withoutFileField?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormField>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterFields(
        materialMasterId,
        withoutFileField,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all material master upload logs
     * @param {number} id
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(
      id: number,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterLogs> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterHistory(
        id,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets material master import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterImportConfiguration(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterImportConfiguration> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterImportConfiguration(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterLayout(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormLayout> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterLayout(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get material master statistics
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialMasterStatistics>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterStatistics(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the status of a materialmaster update
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUpdateStatus(
      businessEntityId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialMasterUpdateStatus> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterUpdateStatus(
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUserFormLayout(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormLayout> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialMasterUserFormLayout(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all available user form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialUserFields(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormField>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getMaterialUserFields(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the remaining plant values
     * @param {number} businessEntityId Business Entity Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRemainingPlantValues(
      businessEntityId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabelValuePair>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getRemainingPlantValues(
        businessEntityId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user data creation form
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDataForm(
      userId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserForm> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getUserDataForm(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all user form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFieldConfigurations(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormFieldConfiguration>> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getUserFieldConfigurations(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(
      userId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).getUserFormValues(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Merge a MM with is update, along with all relevant matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeMaterialMasterUpdate(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).mergeMaterialMasterUpdate(
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Pre-process all columns that are mapped to special fields
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preProcessColumns(
      materialMasterId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).preProcessColumns(
        materialMasterId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Save a SPARETECH result mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {FormFieldMapping} [formFieldMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setMapping(
      materialMasterId: number,
      type: string,
      formFieldMapping?: FormFieldMapping,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).setMapping(
        materialMasterId,
        type,
        formFieldMapping,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdditionalPlantFields(
      materialMasterId: number,
      id: string,
      requestBody?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateAdditionalPlantFields(
        materialMasterId,
        id,
        requestBody,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update export mappings for a given material configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialExportMappings} [materialExportMappings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportMappings(
      materialMasterId: number,
      materialExportMappings?: MaterialExportMappings,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateExportMappings(
        materialMasterId,
        materialExportMappings,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update form fields translation
     * @param {boolean} isUserField If true it returns the user fields
     * @param {FormFieldI18ns} [formFieldI18ns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFieldsTranslation(
      isUserField: boolean,
      formFieldI18ns?: FormFieldI18ns,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateFieldsTranslation(
        isUserField,
        formFieldI18ns,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a form field of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId Field ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldConfiguration(
      materialMasterId: number,
      fieldId: string,
      formFieldConfiguration?: FormFieldConfiguration,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateFormFieldConfiguration(
        materialMasterId,
        fieldId,
        formFieldConfiguration,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a field group\'s configuration
     * @param {number} materialMasterId
     * @param {string} id
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldGroup(
      materialMasterId: number,
      id: string,
      newFieldGroup?: NewFieldGroup,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateFormFieldGroup(
        materialMasterId,
        id,
        newFieldGroup,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updated the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {ManufacturerMapping} [manufacturerMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerMapping(
      materialMasterId: number,
      manufacturerMapping?: ManufacturerMapping,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateManufacturerMapping(
        materialMasterId,
        manufacturerMapping,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary update current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {FormLayout} [formLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMasterDataFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      formLayout?: FormLayout,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormLayout> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateMasterDataFormLayout(
        materialMasterId,
        isTranslation,
        formLayout,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a material master configuration
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumns} [materialMasterColumns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterDisplayColumns(
      businessEntityId: number,
      materialMasterColumns?: MaterialMasterColumns,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateMaterialMasterDisplayColumns(
        businessEntityId,
        materialMasterColumns,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Updates a material master\'s import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialMasterImportConfiguration} [materialMasterImportConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterImportConfiguration(
      materialMasterId: number,
      materialMasterImportConfiguration?: MaterialMasterImportConfiguration,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(
        configuration,
      ).updateMaterialMasterImportConfiguration(materialMasterId, materialMasterImportConfiguration, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary update current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {UserFormLayout} [userFormLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      userFormLayout?: UserFormLayout,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFormLayout> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateUserFormLayout(
        materialMasterId,
        isTranslation,
        userFormLayout,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(
      userId: string,
      body?: object,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = MaterialMasterApiAxiosParamCreator(configuration).updateUserFormValues(
        userId,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MaterialMasterApi - factory interface
 * @export
 */
export const MaterialMasterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Cancels a material master update and deletes all relevant data
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelMaterialMasterUpdate(id: number, options?: any) {
      return MaterialMasterApiFp(configuration).cancelMaterialMasterUpdate(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Accepts a material master update\'s changeset and changes the update\'s status to MATCHING
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmTaskUpdate(id: number, options?: any) {
      return MaterialMasterApiFp(configuration).confirmTaskUpdate(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a form field group
     * @param {number} materialMasterId
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFormFieldGroup(materialMasterId: number, newFieldGroup?: NewFieldGroup, options?: any) {
      return MaterialMasterApiFp(configuration).createFormFieldGroup(
        materialMasterId,
        newFieldGroup,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Create a new form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterialMasterFormField(
      materialMasterId: number,
      formFieldConfiguration?: FormFieldConfiguration,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).createMaterialMasterFormField(
        materialMasterId,
        formFieldConfiguration,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Create a new user form fields of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserFormField(materialMasterId: number, formFieldConfiguration?: FormFieldConfiguration, options?: any) {
      return MaterialMasterApiFp(configuration).createUserFormField(
        materialMasterId,
        formFieldConfiguration,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete a mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMapping(materialMasterId: number, type: string, options?: any) {
      return MaterialMasterApiFp(configuration).deleteMapping(materialMasterId, type, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdditionalPlantFields(materialMasterId: number, id: string, options?: any) {
      return MaterialMasterApiFp(configuration).getAdditionalPlantFields(
        materialMasterId,
        id,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutUserFields] Without user related fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFields(materialMasterId: number, withoutUserFields?: boolean, options?: any) {
      return MaterialMasterApiFp(configuration).getAvailableFields(
        materialMasterId,
        withoutUserFields,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all (OptionsField, OptionsLookupField) form fields that are not part of the layout
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailablePlantFields(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getAvailablePlantFields(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the configuration logs for the material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfigurationLogs(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getConfigurationLogs(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get export mappings for a given material master configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExportMappings(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getExportMappings(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all original fields or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFields(materialMasterId: number, isUserField: boolean, language?: string, options?: any) {
      return MaterialMasterApiFp(configuration).getFields(
        materialMasterId,
        isUserField,
        language,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get the details for the formfield
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId formfield-id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldDetails(materialMasterId: number, fieldId: string, options?: any) {
      return MaterialMasterApiFp(configuration).getFormFieldDetails(
        materialMasterId,
        fieldId,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all details from a form field group
     * @param {number} materialMasterId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroupConfiguration(materialMasterId: number, id: string, options?: any) {
      return MaterialMasterApiFp(configuration).getFormFieldGroupConfiguration(
        materialMasterId,
        id,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all form field groups
     * @param {number} materialMasterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFormFieldGroups(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getFormFieldGroups(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all original groups or only the translations depending on the `language` parameter
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isUserField If true it returns the user fields
     * @param {string} [language] The language of the needed translations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGroups(materialMasterId: number, isUserField: boolean, language?: string, options?: any) {
      return MaterialMasterApiFp(configuration).getGroups(
        materialMasterId,
        isUserField,
        language,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Gets the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerMapping(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getManufacturerMapping(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets the options for the manufacturer. Or empty, if not available
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturerOptions(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getManufacturerOptions(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get mapping for a material master
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMapping(materialMasterId: number, type: string, options?: any) {
      return MaterialMasterApiFp(configuration).getMapping(materialMasterId, type, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the current material master
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMaster(id: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMaster(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFieldConfigurations(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterFieldConfigurations(materialMasterId, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get all available form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} [withoutFileField] Without form field \&#39;file\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterFields(materialMasterId: number, withoutFileField?: boolean, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterFields(
        materialMasterId,
        withoutFileField,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get all material master upload logs
     * @param {number} id
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterHistory(id: number, page?: number, limit?: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterHistory(id, page, limit, options)(axios, basePath);
    },
    /**
     *
     * @summary Gets material master import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterImportConfiguration(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterImportConfiguration(materialMasterId, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterLayout(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterLayout(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get material master statistics
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterStatistics(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterStatistics(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the status of a materialmaster update
     * @param {number} businessEntityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUpdateStatus(businessEntityId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterUpdateStatus(businessEntityId, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialMasterUserFormLayout(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialMasterUserFormLayout(materialMasterId, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Get all available user form fields of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaterialUserFields(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getMaterialUserFields(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the remaining plant values
     * @param {number} businessEntityId Business Entity Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRemainingPlantValues(businessEntityId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getRemainingPlantValues(businessEntityId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get user data creation form
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDataForm(userId: string, options?: any) {
      return MaterialMasterApiFp(configuration).getUserDataForm(userId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all user form fields and their configuration of a material master as a list
     * @param {number} materialMasterId Material Master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFieldConfigurations(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).getUserFieldConfigurations(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get user form values
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserFormValues(userId: string, options?: any) {
      return MaterialMasterApiFp(configuration).getUserFormValues(userId, options)(axios, basePath);
    },
    /**
     *
     * @summary Merge a MM with is update, along with all relevant matching candidates
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeMaterialMasterUpdate(id: number, options?: any) {
      return MaterialMasterApiFp(configuration).mergeMaterialMasterUpdate(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Pre-process all columns that are mapped to special fields
     * @param {number} materialMasterId Material master ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preProcessColumns(materialMasterId: number, options?: any) {
      return MaterialMasterApiFp(configuration).preProcessColumns(materialMasterId, options)(axios, basePath);
    },
    /**
     *
     * @summary Save a SPARETECH result mapping
     * @param {number} materialMasterId Material master ID
     * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
     * @param {FormFieldMapping} [formFieldMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setMapping(materialMasterId: number, type: string, formFieldMapping?: FormFieldMapping, options?: any) {
      return MaterialMasterApiFp(configuration).setMapping(
        materialMasterId,
        type,
        formFieldMapping,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update additional plant fields
     * @param {number} materialMasterId
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAdditionalPlantFields(materialMasterId: number, id: string, requestBody?: Array<string>, options?: any) {
      return MaterialMasterApiFp(configuration).updateAdditionalPlantFields(
        materialMasterId,
        id,
        requestBody,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update export mappings for a given material configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialExportMappings} [materialExportMappings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExportMappings(materialMasterId: number, materialExportMappings?: MaterialExportMappings, options?: any) {
      return MaterialMasterApiFp(configuration).updateExportMappings(
        materialMasterId,
        materialExportMappings,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update form fields translation
     * @param {boolean} isUserField If true it returns the user fields
     * @param {FormFieldI18ns} [formFieldI18ns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFieldsTranslation(isUserField: boolean, formFieldI18ns?: FormFieldI18ns, options?: any) {
      return MaterialMasterApiFp(configuration).updateFieldsTranslation(
        isUserField,
        formFieldI18ns,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update a form field of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {string} fieldId Field ID
     * @param {FormFieldConfiguration} [formFieldConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldConfiguration(
      materialMasterId: number,
      fieldId: string,
      formFieldConfiguration?: FormFieldConfiguration,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).updateFormFieldConfiguration(
        materialMasterId,
        fieldId,
        formFieldConfiguration,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update a field group\'s configuration
     * @param {number} materialMasterId
     * @param {string} id
     * @param {NewFieldGroup} [newFieldGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFormFieldGroup(materialMasterId: number, id: string, newFieldGroup?: NewFieldGroup, options?: any) {
      return MaterialMasterApiFp(configuration).updateFormFieldGroup(
        materialMasterId,
        id,
        newFieldGroup,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Updated the manufacturer mapping
     * @param {number} materialMasterId Material master ID
     * @param {ManufacturerMapping} [manufacturerMapping]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerMapping(materialMasterId: number, manufacturerMapping?: ManufacturerMapping, options?: any) {
      return MaterialMasterApiFp(configuration).updateManufacturerMapping(
        materialMasterId,
        manufacturerMapping,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary update current form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {FormLayout} [formLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMasterDataFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      formLayout?: FormLayout,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).updateMasterDataFormLayout(
        materialMasterId,
        isTranslation,
        formLayout,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update a material master configuration
     * @param {number} businessEntityId Business entity ID
     * @param {MaterialMasterColumns} [materialMasterColumns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterDisplayColumns(
      businessEntityId: number,
      materialMasterColumns?: MaterialMasterColumns,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).updateMaterialMasterDisplayColumns(
        businessEntityId,
        materialMasterColumns,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Updates a material master\'s import configuration
     * @param {number} materialMasterId Material Master ID
     * @param {MaterialMasterImportConfiguration} [materialMasterImportConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterialMasterImportConfiguration(
      materialMasterId: number,
      materialMasterImportConfiguration?: MaterialMasterImportConfiguration,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).updateMaterialMasterImportConfiguration(
        materialMasterId,
        materialMasterImportConfiguration,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary update current user form layout of a material master
     * @param {number} materialMasterId Material Master ID
     * @param {boolean} isTranslation Value to differentiate change logs
     * @param {UserFormLayout} [userFormLayout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormLayout(
      materialMasterId: number,
      isTranslation: boolean,
      userFormLayout?: UserFormLayout,
      options?: any,
    ) {
      return MaterialMasterApiFp(configuration).updateUserFormLayout(
        materialMasterId,
        isTranslation,
        userFormLayout,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update user values
     * @param {string} userId
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserFormValues(userId: string, body?: object, options?: any) {
      return MaterialMasterApiFp(configuration).updateUserFormValues(userId, body, options)(axios, basePath);
    },
  };
};

/**
 * MaterialMasterApi - object-oriented interface
 * @export
 * @class MaterialMasterApi
 * @extends {BaseAPI}
 */
export class MaterialMasterApi extends BaseAPI {
  /**
   *
   * @summary Cancels a material master update and deletes all relevant data
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public cancelMaterialMasterUpdate(id: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).cancelMaterialMasterUpdate(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Accepts a material master update\'s changeset and changes the update\'s status to MATCHING
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public confirmTaskUpdate(id: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).confirmTaskUpdate(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a form field group
   * @param {number} materialMasterId
   * @param {NewFieldGroup} [newFieldGroup]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public createFormFieldGroup(materialMasterId: number, newFieldGroup?: NewFieldGroup, options?: any) {
    return MaterialMasterApiFp(this.configuration).createFormFieldGroup(
      materialMasterId,
      newFieldGroup,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new form fields of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {FormFieldConfiguration} [formFieldConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public createMaterialMasterFormField(
    materialMasterId: number,
    formFieldConfiguration?: FormFieldConfiguration,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).createMaterialMasterFormField(
      materialMasterId,
      formFieldConfiguration,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a new user form fields of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {FormFieldConfiguration} [formFieldConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public createUserFormField(materialMasterId: number, formFieldConfiguration?: FormFieldConfiguration, options?: any) {
    return MaterialMasterApiFp(this.configuration).createUserFormField(
      materialMasterId,
      formFieldConfiguration,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a mapping
   * @param {number} materialMasterId Material master ID
   * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public deleteMapping(materialMasterId: number, type: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).deleteMapping(
      materialMasterId,
      type,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all additional plant fields
   * @param {number} materialMasterId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getAdditionalPlantFields(materialMasterId: number, id: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getAdditionalPlantFields(
      materialMasterId,
      id,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all form fields that are not part of the layout
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} [withoutUserFields] Without user related fields
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getAvailableFields(materialMasterId: number, withoutUserFields?: boolean, options?: any) {
    return MaterialMasterApiFp(this.configuration).getAvailableFields(
      materialMasterId,
      withoutUserFields,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all (OptionsField, OptionsLookupField) form fields that are not part of the layout
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getAvailablePlantFields(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getAvailablePlantFields(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get the configuration logs for the material master
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getConfigurationLogs(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getConfigurationLogs(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get export mappings for a given material master configuration
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getExportMappings(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getExportMappings(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all original fields or only the translations depending on the `language` parameter
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} isUserField If true it returns the user fields
   * @param {string} [language] The language of the needed translations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getFields(materialMasterId: number, isUserField: boolean, language?: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getFields(
      materialMasterId,
      isUserField,
      language,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the details for the formfield
   * @param {number} materialMasterId Material Master ID
   * @param {string} fieldId formfield-id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getFormFieldDetails(materialMasterId: number, fieldId: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getFormFieldDetails(
      materialMasterId,
      fieldId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all details from a form field group
   * @param {number} materialMasterId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getFormFieldGroupConfiguration(materialMasterId: number, id: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getFormFieldGroupConfiguration(
      materialMasterId,
      id,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all form field groups
   * @param {number} materialMasterId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getFormFieldGroups(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getFormFieldGroups(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all original groups or only the translations depending on the `language` parameter
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} isUserField If true it returns the user fields
   * @param {string} [language] The language of the needed translations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getGroups(materialMasterId: number, isUserField: boolean, language?: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getGroups(
      materialMasterId,
      isUserField,
      language,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets the manufacturer mapping
   * @param {number} materialMasterId Material master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getManufacturerMapping(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getManufacturerMapping(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Gets the options for the manufacturer. Or empty, if not available
   * @param {number} materialMasterId Material master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getManufacturerOptions(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getManufacturerOptions(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get mapping for a material master
   * @param {number} materialMasterId Material master ID
   * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMapping(materialMasterId: number, type: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMapping(
      materialMasterId,
      type,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the current material master
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMaster(id: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMaster(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all form fields and their configuration of a material master as a list
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterFieldConfigurations(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterFieldConfigurations(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all available form fields of a material master as a list
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} [withoutFileField] Without form field \&#39;file\&#39;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterFields(materialMasterId: number, withoutFileField?: boolean, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterFields(
      materialMasterId,
      withoutFileField,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all material master upload logs
   * @param {number} id
   * @param {number} [page]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterHistory(id: number, page?: number, limit?: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterHistory(
      id,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets material master import configuration
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterImportConfiguration(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterImportConfiguration(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get current form layout of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterLayout(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterLayout(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get material master statistics
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterStatistics(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterStatistics(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get the status of a materialmaster update
   * @param {number} businessEntityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterUpdateStatus(businessEntityId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterUpdateStatus(businessEntityId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get current user form layout of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialMasterUserFormLayout(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialMasterUserFormLayout(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all available user form fields of a material master as a list
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getMaterialUserFields(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getMaterialUserFields(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get the remaining plant values
   * @param {number} businessEntityId Business Entity Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getRemainingPlantValues(businessEntityId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getRemainingPlantValues(businessEntityId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get user data creation form
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getUserDataForm(userId: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getUserDataForm(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all user form fields and their configuration of a material master as a list
   * @param {number} materialMasterId Material Master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getUserFieldConfigurations(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).getUserFieldConfigurations(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get user form values
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public getUserFormValues(userId: string, options?: any) {
    return MaterialMasterApiFp(this.configuration).getUserFormValues(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Merge a MM with is update, along with all relevant matching candidates
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public mergeMaterialMasterUpdate(id: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).mergeMaterialMasterUpdate(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Pre-process all columns that are mapped to special fields
   * @param {number} materialMasterId Material master ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public preProcessColumns(materialMasterId: number, options?: any) {
    return MaterialMasterApiFp(this.configuration).preProcessColumns(materialMasterId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Save a SPARETECH result mapping
   * @param {number} materialMasterId Material master ID
   * @param {string} type Type of Mapping (SPT-Result, Export, Database, MaterialMasterField)
   * @param {FormFieldMapping} [formFieldMapping]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public setMapping(materialMasterId: number, type: string, formFieldMapping?: FormFieldMapping, options?: any) {
    return MaterialMasterApiFp(this.configuration).setMapping(
      materialMasterId,
      type,
      formFieldMapping,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update additional plant fields
   * @param {number} materialMasterId
   * @param {string} id
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateAdditionalPlantFields(materialMasterId: number, id: string, requestBody?: Array<string>, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateAdditionalPlantFields(
      materialMasterId,
      id,
      requestBody,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update export mappings for a given material configuration
   * @param {number} materialMasterId Material Master ID
   * @param {MaterialExportMappings} [materialExportMappings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateExportMappings(
    materialMasterId: number,
    materialExportMappings?: MaterialExportMappings,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateExportMappings(
      materialMasterId,
      materialExportMappings,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update form fields translation
   * @param {boolean} isUserField If true it returns the user fields
   * @param {FormFieldI18ns} [formFieldI18ns]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateFieldsTranslation(isUserField: boolean, formFieldI18ns?: FormFieldI18ns, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateFieldsTranslation(
      isUserField,
      formFieldI18ns,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a form field of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {string} fieldId Field ID
   * @param {FormFieldConfiguration} [formFieldConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateFormFieldConfiguration(
    materialMasterId: number,
    fieldId: string,
    formFieldConfiguration?: FormFieldConfiguration,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateFormFieldConfiguration(
      materialMasterId,
      fieldId,
      formFieldConfiguration,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a field group\'s configuration
   * @param {number} materialMasterId
   * @param {string} id
   * @param {NewFieldGroup} [newFieldGroup]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateFormFieldGroup(materialMasterId: number, id: string, newFieldGroup?: NewFieldGroup, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateFormFieldGroup(
      materialMasterId,
      id,
      newFieldGroup,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Updated the manufacturer mapping
   * @param {number} materialMasterId Material master ID
   * @param {ManufacturerMapping} [manufacturerMapping]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateManufacturerMapping(materialMasterId: number, manufacturerMapping?: ManufacturerMapping, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateManufacturerMapping(
      materialMasterId,
      manufacturerMapping,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary update current form layout of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} isTranslation Value to differentiate change logs
   * @param {FormLayout} [formLayout]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateMasterDataFormLayout(
    materialMasterId: number,
    isTranslation: boolean,
    formLayout?: FormLayout,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateMasterDataFormLayout(
      materialMasterId,
      isTranslation,
      formLayout,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a material master configuration
   * @param {number} businessEntityId Business entity ID
   * @param {MaterialMasterColumns} [materialMasterColumns]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateMaterialMasterDisplayColumns(
    businessEntityId: number,
    materialMasterColumns?: MaterialMasterColumns,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateMaterialMasterDisplayColumns(
      businessEntityId,
      materialMasterColumns,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Updates a material master\'s import configuration
   * @param {number} materialMasterId Material Master ID
   * @param {MaterialMasterImportConfiguration} [materialMasterImportConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateMaterialMasterImportConfiguration(
    materialMasterId: number,
    materialMasterImportConfiguration?: MaterialMasterImportConfiguration,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateMaterialMasterImportConfiguration(
      materialMasterId,
      materialMasterImportConfiguration,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary update current user form layout of a material master
   * @param {number} materialMasterId Material Master ID
   * @param {boolean} isTranslation Value to differentiate change logs
   * @param {UserFormLayout} [userFormLayout]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateUserFormLayout(
    materialMasterId: number,
    isTranslation: boolean,
    userFormLayout?: UserFormLayout,
    options?: any,
  ) {
    return MaterialMasterApiFp(this.configuration).updateUserFormLayout(
      materialMasterId,
      isTranslation,
      userFormLayout,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update user values
   * @param {string} userId
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaterialMasterApi
   */
  public updateUserFormValues(userId: string, body?: object, options?: any) {
    return MaterialMasterApiFp(this.configuration).updateUserFormValues(
      userId,
      body,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete products based on CSV file from S3
     * @param {ProductBatchDeleteRequest} productBatchDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductInBatch(productBatchDeleteRequest: ProductBatchDeleteRequest, options: any = {}): RequestArgs {
      // verify required parameter 'productBatchDeleteRequest' is not null or undefined
      if (productBatchDeleteRequest === null || productBatchDeleteRequest === undefined) {
        throw new RequiredError(
          "productBatchDeleteRequest",
          "Required parameter productBatchDeleteRequest was null or undefined when calling deleteProductInBatch.",
        );
      }
      const localVarPath = `/products/batch-delete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ProductBatchDeleteRequest" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(productBatchDeleteRequest !== undefined ? productBatchDeleteRequest : {})
        : productBatchDeleteRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete products based on CSV file from S3
     * @param {ProductBatchDeleteRequest} productBatchDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductInBatch(
      productBatchDeleteRequest: ProductBatchDeleteRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductApiAxiosParamCreator(configuration).deleteProductInBatch(
        productBatchDeleteRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete products based on CSV file from S3
     * @param {ProductBatchDeleteRequest} productBatchDeleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductInBatch(productBatchDeleteRequest: ProductBatchDeleteRequest, options?: any) {
      return ProductApiFp(configuration).deleteProductInBatch(productBatchDeleteRequest, options)(axios, basePath);
    },
  };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
  /**
   *
   * @summary Delete products based on CSV file from S3
   * @param {ProductBatchDeleteRequest} productBatchDeleteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public deleteProductInBatch(productBatchDeleteRequest: ProductBatchDeleteRequest, options?: any) {
    return ProductApiFp(this.configuration).deleteProductInBatch(productBatchDeleteRequest, options)(
      this.axios,
      this.basePath,
    );
  }
}

/**
 * ProductImportApi - axios parameter creator
 * @export
 */
export const ProductImportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload a product import file
     * @param {string} fileId
     * @param {string} type
     * @param {string} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImportJob(fileId: string, type: string, source: string, options: any = {}): RequestArgs {
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling createImportJob.",
        );
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError("type", "Required parameter type was null or undefined when calling createImportJob.");
      }
      // verify required parameter 'source' is not null or undefined
      if (source === null || source === undefined) {
        throw new RequiredError(
          "source",
          "Required parameter source was null or undefined when calling createImportJob.",
        );
      }
      const localVarPath = `/products/import`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileId !== undefined) {
        localVarQueryParameter["fileId"] = fileId;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (source !== undefined) {
        localVarQueryParameter["source"] = source;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a product import job
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImportJob(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteProductImportJob.",
        );
      }
      const localVarPath = `/products/import/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a review document by its ID.
     * @param {string} reviewId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReviewDocument(reviewId: string, id: string, options: any = {}): RequestArgs {
      // verify required parameter 'reviewId' is not null or undefined
      if (reviewId === null || reviewId === undefined) {
        throw new RequiredError(
          "reviewId",
          "Required parameter reviewId was null or undefined when calling deleteReviewDocument.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteReviewDocument.");
      }
      const localVarPath = `/products/import/{reviewId}/review/{id}`
        .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Finish a product review and import all saved items.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishProductReview(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling finishProductReview.");
      }
      const localVarPath = `/products/import/{id}/review/finish`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all import jobs
     * @param {boolean} released
     * @param {string} [type]
     * @param {string} [state]
     * @param {string} [author]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImportJobs(
      released: boolean,
      type?: string,
      state?: string,
      author?: string,
      limit?: number,
      page?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'released' is not null or undefined
      if (released === null || released === undefined) {
        throw new RequiredError(
          "released",
          "Required parameter released was null or undefined when calling getImportJobs.",
        );
      }
      const localVarPath = `/products/import`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (released !== undefined) {
        localVarQueryParameter["released"] = released;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (state !== undefined) {
        localVarQueryParameter["state"] = state;
      }

      if (author !== undefined) {
        localVarQueryParameter["author"] = author;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a product import job\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportJob(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductImportJob.");
      }
      const localVarPath = `/products/import/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get product import validation
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportValidation(jobId: string, options: any = {}): RequestArgs {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling getProductImportValidation.",
        );
      }
      const localVarPath = `/products/import/validate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get an in-review import job and its documents
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [state]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReview(
      id: string,
      limit?: number,
      page?: number,
      state?: string,
      manufacturer?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductReview.");
      }
      const localVarPath = `/products/import/{id}/review`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (state !== undefined) {
        localVarQueryParameter["state"] = state;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets all manufacturers relevant to a review job
     * @param {string} id
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReviewJobManufacturers(id: string, state?: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getReviewJobManufacturers.",
        );
      }
      const localVarPath = `/products/import/{id}/review/manufacturers`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter["state"] = state;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark a product review ready for import
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markReadyForImport(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling markReadyForImport.");
      }
      const localVarPath = `/products/import/{id}/review/ready`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Process a valid import file
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processProductImport(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling processProductImport.");
      }
      const localVarPath = `/products/import/{id}/process`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Re-matches all review documents from one manufacturer
     * @param {string} id
     * @param {ReMatchRequest} [reMatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rematchReviewDocuments(id: string, reMatchRequest?: ReMatchRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling rematchReviewDocuments.",
        );
      }
      const localVarPath = `/products/import/{id}/review/re-match`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ReMatchRequest" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(reMatchRequest !== undefined ? reMatchRequest : {})
        : reMatchRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resolves conflicts for a batch of review documents
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReviewConflicts(id: string, requestBody?: Array<string>, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling resolveReviewConflicts.",
        );
      }
      const localVarPath = `/products/import/{id}/review/resolve-conflicts`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"Array&lt;string&gt;" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Select product candidate
     * @param {string} id
     * @param {number} selectedProductCandidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectProductCandidate(id: string, selectedProductCandidate: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling selectProductCandidate.",
        );
      }
      // verify required parameter 'selectedProductCandidate' is not null or undefined
      if (selectedProductCandidate === null || selectedProductCandidate === undefined) {
        throw new RequiredError(
          "selectedProductCandidate",
          "Required parameter selectedProductCandidate was null or undefined when calling selectProductCandidate.",
        );
      }
      const localVarPath = `/products/import/{id}/review/select-product-candidate`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (selectedProductCandidate !== undefined) {
        localVarQueryParameter["selectedProductCandidate"] = selectedProductCandidate;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unselect product candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unSelectProductCandidate(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling unSelectProductCandidate.",
        );
      }
      const localVarPath = `/products/import/review/unselect-product-candidate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update file of existing import job
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImportJobFile(jobId: string, fileId: string, options: any = {}): RequestArgs {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling updateImportJobFile.",
        );
      }
      // verify required parameter 'fileId' is not null or undefined
      if (fileId === null || fileId === undefined) {
        throw new RequiredError(
          "fileId",
          "Required parameter fileId was null or undefined when calling updateImportJobFile.",
        );
      }
      const localVarPath = `/products/import/update-file`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      if (fileId !== undefined) {
        localVarQueryParameter["fileId"] = fileId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate a product import
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductImport(jobId: string, options: any = {}): RequestArgs {
      // verify required parameter 'jobId' is not null or undefined
      if (jobId === null || jobId === undefined) {
        throw new RequiredError(
          "jobId",
          "Required parameter jobId was null or undefined when calling validateProductImport.",
        );
      }
      const localVarPath = `/products/import/validate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter["jobId"] = jobId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductImportApi - functional programming interface
 * @export
 */
export const ProductImportApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload a product import file
     * @param {string} fileId
     * @param {string} type
     * @param {string} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImportJob(
      fileId: string,
      type: string,
      source: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportJob> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).createImportJob(
        fileId,
        type,
        source,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a product import job
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImportJob(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).deleteProductImportJob(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a review document by its ID.
     * @param {string} reviewId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReviewDocument(
      reviewId: string,
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).deleteReviewDocument(
        reviewId,
        id,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Finish a product review and import all saved items.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishProductReview(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).finishProductReview(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all import jobs
     * @param {boolean} released
     * @param {string} [type]
     * @param {string} [state]
     * @param {string} [author]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImportJobs(
      released: boolean,
      type?: string,
      state?: string,
      author?: string,
      limit?: number,
      page?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportJobs> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).getImportJobs(
        released,
        type,
        state,
        author,
        limit,
        page,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a product import job\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportJob(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportJob> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).getProductImportJob(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get product import validation
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportValidation(
      jobId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductImportValidation>> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).getProductImportValidation(
        jobId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get an in-review import job and its documents
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [state]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReview(
      id: string,
      limit?: number,
      page?: number,
      state?: string,
      manufacturer?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportReview> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).getProductReview(
        id,
        limit,
        page,
        state,
        manufacturer,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets all manufacturers relevant to a review job
     * @param {string} id
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReviewJobManufacturers(
      id: string,
      state?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerAggregation>> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).getReviewJobManufacturers(
        id,
        state,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Mark a product review ready for import
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markReadyForImport(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).markReadyForImport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Process a valid import file
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processProductImport(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).processProductImport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Re-matches all review documents from one manufacturer
     * @param {string} id
     * @param {ReMatchRequest} [reMatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rematchReviewDocuments(
      id: string,
      reMatchRequest?: ReMatchRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).rematchReviewDocuments(
        id,
        reMatchRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Resolves conflicts for a batch of review documents
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReviewConflicts(
      id: string,
      requestBody?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).resolveReviewConflicts(
        id,
        requestBody,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Select product candidate
     * @param {string} id
     * @param {number} selectedProductCandidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectProductCandidate(
      id: string,
      selectedProductCandidate: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).selectProductCandidate(
        id,
        selectedProductCandidate,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Unselect product candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unSelectProductCandidate(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).unSelectProductCandidate(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update file of existing import job
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImportJobFile(
      jobId: string,
      fileId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).updateImportJobFile(
        jobId,
        fileId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Validate a product import
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductImport(
      jobId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductImportApiAxiosParamCreator(configuration).validateProductImport(jobId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductImportApi - factory interface
 * @export
 */
export const ProductImportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Upload a product import file
     * @param {string} fileId
     * @param {string} type
     * @param {string} source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImportJob(fileId: string, type: string, source: string, options?: any) {
      return ProductImportApiFp(configuration).createImportJob(fileId, type, source, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a product import job
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductImportJob(id: string, options?: any) {
      return ProductImportApiFp(configuration).deleteProductImportJob(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a review document by its ID.
     * @param {string} reviewId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReviewDocument(reviewId: string, id: string, options?: any) {
      return ProductImportApiFp(configuration).deleteReviewDocument(reviewId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Finish a product review and import all saved items.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishProductReview(id: string, options?: any) {
      return ProductImportApiFp(configuration).finishProductReview(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all import jobs
     * @param {boolean} released
     * @param {string} [type]
     * @param {string} [state]
     * @param {string} [author]
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImportJobs(
      released: boolean,
      type?: string,
      state?: string,
      author?: string,
      limit?: number,
      page?: number,
      options?: any,
    ) {
      return ProductImportApiFp(configuration).getImportJobs(
        released,
        type,
        state,
        author,
        limit,
        page,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get a product import job\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportJob(id: string, options?: any) {
      return ProductImportApiFp(configuration).getProductImportJob(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get product import validation
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductImportValidation(jobId: string, options?: any) {
      return ProductImportApiFp(configuration).getProductImportValidation(jobId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get an in-review import job and its documents
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [state]
     * @param {string} [manufacturer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReview(id: string, limit?: number, page?: number, state?: string, manufacturer?: string, options?: any) {
      return ProductImportApiFp(configuration).getProductReview(
        id,
        limit,
        page,
        state,
        manufacturer,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Gets all manufacturers relevant to a review job
     * @param {string} id
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReviewJobManufacturers(id: string, state?: string, options?: any) {
      return ProductImportApiFp(configuration).getReviewJobManufacturers(id, state, options)(axios, basePath);
    },
    /**
     *
     * @summary Mark a product review ready for import
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markReadyForImport(id: string, options?: any) {
      return ProductImportApiFp(configuration).markReadyForImport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Process a valid import file
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processProductImport(id: string, options?: any) {
      return ProductImportApiFp(configuration).processProductImport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Re-matches all review documents from one manufacturer
     * @param {string} id
     * @param {ReMatchRequest} [reMatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rematchReviewDocuments(id: string, reMatchRequest?: ReMatchRequest, options?: any) {
      return ProductImportApiFp(configuration).rematchReviewDocuments(id, reMatchRequest, options)(axios, basePath);
    },
    /**
     *
     * @summary Resolves conflicts for a batch of review documents
     * @param {string} id
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveReviewConflicts(id: string, requestBody?: Array<string>, options?: any) {
      return ProductImportApiFp(configuration).resolveReviewConflicts(id, requestBody, options)(axios, basePath);
    },
    /**
     *
     * @summary Select product candidate
     * @param {string} id
     * @param {number} selectedProductCandidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectProductCandidate(id: string, selectedProductCandidate: number, options?: any) {
      return ProductImportApiFp(configuration).selectProductCandidate(
        id,
        selectedProductCandidate,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Unselect product candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unSelectProductCandidate(id: string, options?: any) {
      return ProductImportApiFp(configuration).unSelectProductCandidate(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Update file of existing import job
     * @param {string} jobId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImportJobFile(jobId: string, fileId: string, options?: any) {
      return ProductImportApiFp(configuration).updateImportJobFile(jobId, fileId, options)(axios, basePath);
    },
    /**
     *
     * @summary Validate a product import
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateProductImport(jobId: string, options?: any) {
      return ProductImportApiFp(configuration).validateProductImport(jobId, options)(axios, basePath);
    },
  };
};

/**
 * ProductImportApi - object-oriented interface
 * @export
 * @class ProductImportApi
 * @extends {BaseAPI}
 */
export class ProductImportApi extends BaseAPI {
  /**
   *
   * @summary Upload a product import file
   * @param {string} fileId
   * @param {string} type
   * @param {string} source
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public createImportJob(fileId: string, type: string, source: string, options?: any) {
    return ProductImportApiFp(this.configuration).createImportJob(
      fileId,
      type,
      source,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a product import job
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public deleteProductImportJob(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).deleteProductImportJob(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a review document by its ID.
   * @param {string} reviewId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public deleteReviewDocument(reviewId: string, id: string, options?: any) {
    return ProductImportApiFp(this.configuration).deleteReviewDocument(
      reviewId,
      id,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Finish a product review and import all saved items.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public finishProductReview(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).finishProductReview(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all import jobs
   * @param {boolean} released
   * @param {string} [type]
   * @param {string} [state]
   * @param {string} [author]
   * @param {number} [limit]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public getImportJobs(
    released: boolean,
    type?: string,
    state?: string,
    author?: string,
    limit?: number,
    page?: number,
    options?: any,
  ) {
    return ProductImportApiFp(this.configuration).getImportJobs(
      released,
      type,
      state,
      author,
      limit,
      page,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a product import job\'s details
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public getProductImportJob(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).getProductImportJob(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get product import validation
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public getProductImportValidation(jobId: string, options?: any) {
    return ProductImportApiFp(this.configuration).getProductImportValidation(jobId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get an in-review import job and its documents
   * @param {string} id
   * @param {number} [limit]
   * @param {number} [page]
   * @param {string} [state]
   * @param {string} [manufacturer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public getProductReview(
    id: string,
    limit?: number,
    page?: number,
    state?: string,
    manufacturer?: string,
    options?: any,
  ) {
    return ProductImportApiFp(this.configuration).getProductReview(
      id,
      limit,
      page,
      state,
      manufacturer,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Gets all manufacturers relevant to a review job
   * @param {string} id
   * @param {string} [state]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public getReviewJobManufacturers(id: string, state?: string, options?: any) {
    return ProductImportApiFp(this.configuration).getReviewJobManufacturers(
      id,
      state,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Mark a product review ready for import
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public markReadyForImport(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).markReadyForImport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Process a valid import file
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public processProductImport(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).processProductImport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Re-matches all review documents from one manufacturer
   * @param {string} id
   * @param {ReMatchRequest} [reMatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public rematchReviewDocuments(id: string, reMatchRequest?: ReMatchRequest, options?: any) {
    return ProductImportApiFp(this.configuration).rematchReviewDocuments(
      id,
      reMatchRequest,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Resolves conflicts for a batch of review documents
   * @param {string} id
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public resolveReviewConflicts(id: string, requestBody?: Array<string>, options?: any) {
    return ProductImportApiFp(this.configuration).resolveReviewConflicts(
      id,
      requestBody,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Select product candidate
   * @param {string} id
   * @param {number} selectedProductCandidate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public selectProductCandidate(id: string, selectedProductCandidate: number, options?: any) {
    return ProductImportApiFp(this.configuration).selectProductCandidate(
      id,
      selectedProductCandidate,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Unselect product candidate
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public unSelectProductCandidate(id: string, options?: any) {
    return ProductImportApiFp(this.configuration).unSelectProductCandidate(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update file of existing import job
   * @param {string} jobId
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public updateImportJobFile(jobId: string, fileId: string, options?: any) {
    return ProductImportApiFp(this.configuration).updateImportJobFile(
      jobId,
      fileId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Validate a product import
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductImportApi
   */
  public validateProductImport(jobId: string, options?: any) {
    return ProductImportApiFp(this.configuration).validateProductImport(jobId, options)(this.axios, this.basePath);
  }
}

/**
 * ProductReportsApi - axios parameter creator
 * @export
 */
export const ProductReportsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a product report
     * @param {ProductReport} [productReport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductReport(productReport?: ProductReport, options: any = {}): RequestArgs {
      const localVarPath = `/products/reports`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"ProductReport" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(productReport !== undefined ? productReport : {})
        : productReport || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a product report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductReport(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteProductReport.");
      }
      const localVarPath = `/products/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a product report\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReport(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductReport.");
      }
      const localVarPath = `/products/reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get reports for wrong/duplicate products
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [reason]
     * @param {string} [author]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReports(page?: number, limit?: number, reason?: string, author?: string, options: any = {}): RequestArgs {
      const localVarPath = `/products/reports`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (reason !== undefined) {
        localVarQueryParameter["reason"] = reason;
      }

      if (author !== undefined) {
        localVarQueryParameter["author"] = author;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductReportsApi - functional programming interface
 * @export
 */
export const ProductReportsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a product report
     * @param {ProductReport} [productReport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductReport(
      productReport?: ProductReport,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReport> {
      const localVarAxiosArgs = ProductReportsApiAxiosParamCreator(configuration).createProductReport(
        productReport,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a product report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductReport(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductReportsApiAxiosParamCreator(configuration).deleteProductReport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a product report\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReport(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReport> {
      const localVarAxiosArgs = ProductReportsApiAxiosParamCreator(configuration).getProductReport(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get reports for wrong/duplicate products
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [reason]
     * @param {string} [author]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReports(
      page?: number,
      limit?: number,
      reason?: string,
      author?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductReports> {
      const localVarAxiosArgs = ProductReportsApiAxiosParamCreator(configuration).getProductReports(
        page,
        limit,
        reason,
        author,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductReportsApi - factory interface
 * @export
 */
export const ProductReportsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Create a product report
     * @param {ProductReport} [productReport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductReport(productReport?: ProductReport, options?: any) {
      return ProductReportsApiFp(configuration).createProductReport(productReport, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a product report
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductReport(id: string, options?: any) {
      return ProductReportsApiFp(configuration).deleteProductReport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get a product report\'s details
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReport(id: string, options?: any) {
      return ProductReportsApiFp(configuration).getProductReport(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get reports for wrong/duplicate products
     * @param {number} [page]
     * @param {number} [limit]
     * @param {string} [reason]
     * @param {string} [author]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductReports(page?: number, limit?: number, reason?: string, author?: string, options?: any) {
      return ProductReportsApiFp(configuration).getProductReports(
        page,
        limit,
        reason,
        author,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * ProductReportsApi - object-oriented interface
 * @export
 * @class ProductReportsApi
 * @extends {BaseAPI}
 */
export class ProductReportsApi extends BaseAPI {
  /**
   *
   * @summary Create a product report
   * @param {ProductReport} [productReport]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReportsApi
   */
  public createProductReport(productReport?: ProductReport, options?: any) {
    return ProductReportsApiFp(this.configuration).createProductReport(productReport, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Delete a product report
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReportsApi
   */
  public deleteProductReport(id: string, options?: any) {
    return ProductReportsApiFp(this.configuration).deleteProductReport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a product report\'s details
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReportsApi
   */
  public getProductReport(id: string, options?: any) {
    return ProductReportsApiFp(this.configuration).getProductReport(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get reports for wrong/duplicate products
   * @param {number} [page]
   * @param {number} [limit]
   * @param {string} [reason]
   * @param {string} [author]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductReportsApi
   */
  public getProductReports(page?: number, limit?: number, reason?: string, author?: string, options?: any) {
    return ProductReportsApiFp(this.configuration).getProductReports(
      page,
      limit,
      reason,
      author,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Assigns a successor to a product
     * @param {string} id
     * @param {string} successorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignSuccessorToProduct(id: string, successorId: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling assignSuccessorToProduct.",
        );
      }
      // verify required parameter 'successorId' is not null or undefined
      if (successorId === null || successorId === undefined) {
        throw new RequiredError(
          "successorId",
          "Required parameter successorId was null or undefined when calling assignSuccessorToProduct.",
        );
      }
      const localVarPath = `/products/{id}/successor/{successorId}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"successorId"}}`, encodeURIComponent(String(successorId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deletes a potential successor document in case it is not relevant anymore
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePotentialSuccessor(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deletePotentialSuccessor.",
        );
      }
      const localVarPath = `/products/{id}/successor`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a product by its Sparetech ID
     * @param {string} id
     * @param {string} [correctId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct(id: string, correctId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteProduct.");
      }
      const localVarPath = `/products/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (correctId !== undefined) {
        localVarQueryParameter["correctId"] = correctId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all list containing the product as candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListsByProduct(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getListsByProduct.");
      }
      const localVarPath = `/products/{id}/lists`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all products with potential successors
     * @param {boolean} onlyWithCandidates
     * @param {number} [numberOfResults]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialSuccessors(
      onlyWithCandidates: boolean,
      numberOfResults?: number,
      page?: number,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'onlyWithCandidates' is not null or undefined
      if (onlyWithCandidates === null || onlyWithCandidates === undefined) {
        throw new RequiredError(
          "onlyWithCandidates",
          "Required parameter onlyWithCandidates was null or undefined when calling getPotentialSuccessors.",
        );
      }
      const localVarPath = `/products/potential-successors`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (numberOfResults !== undefined) {
        localVarQueryParameter["numberOfResults"] = numberOfResults;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (onlyWithCandidates !== undefined) {
        localVarQueryParameter["onlyWithCandidates"] = onlyWithCandidates;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a product by its Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProduct.");
      }
      const localVarPath = `/products/{id}/main`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getProductDetails.");
      }
      const localVarPath = `/products/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all products
     * @param {string} freeText
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [manufacturerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts(
      freeText: string,
      limit?: number,
      page?: number,
      manufacturerName?: string,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'freeText' is not null or undefined
      if (freeText === null || freeText === undefined) {
        throw new RequiredError(
          "freeText",
          "Required parameter freeText was null or undefined when calling getProducts.",
        );
      }
      const localVarPath = `/products`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (manufacturerName !== undefined) {
        localVarQueryParameter["manufacturerName"] = manufacturerName;
      }

      if (freeText !== undefined) {
        localVarQueryParameter["freeText"] = freeText;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Assigns a successor to a product
     * @param {string} id
     * @param {string} successorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignSuccessorToProduct(
      id: string,
      successorId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).assignSuccessorToProduct(
        id,
        successorId,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Deletes a potential successor document in case it is not relevant anymore
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePotentialSuccessor(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).deletePotentialSuccessor(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a product by its Sparetech ID
     * @param {string} id
     * @param {string} [correctId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct(
      id: string,
      correctId?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).deleteProduct(id, correctId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all list containing the product as candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListsByProduct(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getListsByProduct(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all products with potential successors
     * @param {boolean} onlyWithCandidates
     * @param {number} [numberOfResults]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialSuccessors(
      onlyWithCandidates: boolean,
      numberOfResults?: number,
      page?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PotentialSuccessorsResponse> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getPotentialSuccessors(
        onlyWithCandidates,
        numberOfResults,
        page,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a product by its Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProduct(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetails>> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProductDetails(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all products
     * @param {string} freeText
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [manufacturerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts(
      freeText: string,
      limit?: number,
      page?: number,
      manufacturerName?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResults> {
      const localVarAxiosArgs = ProductsApiAxiosParamCreator(configuration).getProducts(
        freeText,
        limit,
        page,
        manufacturerName,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Assigns a successor to a product
     * @param {string} id
     * @param {string} successorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignSuccessorToProduct(id: string, successorId: string, options?: any) {
      return ProductsApiFp(configuration).assignSuccessorToProduct(id, successorId, options)(axios, basePath);
    },
    /**
     *
     * @summary Deletes a potential successor document in case it is not relevant anymore
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePotentialSuccessor(id: string, options?: any) {
      return ProductsApiFp(configuration).deletePotentialSuccessor(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete a product by its Sparetech ID
     * @param {string} id
     * @param {string} [correctId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct(id: string, correctId?: string, options?: any) {
      return ProductsApiFp(configuration).deleteProduct(id, correctId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all list containing the product as candidate
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListsByProduct(id: string, options?: any) {
      return ProductsApiFp(configuration).getListsByProduct(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all products with potential successors
     * @param {boolean} onlyWithCandidates
     * @param {number} [numberOfResults]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPotentialSuccessors(onlyWithCandidates: boolean, numberOfResults?: number, page?: number, options?: any) {
      return ProductsApiFp(configuration).getPotentialSuccessors(
        onlyWithCandidates,
        numberOfResults,
        page,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Get a product by its Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(id: string, options?: any) {
      return ProductsApiFp(configuration).getProduct(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get the product-details by the Sparetech ID
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetails(id: string, options?: any) {
      return ProductsApiFp(configuration).getProductDetails(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all products
     * @param {string} freeText
     * @param {number} [limit]
     * @param {number} [page]
     * @param {string} [manufacturerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts(freeText: string, limit?: number, page?: number, manufacturerName?: string, options?: any) {
      return ProductsApiFp(configuration).getProducts(
        freeText,
        limit,
        page,
        manufacturerName,
        options,
      )(axios, basePath);
    },
  };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
  /**
   *
   * @summary Assigns a successor to a product
   * @param {string} id
   * @param {string} successorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public assignSuccessorToProduct(id: string, successorId: string, options?: any) {
    return ProductsApiFp(this.configuration).assignSuccessorToProduct(
      id,
      successorId,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Deletes a potential successor document in case it is not relevant anymore
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public deletePotentialSuccessor(id: string, options?: any) {
    return ProductsApiFp(this.configuration).deletePotentialSuccessor(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete a product by its Sparetech ID
   * @param {string} id
   * @param {string} [correctId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public deleteProduct(id: string, correctId?: string, options?: any) {
    return ProductsApiFp(this.configuration).deleteProduct(id, correctId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all list containing the product as candidate
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getListsByProduct(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getListsByProduct(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all products with potential successors
   * @param {boolean} onlyWithCandidates
   * @param {number} [numberOfResults]
   * @param {number} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getPotentialSuccessors(onlyWithCandidates: boolean, numberOfResults?: number, page?: number, options?: any) {
    return ProductsApiFp(this.configuration).getPotentialSuccessors(
      onlyWithCandidates,
      numberOfResults,
      page,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a product by its Sparetech ID
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProduct(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProduct(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the product-details by the Sparetech ID
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProductDetails(id: string, options?: any) {
    return ProductsApiFp(this.configuration).getProductDetails(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all products
   * @param {string} freeText
   * @param {number} [limit]
   * @param {number} [page]
   * @param {string} [manufacturerName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductsApi
   */
  public getProducts(freeText: string, limit?: number, page?: number, manufacturerName?: string, options?: any) {
    return ProductsApiFp(this.configuration).getProducts(
      freeText,
      limit,
      page,
      manufacturerName,
      options,
    )(this.axios, this.basePath);
  }
}

/**
 * RuleEngineApi - axios parameter creator
 * @export
 */
export const RuleEngineApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Import a file to the rule engine
     * @param {RuleEngineImport} [ruleEngineImport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRuleEngine(ruleEngineImport?: RuleEngineImport, options: any = {}): RequestArgs {
      const localVarPath = `/rule-engine`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"RuleEngineImport" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(ruleEngineImport !== undefined ? ruleEngineImport : {})
        : ruleEngineImport || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RuleEngineApi - functional programming interface
 * @export
 */
export const RuleEngineApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Import a file to the rule engine
     * @param {RuleEngineImport} [ruleEngineImport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRuleEngine(
      ruleEngineImport?: RuleEngineImport,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = RuleEngineApiAxiosParamCreator(configuration).runRuleEngine(ruleEngineImport, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RuleEngineApi - factory interface
 * @export
 */
export const RuleEngineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Import a file to the rule engine
     * @param {RuleEngineImport} [ruleEngineImport]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runRuleEngine(ruleEngineImport?: RuleEngineImport, options?: any) {
      return RuleEngineApiFp(configuration).runRuleEngine(ruleEngineImport, options)(axios, basePath);
    },
  };
};

/**
 * RuleEngineApi - object-oriented interface
 * @export
 * @class RuleEngineApi
 * @extends {BaseAPI}
 */
export class RuleEngineApi extends BaseAPI {
  /**
   *
   * @summary Import a file to the rule engine
   * @param {RuleEngineImport} [ruleEngineImport]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RuleEngineApi
   */
  public runRuleEngine(ruleEngineImport?: RuleEngineImport, options?: any) {
    return RuleEngineApiFp(this.configuration).runRuleEngine(ruleEngineImport, options)(this.axios, this.basePath);
  }
}

/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete demo data
     * @param {DemoDataToDelete} [demoDataToDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDemoData(demoDataToDelete?: DemoDataToDelete, options: any = {}): RequestArgs {
      const localVarPath = `/service/delete-demo-data`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"DemoDataToDelete" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(demoDataToDelete !== undefined ? demoDataToDelete : {})
        : demoDataToDelete || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options: any = {}): RequestArgs {
      const localVarPath = `/service/backend-version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run Mongo DB migrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runMongoDbMigrations(options: any = {}): RequestArgs {
      const localVarPath = `/service/run-mongo-db-migrations`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete demo data
     * @param {DemoDataToDelete} [demoDataToDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDemoData(
      demoDataToDelete?: DemoDataToDelete,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).deleteDemoData(demoDataToDelete, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).getBackendVersion(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Run Mongo DB migrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runMongoDbMigrations(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ServiceApiAxiosParamCreator(configuration).runMongoDbMigrations(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete demo data
     * @param {DemoDataToDelete} [demoDataToDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDemoData(demoDataToDelete?: DemoDataToDelete, options?: any) {
      return ServiceApiFp(configuration).deleteDemoData(demoDataToDelete, options)(axios, basePath);
    },
    /**
     *
     * @summary Returns the backend version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendVersion(options?: any) {
      return ServiceApiFp(configuration).getBackendVersion(options)(axios, basePath);
    },
    /**
     *
     * @summary Run Mongo DB migrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runMongoDbMigrations(options?: any) {
      return ServiceApiFp(configuration).runMongoDbMigrations(options)(axios, basePath);
    },
  };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
  /**
   *
   * @summary Delete demo data
   * @param {DemoDataToDelete} [demoDataToDelete]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public deleteDemoData(demoDataToDelete?: DemoDataToDelete, options?: any) {
    return ServiceApiFp(this.configuration).deleteDemoData(demoDataToDelete, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Returns the backend version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public getBackendVersion(options?: any) {
    return ServiceApiFp(this.configuration).getBackendVersion(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Run Mongo DB migrations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceApi
   */
  public runMongoDbMigrations(options?: any) {
    return ServiceApiFp(this.configuration).runMongoDbMigrations(options)(this.axios, this.basePath);
  }
}

/**
 * SptResultApi - axios parameter creator
 * @export
 */
export const SptResultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all SPT-Result headers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSptHeaders(options: any = {}): RequestArgs {
      const localVarPath = `/spt-headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SptResultApi - functional programming interface
 * @export
 */
export const SptResultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all SPT-Result headers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSptHeaders(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = SptResultApiAxiosParamCreator(configuration).getSptHeaders(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SptResultApi - factory interface
 * @export
 */
export const SptResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get all SPT-Result headers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSptHeaders(options?: any) {
      return SptResultApiFp(configuration).getSptHeaders(options)(axios, basePath);
    },
  };
};

/**
 * SptResultApi - object-oriented interface
 * @export
 * @class SptResultApi
 * @extends {BaseAPI}
 */
export class SptResultApi extends BaseAPI {
  /**
   *
   * @summary Get all SPT-Result headers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SptResultApi
   */
  public getSptHeaders(options?: any) {
    return SptResultApiFp(this.configuration).getSptHeaders(options)(this.axios, this.basePath);
  }
}

/**
 * SystemStatisticsApi - axios parameter creator
 * @export
 */
export const SystemStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Export the non matched parts
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/statistics/matching/non-matched/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (dateRange !== undefined) {
        localVarQueryParameter["dateRange"] = dateRange;
      }

      if (listType !== undefined) {
        localVarQueryParameter["listType"] = listType;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export the created materials without sparetech id
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/statistics/material-creation/without-spt-id/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (sptManufacturerId !== undefined) {
        localVarQueryParameter["sptManufacturerId"] = sptManufacturerId;
      }

      if (dateRange !== undefined) {
        localVarQueryParameter["dateRange"] = dateRange;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generates the statistics for the non-matched parts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateNonMatchedPartsStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/statistics/matching/non-matched`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate system statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSystemStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/system-statistics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns the manufacturers
     * @param {number} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturersUsedInMaterialCreation(clientId?: number, options: any = {}): RequestArgs {
      const localVarPath = `/statistics/material-creation/used-manufacturers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of parts where we didn\'t find any candidate
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/statistics/matching/non-matched`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (dateRange !== undefined) {
        localVarQueryParameter["dateRange"] = dateRange;
      }

      if (listType !== undefined) {
        localVarQueryParameter["listType"] = listType;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of products that where created without using data from the product DB (=> no real SPT-ID)
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/statistics/material-creation/without-spt-id`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (sptManufacturerId !== undefined) {
        localVarQueryParameter["sptManufacturerId"] = sptManufacturerId;
      }

      if (dateRange !== undefined) {
        localVarQueryParameter["dateRange"] = dateRange;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns the rejected green candidates
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {number} [taskId]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRejectedGreenCandidates(
      manufacturer?: string,
      dateRange?: DateRange,
      taskId?: number,
      page?: number,
      limit?: number,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/statistics/matching/rejected-green`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (manufacturer !== undefined) {
        localVarQueryParameter["manufacturer"] = manufacturer;
      }

      if (dateRange !== undefined) {
        localVarQueryParameter["dateRange"] = dateRange;
      }

      if (taskId !== undefined) {
        localVarQueryParameter["taskId"] = taskId;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Show system statistics, like stats from the product DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemStatistics(options: any = {}): RequestArgs {
      const localVarPath = `/system-statistics`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemStatisticsApi - functional programming interface
 * @export
 */
export const SystemStatisticsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Export the non matched parts
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).exportNonMatchedParts(
        clientId,
        manufacturer,
        dateRange,
        listType,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Export the created materials without sparetech id
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).exportNonSptMaterialCreations(
        clientId,
        manufacturer,
        sptManufacturerId,
        dateRange,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generates the statistics for the non-matched parts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateNonMatchedPartsStatistics(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs =
        SystemStatisticsApiAxiosParamCreator(configuration).generateNonMatchedPartsStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generate system statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSystemStatistics(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).generateSystemStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns the manufacturers
     * @param {number} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturersUsedInMaterialCreation(
      clientId?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(
        configuration,
      ).getManufacturersUsedInMaterialCreation(clientId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns a list of parts where we didn\'t find any candidate
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonMatchedPartsStatisticResults> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).getNonMatchedParts(
        clientId,
        manufacturer,
        dateRange,
        listType,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns a list of products that where created without using data from the product DB (=> no real SPT-ID)
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedMaterialStatisticResults> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).getNonSptMaterialCreations(
        clientId,
        manufacturer,
        sptManufacturerId,
        dateRange,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns the rejected green candidates
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {number} [taskId]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRejectedGreenCandidates(
      manufacturer?: string,
      dateRange?: DateRange,
      taskId?: number,
      page?: number,
      limit?: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RejectedGreenCandidates> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).getRejectedGreenCandidates(
        manufacturer,
        dateRange,
        taskId,
        page,
        limit,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Show system statistics, like stats from the product DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemStatistics(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatistics> {
      const localVarAxiosArgs = SystemStatisticsApiAxiosParamCreator(configuration).getSystemStatistics(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemStatisticsApi - factory interface
 * @export
 */
export const SystemStatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Export the non matched parts
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      options?: any,
    ) {
      return SystemStatisticsApiFp(configuration).exportNonMatchedParts(
        clientId,
        manufacturer,
        dateRange,
        listType,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Export the created materials without sparetech id
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      options?: any,
    ) {
      return SystemStatisticsApiFp(configuration).exportNonSptMaterialCreations(
        clientId,
        manufacturer,
        sptManufacturerId,
        dateRange,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Generates the statistics for the non-matched parts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateNonMatchedPartsStatistics(options?: any) {
      return SystemStatisticsApiFp(configuration).generateNonMatchedPartsStatistics(options)(axios, basePath);
    },
    /**
     *
     * @summary Generate system statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSystemStatistics(options?: any) {
      return SystemStatisticsApiFp(configuration).generateSystemStatistics(options)(axios, basePath);
    },
    /**
     *
     * @summary Returns the manufacturers
     * @param {number} [clientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getManufacturersUsedInMaterialCreation(clientId?: number, options?: any) {
      return SystemStatisticsApiFp(configuration).getManufacturersUsedInMaterialCreation(clientId, options)(
        axios,
        basePath,
      );
    },
    /**
     *
     * @summary Returns a list of parts where we didn\'t find any candidate
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {string} [listType]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonMatchedParts(
      clientId?: number,
      manufacturer?: string,
      dateRange?: DateRange,
      listType?: string,
      page?: number,
      limit?: number,
      options?: any,
    ) {
      return SystemStatisticsApiFp(configuration).getNonMatchedParts(
        clientId,
        manufacturer,
        dateRange,
        listType,
        page,
        limit,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Returns a list of products that where created without using data from the product DB (=> no real SPT-ID)
     * @param {number} [clientId]
     * @param {string} [manufacturer]
     * @param {string} [sptManufacturerId]
     * @param {DateRange} [dateRange]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNonSptMaterialCreations(
      clientId?: number,
      manufacturer?: string,
      sptManufacturerId?: string,
      dateRange?: DateRange,
      page?: number,
      limit?: number,
      options?: any,
    ) {
      return SystemStatisticsApiFp(configuration).getNonSptMaterialCreations(
        clientId,
        manufacturer,
        sptManufacturerId,
        dateRange,
        page,
        limit,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Returns the rejected green candidates
     * @param {string} [manufacturer]
     * @param {DateRange} [dateRange]
     * @param {number} [taskId]
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRejectedGreenCandidates(
      manufacturer?: string,
      dateRange?: DateRange,
      taskId?: number,
      page?: number,
      limit?: number,
      options?: any,
    ) {
      return SystemStatisticsApiFp(configuration).getRejectedGreenCandidates(
        manufacturer,
        dateRange,
        taskId,
        page,
        limit,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Show system statistics, like stats from the product DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemStatistics(options?: any) {
      return SystemStatisticsApiFp(configuration).getSystemStatistics(options)(axios, basePath);
    },
  };
};

/**
 * SystemStatisticsApi - object-oriented interface
 * @export
 * @class SystemStatisticsApi
 * @extends {BaseAPI}
 */
export class SystemStatisticsApi extends BaseAPI {
  /**
   *
   * @summary Export the non matched parts
   * @param {number} [clientId]
   * @param {string} [manufacturer]
   * @param {DateRange} [dateRange]
   * @param {string} [listType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public exportNonMatchedParts(
    clientId?: number,
    manufacturer?: string,
    dateRange?: DateRange,
    listType?: string,
    options?: any,
  ) {
    return SystemStatisticsApiFp(this.configuration).exportNonMatchedParts(
      clientId,
      manufacturer,
      dateRange,
      listType,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Export the created materials without sparetech id
   * @param {number} [clientId]
   * @param {string} [manufacturer]
   * @param {string} [sptManufacturerId]
   * @param {DateRange} [dateRange]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public exportNonSptMaterialCreations(
    clientId?: number,
    manufacturer?: string,
    sptManufacturerId?: string,
    dateRange?: DateRange,
    options?: any,
  ) {
    return SystemStatisticsApiFp(this.configuration).exportNonSptMaterialCreations(
      clientId,
      manufacturer,
      sptManufacturerId,
      dateRange,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Generates the statistics for the non-matched parts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public generateNonMatchedPartsStatistics(options?: any) {
    return SystemStatisticsApiFp(this.configuration).generateNonMatchedPartsStatistics(options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Generate system statistics
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public generateSystemStatistics(options?: any) {
    return SystemStatisticsApiFp(this.configuration).generateSystemStatistics(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Returns the manufacturers
   * @param {number} [clientId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public getManufacturersUsedInMaterialCreation(clientId?: number, options?: any) {
    return SystemStatisticsApiFp(this.configuration).getManufacturersUsedInMaterialCreation(clientId, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Returns a list of parts where we didn\'t find any candidate
   * @param {number} [clientId]
   * @param {string} [manufacturer]
   * @param {DateRange} [dateRange]
   * @param {string} [listType]
   * @param {number} [page] Page
   * @param {number} [limit] Limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public getNonMatchedParts(
    clientId?: number,
    manufacturer?: string,
    dateRange?: DateRange,
    listType?: string,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return SystemStatisticsApiFp(this.configuration).getNonMatchedParts(
      clientId,
      manufacturer,
      dateRange,
      listType,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Returns a list of products that where created without using data from the product DB (=> no real SPT-ID)
   * @param {number} [clientId]
   * @param {string} [manufacturer]
   * @param {string} [sptManufacturerId]
   * @param {DateRange} [dateRange]
   * @param {number} [page] Page
   * @param {number} [limit] Limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public getNonSptMaterialCreations(
    clientId?: number,
    manufacturer?: string,
    sptManufacturerId?: string,
    dateRange?: DateRange,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return SystemStatisticsApiFp(this.configuration).getNonSptMaterialCreations(
      clientId,
      manufacturer,
      sptManufacturerId,
      dateRange,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Returns the rejected green candidates
   * @param {string} [manufacturer]
   * @param {DateRange} [dateRange]
   * @param {number} [taskId]
   * @param {number} [page] Page
   * @param {number} [limit] Limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public getRejectedGreenCandidates(
    manufacturer?: string,
    dateRange?: DateRange,
    taskId?: number,
    page?: number,
    limit?: number,
    options?: any,
  ) {
    return SystemStatisticsApiFp(this.configuration).getRejectedGreenCandidates(
      manufacturer,
      dateRange,
      taskId,
      page,
      limit,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Show system statistics, like stats from the product DB
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemStatisticsApi
   */
  public getSystemStatistics(options?: any) {
    return SystemStatisticsApiFp(this.configuration).getSystemStatistics(options)(this.axios, this.basePath);
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a user in the scope of a Manufacturer
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturerUser(newManufacturerUser?: NewManufacturerUser, options: any = {}): RequestArgs {
      const localVarPath = `/users/manufacturers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewManufacturerUser" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newManufacturerUser !== undefined ? newManufacturerUser : {})
        : newManufacturerUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new User
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options: any = {}): RequestArgs {
      // verify required parameter 'newUser' is not null or undefined
      if (newUser === null || newUser === undefined) {
        throw new RequiredError("newUser", "Required parameter newUser was null or undefined when calling createUser.");
      }
      const localVarPath = `/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewUser" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newUser !== undefined ? newUser : {})
        : newUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary delete a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get admin user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUser(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getAdminUser.");
      }
      const localVarPath = `/users/admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all admin users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUsers(options: any = {}): RequestArgs {
      const localVarPath = `/users/admins`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all meta information for a user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserMeta(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling getUserMeta.");
      }
      const localVarPath = `/users/{id}/meta`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all Users
     * @param {number} [clientId] Client Id
     * @param {string} [manufacturerId] Manufacturer ID
     * @param {boolean} [withMeta]
     * @param {boolean} [isGhostUser] True if we need to get only ghost users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      clientId?: number,
      manufacturerId?: string,
      withMeta?: boolean,
      isGhostUser?: boolean,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (clientId !== undefined) {
        localVarQueryParameter["clientId"] = clientId;
      }

      if (manufacturerId !== undefined) {
        localVarQueryParameter["manufacturerId"] = manufacturerId;
      }

      if (withMeta !== undefined) {
        localVarQueryParameter["withMeta"] = withMeta;
      }

      if (isGhostUser !== undefined) {
        localVarQueryParameter["isGhostUser"] = isGhostUser;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a user of a manufacturer
     * @param {string} id User ID
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerUser(id: string, newManufacturerUser?: NewManufacturerUser, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateManufacturerUser.",
        );
      }
      const localVarPath = `/users/manufacturers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewManufacturerUser" !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newManufacturerUser !== undefined ? newManufacturerUser : {})
        : newManufacturerUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a user
     * @param {string} id User ID
     * @param {NewUser} [newUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, newUser?: NewUser, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError("id", "Required parameter id was null or undefined when calling updateUser.");
      }
      const localVarPath = `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        <any>"NewUser" !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(newUser !== undefined ? newUser : {})
        : newUser || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a user in the scope of a Manufacturer
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturerUser(
      newManufacturerUser?: NewManufacturerUser,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).createManufacturerUser(
        newManufacturerUser,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a new User
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).createUser(newUser, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary delete a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).deleteUser(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get admin user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUser(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getAdminUser(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all admin users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminUser>> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getAdminUsers(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUser(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all meta information for a user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserMeta(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMeta> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUserMeta(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all Users
     * @param {number} [clientId] Client Id
     * @param {string} [manufacturerId] Manufacturer ID
     * @param {boolean} [withMeta]
     * @param {boolean} [isGhostUser] True if we need to get only ghost users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      clientId?: number,
      manufacturerId?: string,
      withMeta?: boolean,
      isGhostUser?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).getUsers(
        clientId,
        manufacturerId,
        withMeta,
        isGhostUser,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a user of a manufacturer
     * @param {string} id User ID
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerUser(
      id: string,
      newManufacturerUser?: NewManufacturerUser,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateManufacturerUser(
        id,
        newManufacturerUser,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update a user
     * @param {string} id User ID
     * @param {NewUser} [newUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      id: string,
      newUser?: NewUser,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UsersApiAxiosParamCreator(configuration).updateUser(id, newUser, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create a user in the scope of a Manufacturer
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManufacturerUser(newManufacturerUser?: NewManufacturerUser, options?: any) {
      return UsersApiFp(configuration).createManufacturerUser(newManufacturerUser, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a new User
     * @param {NewUser} newUser User information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(newUser: NewUser, options?: any) {
      return UsersApiFp(configuration).createUser(newUser, options)(axios, basePath);
    },
    /**
     *
     * @summary delete a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(id: string, options?: any) {
      return UsersApiFp(configuration).deleteUser(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get admin user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUser(id: string, options?: any) {
      return UsersApiFp(configuration).getAdminUser(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all admin users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminUsers(options?: any) {
      return UsersApiFp(configuration).getAdminUsers(options)(axios, basePath);
    },
    /**
     *
     * @summary Get a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(id: string, options?: any) {
      return UsersApiFp(configuration).getUser(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all meta information for a user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserMeta(id: string, options?: any) {
      return UsersApiFp(configuration).getUserMeta(id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all Users
     * @param {number} [clientId] Client Id
     * @param {string} [manufacturerId] Manufacturer ID
     * @param {boolean} [withMeta]
     * @param {boolean} [isGhostUser] True if we need to get only ghost users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(clientId?: number, manufacturerId?: string, withMeta?: boolean, isGhostUser?: boolean, options?: any) {
      return UsersApiFp(configuration).getUsers(
        clientId,
        manufacturerId,
        withMeta,
        isGhostUser,
        options,
      )(axios, basePath);
    },
    /**
     *
     * @summary Update a user of a manufacturer
     * @param {string} id User ID
     * @param {NewManufacturerUser} [newManufacturerUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManufacturerUser(id: string, newManufacturerUser?: NewManufacturerUser, options?: any) {
      return UsersApiFp(configuration).updateManufacturerUser(id, newManufacturerUser, options)(axios, basePath);
    },
    /**
     *
     * @summary Update a user
     * @param {string} id User ID
     * @param {NewUser} [newUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, newUser?: NewUser, options?: any) {
      return UsersApiFp(configuration).updateUser(id, newUser, options)(axios, basePath);
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Create a user in the scope of a Manufacturer
   * @param {NewManufacturerUser} [newManufacturerUser]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createManufacturerUser(newManufacturerUser?: NewManufacturerUser, options?: any) {
    return UsersApiFp(this.configuration).createManufacturerUser(newManufacturerUser, options)(
      this.axios,
      this.basePath,
    );
  }

  /**
   *
   * @summary Create a new User
   * @param {NewUser} newUser User information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(newUser: NewUser, options?: any) {
    return UsersApiFp(this.configuration).createUser(newUser, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary delete a user
   * @param {string} id User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUser(id: string, options?: any) {
    return UsersApiFp(this.configuration).deleteUser(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get admin user
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAdminUser(id: string, options?: any) {
    return UsersApiFp(this.configuration).getAdminUser(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all admin users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAdminUsers(options?: any) {
    return UsersApiFp(this.configuration).getAdminUsers(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get a user
   * @param {string} id User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUser(id: string, options?: any) {
    return UsersApiFp(this.configuration).getUser(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all meta information for a user
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserMeta(id: string, options?: any) {
    return UsersApiFp(this.configuration).getUserMeta(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all Users
   * @param {number} [clientId] Client Id
   * @param {string} [manufacturerId] Manufacturer ID
   * @param {boolean} [withMeta]
   * @param {boolean} [isGhostUser] True if we need to get only ghost users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsers(
    clientId?: number,
    manufacturerId?: string,
    withMeta?: boolean,
    isGhostUser?: boolean,
    options?: any,
  ) {
    return UsersApiFp(this.configuration).getUsers(
      clientId,
      manufacturerId,
      withMeta,
      isGhostUser,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a user of a manufacturer
   * @param {string} id User ID
   * @param {NewManufacturerUser} [newManufacturerUser]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateManufacturerUser(id: string, newManufacturerUser?: NewManufacturerUser, options?: any) {
    return UsersApiFp(this.configuration).updateManufacturerUser(
      id,
      newManufacturerUser,
      options,
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update a user
   * @param {string} id User ID
   * @param {NewUser} [newUser]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUser(id: string, newUser?: NewUser, options?: any) {
    return UsersApiFp(this.configuration).updateUser(id, newUser, options)(this.axios, this.basePath);
  }
}
